import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        url: String,
    }

    async removeItem(event) {
        event.currentTarget.classList.add('removing');
        const response = await fetch(this.urlValue);
        this.element.innerHTML = await response.text();
    }
}
