import * as bootstrap from 'bootstrap';



/* global angular */
(function () {
    const modalLoading = ($( "#modal-loading").length ) ? new bootstrap.Modal(document.getElementById('modal-loading')) : null;
    const modalSaveObject = ($( "#modal-save-object").length ) ? new bootstrap.Modal(document.getElementById('modal-save-object')) : null;
    const modalSuccess = ($( "#modal-success").length ) ? new bootstrap.Modal(document.getElementById('modal-success')) : null;
    const modalError = ($( "#modal-error").length ) ? new bootstrap.Modal(document.getElementById('modal-error')) : null;

    'use strict';
    var app = angular.module('formlyExample', [
        'formly',
        'formlyBootstrap',
        'ngAnimate',
        'ngMessages'
    ]);
    app.run(function (formlyConfig, formlyValidationMessages, $parse) {
        formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'fc.$touched || form.$submitted';
        formlyValidationMessages.addStringMessage('required', 'This field is required');
        formlyConfig.extras.fieldTransform = formlyConfig.extras.fieldTransform || [];
        formlyConfig.extras.fieldTransform.push(removeOnHideTransformer);

        function removeOnHideTransformer(fields) {
            return fields.map(function (field) {
                field.data = field.data || {};
                if (field.key && !field.noFormControl && field.hideExpression && !field.data.dontRemoveOnHidden) {
                    addFieldRemoveOnHideWatcher(field);
                } else if (field.fieldGroup) {
                    field.fieldGroup = removeOnHideTransformer(fields);
                }
                return field;
            });
        }

        function addFieldRemoveOnHideWatcher(field) {
            var watcher = getWatcher();
            if (field.watcher) {
                if (!angular.isArray(field.watcher)) {
                    field.watcher = [field.watcher];
                }
                field.watcher.push(watcher);
            } else {
                field.watcher = watcher;
            }
        }

        function getWatcher() {
            return {
                expression: function (field) {
                    return field.hide;
                },
                listener: function (field, newHide, oldHide, scope) {
                    var model = field.model || scope.model; // default to the field's model
                    if ((!eval(field.hideExpression))) {
                        field.hide = false;
                        model[field.key] = field.defaultValue;
                    }
                    /* istanbul ignore else */
                    if (field.hide) {
                        var getter = $parse(field.key);
                        field.data.preHiddenValue = '';
                        getter.assign(model, undefined);
                    } else if (field.data.preHiddenValue !== undefined) {
                        $parse(field.key).assign(model, field.data.preHiddenValue);
                    } else {
                        // do nothing.
                    }
                }
            };
        }
    });
    app.config(function ($httpProvider) {
        $httpProvider.interceptors.push('myHttpInterceptor');
        var spinnerFunction = function (data, headersGetter) {
            return data;
        };
        $httpProvider.defaults.transformRequest.push(spinnerFunction);
    })

    // register the interceptor as a service, intercepts ALL angular ajax http calls
        .factory('myHttpInterceptor', function ($q, $window) {
            return function (promise) {
                return promise.then(function (response) {
                    modalLoading.hide();
                    return response;
                }, function (response) {
                    modalLoading.hide();
                    return $q.reject(response);
                });
            };
        });
    app.config(function (formlyConfigProvider) {
        formlyConfigProvider.setWrapper({
            name: 'validation',
            types: ['input'],
            templateUrl: 'error-messages.html'
        });
        formlyConfigProvider.setWrapper({
            name: 'panel',
            templateUrl: 'panel.html'
        });
        formlyConfigProvider.setType({
            name: 'matchField',
            apiCheck: function () {
                return {
                    data: {
                        fieldToMatch: formlyExampleApiCheck.string
                    }
                }
            },
            apiCheckOptions: {
                prefix: 'matchField type'
            },
            defaultOptions: function matchFieldDefaultOptions(options) {
                return {
                    extras: {
                        validateOnModelChange: true
                    },
                    expressionProperties: {
                        'templateOptions.disabled': function (viewValue, modelValue, scope) {
                            var matchField = find(scope.fields, 'key', options.data.fieldToMatch);
                            if (!matchField) {
                                throw new Error('Could not find a field for the key ' + options.data.fieldToMatch);
                            }
                            var model = options.data.modelToMatch || scope.model;
                            var originalValue = model[options.data.fieldToMatch];
                            var invalidOriginal = matchField.formControl && matchField.formControl.$invalid;
                            return !originalValue || invalidOriginal;
                        }
                    },
                    validators: {
                        fieldMatch: {
                            expression: function (viewValue, modelValue, fieldScope) {
                                var value = modelValue || viewValue;
                                var model = options.data.modelToMatch || fieldScope.model;
                                return value === model[options.data.fieldToMatch];
                            },
                            message: options.data.matchFieldMessage || '"Must match"'
                        }
                    }
                };

                function find(array, prop, value) {
                    var foundItem;
                    array.some(function (item) {
                        if (item[prop] === value) {
                            foundItem = item;
                        }
                        return !!foundItem;
                    });
                    return foundItem;
                }
            }
        });
    });
    app.controller('MainCtrl', function MainCtrl(formlyVersion, $scope, $http, $location) {
        var vm = this;
        vm.onReset = onReset;
        $scope.objectId;
        var url;
        $scope.$watch("objectId", function () {
            modalLoading.show();
            var urlSet = Routing.generate('object_show_api', {id: $scope.objectId});
            var urlNull = Routing.generate('object_show_api', {id: 'null'});
            url = ('' !== $scope.objectId) ? urlSet : urlNull;

            // $('#modalSubmitClickChart').modal('show');
            $http({
                method: 'GET',
                url: url
            }).then(function (response) {
                modalLoading.hide();
                responseFunction(response);
            }, function (response) {
                modalLoading.hide();
            });
        });
        $scope.autoSaveAsk = function () {
            console.log("autoSaveAsk: " + new Date($.now()));
            //var dirty = vm.form.$dirty //if the blank inputs of the form are touched it's "dirty"
            //var pristine = vm.form.$pristine //if the form has changed from the original values then pristine is false
            if (false == vm.form.$pristine) {
                //load the modal to ask if the user wants to save the form
                //Er zijn wijzigingen aangebracht en u heeft niet opgeslagen, wilt u opslaan?
                modalSaveObject.show();
            }
        }
        self.setInterval($scope.autoSaveAsk, 300000);
        /*
        function checkForAutoSave() {
            var d = new Date();
            var form = vm.form;
            alert(d);
        }

        self.setInterval(checkForAutoSave, 15000);
        */
        $scope.submitForm = function () {
            if ((!vm.form || vm.form.$valid) && $scope.object.$valid) {
                modalLoading.show();
                if ($scope.object.$valid) {
                    console.log('Form is valid');
                } else {
                    console.log('Form is not valid');
                }
                var data = createPostData();
                if(""!== $scope.objectId){
                    data.formIsComplete = checkIfFormIsComplete();
                }
                var json = JSON.stringify(data);
                var urlEdit = Routing.generate('object_edit_api', {id: $scope.objectId});
                var urlNew = Routing.generate('object_new_api_put');
                var urlPut = ('' !== $scope.objectId) ? urlEdit : urlNew;

                $http({
                    url: urlPut,
                    data: json,
                    method: 'PUT',
                }).then(function (response) {
                    //initNavbarBarChart();
                    modalLoading.hide();
                    if ('' !== $scope.objectId) {
                        responseFunction(response);
                        modalSuccess.show();
                    } else {
                        //window.location.href = Routing.generate('object_edit_api', {id: $scope.objectId});
                        //object_edit
                        window.location.href = Routing.generate('object_edit', {id: response.data.objectId});
                    }
                    vm.form.$setSubmitted();
                    vm.form.$setPristine();
                }, function (response) {
                    modalLoading.hide();
                    modalError.show();

                });
            } else if ((vm.form && vm.form.$error.required) || $scope.object.$error.required) {
                alert('Vul alle verplichte velden in')
            }
        }

        function responseFunction(response) {
            createGeneralData(response.data);
            createQuestionForm(response.data);
            if (null !== response.data.elementanswers) {
                //vm.options.resetModel();
                setAnswers(response.data);
            }
        }

        function setAnswers(response) {
            for (var i = 0; i < response.data.elementanswers.length; i++) {
                var parentKey = response.data.elementanswers[i].parent_element.id;
                var childId = response.data.elementanswers[i].child_element.id;
                var childKey = response.data.elementanswers[i].child_element.name;
                var answerId = response.data.elementanswers[i].answer.id;
                var answerValue = response.data.elementanswers[i].answer.name;
                for (var j = 0; j < vm.fieldsTest.length; j++) {
                    if (vm.fieldsTest[j].key == parentKey) {
                        for (var k = 0; k < vm.fieldsTest[j].fieldGroup.length; k++) {
                            if (vm.fieldsTest[j].fieldGroup[k].key == childKey) {
                                var a = vm.fieldsTest[j].fieldGroup[k].templateOptions.options;
                                //function(d){ return d.part == "primary" ? "end" : "start"}
                                //function(x){ return x.name == answerValue}
                                //var index = a.findIndex(x => x.name == answerValue);
                                //var index = a.findIndex(function(x){ return x.name == answerValue});
                                //var index = a.findIndex(function(x){ return x.name == answerValue});
                                /*
                                var index = -1;
                                for (var i = 0; i < a.length; ++i) {
                                    if (a[i].name == answerValue) {
                                        index = i;
                                        break;
                                    }
                                }
                                */
                                var index = -1;
                                a.some(function (el, i) {
                                    if (el.name == answerValue) {
                                        index = i;
                                        return true;
                                    }
                                });
                                var value = vm.fieldsTest[j].fieldGroup[k].templateOptions.options[index];
                                vm.fieldsTest[j].fieldGroup[k].defaultValue = value.name;
                                vm.fieldsTest[j].fieldGroup[k].templateOptions["answerId"] = answerId;
                                vm.fieldsTest[j].fieldGroup[k].templateOptions["questionId"] = childId;
                            }
                        }
                    }
                }
                var result = $.grep(vm.fieldsTest, function (e) {
                    return e.key == parentKey;
                });
            }
        }

        function createGeneralData(response) {
            var buildingYearGeneral;
            if (response.data.object.building_year) {
                buildingYearGeneral = moment(response.data.object.building_year).format("DD-MM-YYYY");
            } else {
                buildingYearGeneral = null
            }
            var selectedElements = [];
            $scope.object.isArchived = response.data.object.is_archived || '';
            $scope.object.name = response.data.object.name || '';
            $scope.object.address = response.data.object.address || '';
            $scope.object.postal = response.data.object.postal || '';
            $scope.object.city = response.data.object.city || '';
            $scope.customCategories = response.data.customCategories || '';
            $scope.object.buildingYear = buildingYearGeneral;
            $scope.objectFunctions = response.data.functions;
            $scope.objectAdmins = response.data.admins;
            $scope.ownerorganisations = response.data.ownerorganisations;
            $scope.tenantorganisations = response.data.tenantorganisations;
            $scope.elementOptions = response.data.elements;
            $scope.data = response.data || "Request failed";
            $scope.status = response.status;
            if (response.data.object.elements !== undefined) {
                var elems = response.data.object.elements;
                var array = $.map(elems, function (value, index) {
                    return [value];
                });
                for (var i = 0; i < array.length; i++) {
                    var selId = array[i].id;
                    selectedElements.push(selId);
                }
                $scope.object.elements = selectedElements;
            }
            if (response.data.object.is_archived !== undefined) {
                $scope.object.isArchived = response.data.object.is_archived;
            }
            if (response.data.object.buildingYear !== undefined) {
                $scope.object.buildingYear = response.data.object.buildingYear;
            }
            if (response.data.object.custom_category !== undefined) {
                $scope.object.customCategory = response.data.object.custom_category.id;
            }
            if (response.data.object.object_function !== undefined) {
                $scope.object.objectFunction = response.data.object.object_function.id;
            }
            if (response.data.object.object_admin !== undefined) {
                $scope.object.objectAdmin = response.data.object.object_admin.id;
            }
            if (response.data.object.ownerorganisation !== undefined) {
                $scope.object.ownerorganisation = response.data.object.ownerorganisation.id;
            }
            if (response.data.object.owneruser !== undefined) {
                $scope.object.owneruser = response.data.object.owneruser.id;
            }
            if (response.data.object.tenantorganisation !== undefined) {
                $scope.object.tenantorganisation = response.data.object.tenantorganisation.id;
            }
            if (response.data.object.tenantuser !== undefined) {
                $scope.object.tenantuser = response.data.object.tenantuser.id;
            }
            if (response.data.object.address !== undefined) {
                $scope.object.address = response.data.object.address;
            }
            if (response.data.object.postal !== undefined) {
                $scope.object.postal = response.data.object.postal;
            }
            if (response.data.object.city !== undefined) {
                $scope.object.city = response.data.object.city;
            }
        }

        function createQuestionForm(response) {
            vm.fieldsTest = [];
            for (var i = 0; i < response.data.questions.length; i++) {
                var formKeys = [];
                for (var j = 0; j < response.data.questions[i].elementen.length; j++) {
                    var options = [];
                    var label = response.data.questions[i].elementen[j].name;
                    var id = response.data.questions[i].elementen[j].id;
                    var key = response.data.questions[i].elementen[j].key;
                    var hideExpression = response.data.questions[i].elementen[j].hideExpression;
                    if (response.data.questions[i].options !== null) {
                        //options = response.data.questions[i].elementen[j].options;
                        for (var k = 0; k < response.data.questions[i].elementen[j].options.length; k++) {
                            var questionId = response.data.questions[i].elementen[j].id;
                            var answerId = response.data.questions[i].elementen[j].options[k].id;
                            var optionName = response.data.questions[i].elementen[j].options[k].name;
                            var optionValue = response.data.questions[i].elementen[j].options[k].value;
                            var option = {
                                questionId: questionId,
                                answerId: answerId,
                                name: optionName,
                                value: optionValue,
                            };
                            options.push(option);
                        }
                        //$scope.options[label] = options;
                        //$scope.selection[label] = $scope.options[_.indexOf($scope.options[label], _.findWhere($scope.options[label], {selected: 'selected'}))];
                    }
                    ;
                    var formKey = {
                        key: key,
                        type: 'select',
                        templateOptions: {
                            label: label,
                            options: options,
                            required: false,
                            onChange: function ($viewValue, $modelValue, scope) {
                                //var index = $modelValue.templateOptions.options.indexOf($viewValue); // 1
                                var a = $modelValue.templateOptions.options;
                                //var index = a.findIndex(function(x){ return x.name == answerValue});
                                //var index = a.findIndex(x => x.name == $viewValue);
                                var index = a.findIndex(function (x) {
                                    return x.name == $viewValue
                                });
                                var answerId = $modelValue.templateOptions.options[index].answerId;
                                var questionId = $modelValue.templateOptions.options[index].questionId;
                                $modelValue.templateOptions["answerId"] = answerId;
                                $modelValue.templateOptions["questionId"] = questionId;
                            }
                        },
                        hide: false,
                        hideExpression: hideExpression,
                        data: {
                            dontRemoveOnHidden: false
                        },
                        validation: {
                            show: true,
                            messages: {
                                required: 'to.label + " is required"'
                            }
                        },
                        /*

                         watcher: {

                         listener: function (field, newValue) {

                         console.log('model changed to ' + newValue);

                         }

                         }

                         */
                    };
                    formKeys.push(formKey);
                }
                var formGroup = {
                    key: response.data.questions[i].parent_id,
                    wrapper: 'panel',
                    templateOptions: {
                        label: response.data.questions[i].hoofdgroep
                    },
                    fieldGroup: formKeys
                };
                vm.fieldsTest.push(formGroup);
            }
        }

        function checkIfFormIsComplete() {
            var formIsComplete = true;
            var unansweredQuestions = [];
            for (var i = 0; i < vm.fieldsTest.length; i++) {
                for (var j = 0; j < vm.fieldsTest[i].fieldGroup.length; j++) {
                    var hide = vm.fieldsTest[i].fieldGroup[j].hide;
                    var modelvalue = vm.fieldsTest[i].fieldGroup[j].value;
                    if (false == hide){
                        var fieldName = vm.fieldsTest[i].fieldGroup[j].name;
                        var keyField = vm.fieldsTest[i].fieldGroup[j].key;
                        var keyFieldGroup = vm.fieldsTest[i].key;
                        var valueForm = vm.form[fieldName].$modelValue;
                        //var valueMode = vm.model.keyFieldGroup.keyField;

                        if(undefined == valueForm){
                            var question = vm.fieldsTest[i].fieldGroup[j].templateOptions.label;
                            unansweredQuestions.push(question);
                            formIsComplete = false;
                        }


                    }
                }
            }

            return {
                result: formIsComplete,
                unansweredQuestions: unansweredQuestions

            };
        }

        function createPostData() {
            var buildingYearCheck;
            if ($scope.object.buildingYear) {
                buildingYearCheck = moment($scope.object.buildingYear).format("DD-MM-YYYY");
            } else {
                buildingYearCheck = null
            }
            var object = {
                isArchived: $scope.object.isArchived,
                name: $scope.object.name,
                buildingYear: buildingYearCheck,
                objectFunction: $scope.object.objectFunction,
                address: $scope.object.address,
                postal: $scope.object.postal,
                city: $scope.object.city,
                customCategory: $scope.object.customCategory,
                objectAdmin: $scope.object.objectAdmin,
                ownerorganisation: $scope.object.ownerorganisation,
                owneruser: $scope.object.owneruser,
                //changed for Nederweert
                tenantorganisation: $scope.object.ownerorganisation,
                tenantuser: $scope.object.tenantuser,
                elements: {},
                questions: {}
            };
            if ($scope.object.elements !== undefined) {
                $.each($scope.object.elements, function (key, value) {
                    object.elements[key] = value;
                });
            }
            for (var i = 0; i < vm.fieldsTest.length; i++) {
                for (var j = 0; j < vm.fieldsTest[i].fieldGroup.length; j++) {
                    if (true !== vm.fieldsTest[i].fieldGroup[j].hide) {
                        var questionId = vm.fieldsTest[i].fieldGroup[j].templateOptions["questionId"];
                        var answerId = vm.fieldsTest[i].fieldGroup[j].templateOptions["answerId"];
                        object.questions[questionId] = answerId;
                    }
                }
            }
            return object;
        }

        function onReset() {
            vm.options.resetModel();
        }
    });
    app.directive('formlyErrorSummary', function () {
        return {
            scope: {},
            bindToController: {
                form: '=',
                fields: '='
            },
            controllerAs: 'vm',
            controller: function () {
                var vm = this;
                vm.getErrorAsList = getErrorAsList;

                function getErrorAsList(field) {
                    return Object.keys(field.formControl.$error).map(function (error) {
                        // note, this only works because the customInput type we have defined.
                        return field.data.getValidationMessage(error);
                    }).join(', ');
                }
            }
        };
    });
})();
