//DATATABLES BS5
import 'jszip';
import 'pdfmake';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs';
import 'datatables.net-searchpanes-bs5';
import 'datatables.net-select-bs5';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
//END - DATATABLES BS5

// angular.module('mwl.calendar.hmc', ['mwl.calendar', 'ngAnimate', 'ui.bootstrap', 'colorpicker.module']);
angular.module('mwl.calendar.hmc', ['mwl.calendar', 'ngAnimate', 'ui.bootstrap']);
angular
    .module('mwl.calendar.hmc') //you will need to declare your module with the dependencies ['mwl.calendar', 'ui.bootstrap', 'ngAnimate']
    .controller('KitchenSinkCtrl', function ($scope, $http, $location, moment, calendarConfig) {

            //calendarConfig.templates.calendar =           '/bundles/angular-bootstrap-calendar/src/templates/calendar.html';
            //calendarConfig.templates.calendarMonthView =    '/bundles/angular-bootstrap-calendar/src/templates/calendarMonthView.html';
            //console.log(calendarConfig.templates)
            var vm = this;
            var url;
            vm.calendarView = 'month';
            vm.viewDate = new Date();

            calendarConfig:{
                displayEventTime: false
            }
            $scope.$watch("objectId", function () {
                url = Routing.generate('calender_api');
                $http({
                    method: 'GET',
                    url: url
                }).then(function (response) {
                    responseFunction(response.data.data.events.data);
                    var jsonString = JSON.stringify(response.data.data.eventTable.data);
                    var jsonObj = JSON.parse(jsonString);
                    initCalendarTable(jsonObj);
                }, function (response) {

                    //
                });
            });
            function initCalendarTable(responseData) {
                var events = []
                for (var i = 0; i < responseData.length; i++) {
                    if (null !== responseData[i][4]) {
                        var today = new Date();
                        var validTill = new Date(responseData[i][4]);
                        if (today < validTill) {

                            //console.dir(row);
                            events.push(responseData[i]);
                        }
                    }
                }
                $('#calendar-table').DataTable({
                    "language": {
                        "url": "//cdn.datatables.net/plug-ins/1.10.15/i18n/Dutch.json"
                    },
                    "targets": 'sort',
                    "orderable": true,
                    "order": [[4, "asc"]],
                    data: events,
                    fields: [{
                        label: 'Id',
                        name: 'id'
                    }, {
                        label: 'Gebouw',
                        name: 'gebouw'
                    }, {
                        label: 'Element',
                        name: 'element'
                    }, {
                        label: 'Taak',
                        name: 'taak'
                    }, {
                        label: 'Uitvoeren voor',
                        name: 'uitvoeren_voor',
                        type: 'datetime',
                        def: function () {
                            return new Date();
                        },
                        format: 'Do MMMM YYYY',
                        fieldInfo: 'Verbose date format'
                    }, {
                        label: 'Verantwoordelijkheid',
                        name: 'verantwoordelijkheid'
                    }, {
                        label: 'Verantwoordelijkheid contact',
                        name: 'verantwoordelijkheidcontact'
                    }, {
                        label: 'Taak uitvoerder',
                        name: 'taakuitvoerder'
                    }
                    ],
                    columns: [
                        {title: "Id"},
                        {title: "Gebouw"},
                        {title: "Element"},
                        {title: "Taak"},
                        //{title: "Uitvoeren vóór", render: $.fn.dataTable.render.moment("Y-M-D", "DD MMMM YYYY", "nl")},
                        {title: "Uitvoeren vóór"},
                        {title: "Verantwoordelijkheid"},
                        {title: "Verantwoordelijkheid contact"},
                        {title: "Taak uitvoerder"},
                    ],
                    columnDefs: [
                        {
                            "targets": [0],
                            "visible": false,
                            "searchable": false
                        },
                    ],
                    dom: 'Blfrtip',
                    buttons: [
                        {
                            extend: 'copy',
                            text: '<i class="fa fa-files-o" aria-hidden="true"></i>',
                            titleAttr: 'Kopieer naar klembord'
                        }, {
                            extend: 'excel',
                            text: '<i class="fa fa-file-excel-o" aria-hidden="true"></i>',
                            titleAttr: 'Exporteer naar excel',
                        }, {
                            extend: 'colvis',
                            text: '<i class="fa fa-columns" aria-hidden="true"></i>',
                            titleAttr: 'Zichtbaarheid kolommen',
                            postfixButtons: ['colvisRestore']
                        }
                    ],
                    createdRow: function (row, data, index) {

                        //
                        // if the second column cell is blank apply special formatting
                        //
                        var today = new Date();
                        var validTill = new Date(data[4]);
                        var type = data[8];
                        if (type == 1) {

                            //console.dir(row);
                            $(row).addClass('warning');
                        } else if (type == 2) {
                            $(row).addClass('success');
                        } else if (type == 3) {
                            $(row).addClass('info');
                        }
                    },
                    initComplete: function () {
                        this.api().columns().every(function () {
                            var column = this;
                            var select = $('<select><option value=""></option></select>')
                                .appendTo($(column.footer()).empty())
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex(
                                        $(this).val()
                                    );
                                    column
                                        .search(val ? '^' + val + '$' : '', true, false)
                                        .draw();
                                });
                            column.data().unique().sort().each(function (d, j) {
                                if (column.search() === '^' + d + '$') {
                                    select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
                                } else {
                                    select.append('<option value="' + d + '">' + d + '</option>')
                                }
                            });
                        });
                    }
                });
            }

            function responseFunction(data) {

                //These variables MUST be set as a minimum for the calendar to work
                vm.calendarView = 'month';
                vm.viewDate = new Date();
                var actions = [{
                    label: '<i class=\'glyphicon glyphicon-pencil\'></i>',
                    onClick: function (args) {
                        console.log('Edited', args.calendarEvent.url);
                        var url = args.calendarEvent.url;
                        window.location.href = url;
                    }
                }];
                calendarConfig.dateFormatter = 'moment'; // use moment instead of angular for formatting dates
                calendarConfig.displayEventTime = false;
                $http.moment = $http.moment || moment;
                var eventData = JSON.stringify(data);
                var eventDataJson = JSON.parse(eventData);
                var events = []
                for (var i = 0; i < eventDataJson.length; i++) {
                    if (eventDataJson[i].type === 1) {
                        var color = calendarConfig.colorTypes.warning
                    } else if (eventDataJson[i].type === 2) {
                        var color = calendarConfig.colorTypes.success
                    } else if (eventDataJson[i].type === 3) {
                        var color = calendarConfig.colorTypes.info
                    }
                    events.push({
                        title: eventDataJson[i].title,
                        object: eventDataJson[i].object,
                        element: eventDataJson[i].element,
                        task: eventDataJson[i].task,
                        startsAt: new Date(eventDataJson[i].startsAt),
                        endsAt: new Date(eventDataJson[i].endsAt),
                        allDay: true,
                        id: eventDataJson[i].id,
                        actions: actions,
                        type: eventDataJson[i].type,
                        color: color,
                        url: eventDataJson[i].url,
                    })
                }
                vm.events = events;
                //vm.events = eventDataJson;
                for (var i = 0; i < vm.events.length; i++) {
                    vm.events[i].object = data[i].object;
                    vm.events[i].element = data[i].element;
                    vm.events[i].task = data[i].task;
                    vm.events[i].startsAt = new Date(data[i].startsAt);
                    vm.events[i].endsAt = new Date(data[i].endsAt);
                    vm.events[i].allDay = true;
                    vm.events[i].id = data[i].id;
                    vm.events[i].actions = actions;
                }
                vm.isCellOpen = false;
                vm.groupEvents = function (cell) {
                    cell.groups = {};
                    cell.events.forEach(function (event) {
                        cell.groups[event.type] = cell.groups[event.type] || [];
                        cell.groups[event.type].push(event);
                    });
                };
                vm.toggle = function ($event, field, event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    event[field] = !event[field];
                };
                vm.eventClicked = function (event) {
                    // alert.show('Clicked', event);
                };
            }
        }
    );



