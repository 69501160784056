import Chart from 'chart.js';
import * as bootstrap from "bootstrap";


var objectId = null;
var managementObjectId = null;
var conclusions = [];
var dataLabels = [
    "Document ontbreekt (wet)",
    "Datum gepasseerd (wet)",
    "Document ontbreekt (aanbevolen)",
    "Datum gepasseerd (aanbevolen)",
    "Voldoet (wet)",
    "Voldoet (aanbevolen)",
    "In behandeling"
];
var customData = [
    1,
    3,
    2,
    4,
    5,
    6,
    7
];
var filters = {
    responsibleId: "responsible_id",
    objectName: "object_name",
    objectId: "object_id",
    elementName: "element_name",
    elementId: "element_id",
    conclusion: "conclusion",
    conclusionCode: "conclusionCode",
    roomRate: 240, // Amount in dollars
    discount: 15,  // Percentage discount
    offerPrice: function () {
        var offerRate = this.roomRate * ((100 - this.discount) / 100);
        return offerRate;
    }
};
//
var dataUpdated = 0;
var reportDataObjects;
var inspectionDate;

function getConclusionByDate(conclusion, inspectionDate) {
    var conclusionText = conclusion.conclusion
    var conCode = conclusion.conclusionCode;
    if (undefined !== conclusion.oetask_valid_till && null !== conclusion.oetask_valid_till) {
        var validTillString = conclusion.oetask_valid_till.split("-");
        var validTillDate = new Date(validTillString[0], validTillString[1] - 1, validTillString[2]);
        if ((conCode == 1 || conCode == 2) && validTillDate > inspectionDate) {
            conclusionText = "Voldoet (aanbevolen)";
            //conclusion = 4;
            //return conclusion;
        } else if ((conCode == 3 || conCode == 4) && validTillDate > inspectionDate) {
            conclusionText = "Voldoet (wet)";
            //conclusion = 5;
            //return conclusion;
        }
    }
    switch (conclusionText) {
        case "Document ontbreekt (wet)":
            conclusion = 0;
            break;
        case "Datum gepasseerd (wet)":
            conclusion = 1;
            break;
        case "Document ontbreekt (aanbevolen)":
            conclusion = 2;
            break;
        case "Datum gepasseerd (aanbevolen)":
            conclusion = 3;
            break;
        case "Voldoet (wet)":
            conclusion = 4;
            break;
        case "Voldoet (aanbevolen)":
            conclusion = 5;
            break;
        default:
            conclusion = 6;
    }
    return conclusion;
}

function getConclusion(conclusionText) {
    var conclusion;
    switch (conclusionText) {
        case "Document ontbreekt (wet)":
            conclusion = 1;
            break;
        case "Datum gepasseerd (wet)":
            conclusion = 3;
            break;
        case "Document ontbreekt (aanbevolen)":
            conclusion = 2;
            break;
        case "Datum gepasseerd (aanbevolen)":
            conclusion = 4;
            break;
        case "Voldoet (wet)":
            conclusion = 5;
            break;
        case "Voldoet (aanbevolen)":
            conclusion = 6;
            break;
        default:
            conclusion = 0;
    }
    return conclusion;
}

function getFilteredData() {
    var filteredData = [];
    //if object or element is selected
    if (objectId === null) {
        for (var i = 0; i < conclusions.length; i++) {
            filteredData.push(conclusions[i]);
        }
    } else {
        for (var i = 0; i < conclusions.length; i++) {
            if (conclusions[i].object_id === objectId) {
                filteredData.push(conclusions[i]);
            }
        }
    }
    return filteredData;
}

function submitForm(path, params) {
    var method = "post"; // Set method to post by default if not specified.
    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    var form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);
    for (var key in params) {
        if (params.hasOwnProperty(key)) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);
            form.appendChild(hiddenField);
        }
    }
    document.body.appendChild(form);
    form.submit();
}

$('.canvas-to-image').click(function (e) {
    e.preventDefault();
    var elementId = $(this).data('canvas-id');
    var canvas = document.getElementById(elementId);
    var img = canvas.toDataURL("image/png");
    var imgId = '#img-' + elementId
    canvas.remove();
    $(imgId).attr('src', img);
});

function loadDashboardData(url) {
    return $.ajax({
        url: url,
        method: 'GET',
    });
}

function initDashboard(objectId) {

    var objectId;
    if ($('#passport-object-id').length) {
        objectId = $('#passport-object-id').data('object-id');
    } else if (typeof objectId === "undefined" || objectId === null) {
        objectId = null;
    }

    var url = Routing.generate('dashboard_api_one', {id: objectId});
    var urlTest = Routing.generate('dashboard_api');
    loadDashboardData(url).then(
        function (response) {

            conclusions = response.data.conclusions;
            populateSelectButton(response);
            populateSelectButtonsBiPart('element');
            populateSelectButtonsBiPart('object');
            createChartFunction();
            updateNavBarWithObjects();
            createBiPartChartObjects();
            createBiPartChartElements();
            initSaveState();
        }, function (response) {
            console.log(response);
        }
    )
}

function updateNavBarWithObjects() {
    var object = [];
    for (var i = 0; i < conclusions.length; i++) {
        var objectId = conclusions[i].object_id;
        var objectName = conclusions[i].object_name;
        var added = false;
        $.map(object, function (elementOfArray, indexInArray) {
            if (elementOfArray.id == objectId) {
                added = true;
            }
        });
        if (!added) {
            object.push({id: objectId, name: objectName})
        }
    }
    object.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    })

    var objectHolder = $('ul#navbar-object-log-holder');
    for (var i = 0; i < object.length; i++) {
        var url = Routing.generate('tasks_index_filter_object', {id: object[i].id});
        var icon = $('');
        var objectLi = $('<a href="' + url + '"></a>');
        var newObjectLink = $('<li></li>')
        objectLi.append(icon);
        objectLi.append(object[i].name);
        newObjectLink.append(objectLi);
        objectHolder.append(newObjectLink);
    }
    var test = 0;
}

function initReportBarChart() {
    var url = Routing.generate('dashboard_api');
    $.ajax({
        url: url,
        method: 'GET',
    }).then(function (response) {
        reportDataObjects = response.data.objects;
        conclusions = response.data.conclusions;
        var dateStringHolder = $('#inspection-date-report');
        var dateString = dateStringHolder.html().split("-");
        inspectionDate = new Date(dateString[2], dateString[1] - 1, dateString[0]);
        var reportCharts = $('.barChartReport');
        reportCharts.each(function () {
            objectId = $(this).data('object-id');
            createChartFunction();
        });
    }, function (response) {
        alert('Something went wrong, please reload the page');
    });
}

let objectScorePieChart;

function createChartFunction() {
    var ctxBar;
    var scorePie;
    var elementId;
    var filteredData = getFilteredData();
    var a = 0, b = 0, c = 0, d = 0, e = 0, f = 0, g = 0;
    var data = [a, b, c, d, e, f, g];
    var datataskTypeOne = [a, b, c, d, e, f, g];
    var datataskTypeTwo = [a, b, c, d, e, f, g];
    var datataskTypeAll = [a, b, c, d, e, f, g];
    for (var i = 0; i < filteredData.length; i++) {
        //var conclusion;
        var type = filteredData[i].oetask_planning_type;
        //conclusion = getConclusion(filteredData[i].conclusion);
        var conclusionByDate = getConclusionByDate(filteredData[i], inspectionDate);
        //var conclusion = getConclusion(conclusionByDate);
        var conclusion = conclusionByDate;
        data[conclusion] = data[conclusion] + 1;
        if (filteredData[i].task_id === 34) {
            var test = 'test';
            var testTwo;
        }
        if (type === 2 || type === 3) {
            //task is or planned or there is a contract for the task
            datataskTypeTwo[conclusion] = datataskTypeTwo[conclusion] + 1;
        } else {
            //task is not planned
            datataskTypeOne[conclusion] = datataskTypeOne[conclusion] + 1;
        }
        datataskTypeAll[conclusion] = datataskTypeAll[conclusion] + 1;
    }
    if (null !== objectId) {
        elementId = "barChartReport" + objectId;
        ctxBar = document.getElementById(elementId);
        var objectData = $.grep(reportDataObjects, function (e) {
            return e.id === objectId;
        });
    } else {
        elementId = 'barChartDashboard';
        ctxBar = document.getElementById("barChartDashboard");
    }
    if (null !== ctxBar) {
        var dataChart = data;
        objectBarChart = new Chart(ctxBar, {
            type: 'bar',
            data: {
                customData: customData,
                labels: dataLabels,
                datasets: [{
                    //label: '# aantal',
                    data: datataskTypeOne,
                    backgroundColor: [
                        'rgba(203, 23, 30, 0.7)',
                        'rgba(203, 23, 30, 0.7)',
                        'rgba(255, 152, 0, 0.7)',
                        'rgba(255, 152, 0, 0.7)',
                        'rgba(67, 154, 70, 0.7)',
                        'rgba(67, 154, 70, 0.7)',
                        'rgba(33,150,243,0.7)',
                    ],
                    borderWidth: 1
                }, {
                    //label: '# gepland of gecontracteerd',
                    data: datataskTypeTwo,
                    backgroundColor: [
                        'rgba(203, 23, 30, 0.5)',
                        'rgba(203, 23, 30, 0.5)',
                        'rgba(255, 152, 0, 0.5)',
                        'rgba(255, 152, 0, 0.5)',
                        'rgba(67, 154, 70, 0.9)',
                        'rgba(67, 154, 70, 0.9)',
                        'rgba(33,150,243,0.5)',
                    ],
                    borderWidth: 1
                }
                ]
            },
            options: {
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        },
                        stacked: true
                    }],
                    xAxes: [{
                        stacked: true
                    }]
                },
                animation: {
                    onComplete: function (animation) {
                        //convert canvas to png for printing purposes
                        if (elementId !== 'barChartDashboard') {
                            var canvas = document.getElementById(elementId);
                            var img = canvas.toDataURL("image/png");
                            var imgId = '#img-' + elementId
                            $(imgId).attr('src', img);
                        }
                    }
                }
            },
        });
        ctxBar.onHover = function hover(evt) {
            alert('hover');
        }
        //function post(path, params, method) {
        ctxBar.onclick = function post(evt) {
            var gebouwId = $("#select-object-report").val();
            var elementId = 0;
            var elementName = 'Alle elementen';
            var gebouwNameVal = ('Alle gebouwen' === gebouwId) ? gebouwId : $("#select-object-report option[value=" + gebouwId + "]").text();
            var gebouwName = (null !== gebouwId) ? gebouwNameVal : 'Alle gebouwen';
            var responsibleId = $("#select-responsible-report").val();
            var responsibleName = ('Alle verantwoordelijken' === responsibleId) ? responsibleId : $("#select-responsible-report option[value=" + responsibleId + "]").text();
            var activePoints = objectBarChart.getElementsAtEvent(evt);
            var chartData = activePoints[0]['_chart'].config.data;
            var idx = activePoints[0]['_index'];
            var label = chartData.labels[idx];
            var custom = chartData.customData[idx];
            var value = chartData.datasets[0].data[idx];
            $('#modalSubmitClickChart .object-name').text('Gebouw: ' + gebouwName);
            $('#modalSubmitClickChart .element-name').text('Element: ' + elementName);
            $('#modalSubmitClickChart .conclusion-name').text('Conclusie: ' + label);
            $('#modalSubmitClickChart .responsible-name').text('Verantwoordelijke: ' + responsibleName);
            // $('#modalSubmitClickChart').modal('show');
            var modalSubmitClickChart = new bootstrap.Modal(document.getElementById('modalSubmitClickChart'));
            modalSubmitClickChart.show();
            $('#dashboard-click-submit').click(function (e) {
                e.preventDefault();
                var path = Routing.generate('tasks_index_filter');
                var params = [gebouwId, elementId, custom, responsibleId];
                submitForm(path, params)
            });
        }
    }
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    //DashboardScorePie
    var ctxPieScore = document.getElementById("pieScoreDashboard");
    var totalObligated = datataskTypeAll[0] + datataskTypeAll[1] + datataskTypeAll[4];
    var totalRecom = datataskTypeAll[2] + datataskTypeAll[3] + datataskTypeAll[5];
    var scoreObligatedTemp = ((datataskTypeAll[0] / totalObligated) * -10) + ((datataskTypeAll[1] / totalObligated) * -4) + 10;
    var scoreRecomTemp = ((datataskTypeAll[2] / totalRecom) * -10) + ((datataskTypeAll[3] / totalRecom) * -4) + 10;
    var scoreObligated = (isNaN(scoreObligatedTemp)) ? 0 : scoreObligatedTemp;
    var scoreRecom = (isNaN(scoreRecomTemp)) ? 0 : scoreRecomTemp;
    if (0 == scoreObligated && 0 !== scoreRecom) {
        var scoreTotal = Math.floor(scoreRecom * 10);
        var scoreData = [scoreTotal, 100 - scoreTotal]
    } else if (0 !== scoreObligated && 0 == scoreRecom) {
        var scoreTotal = Math.floor(scoreObligated * 10);
        var scoreData = [scoreTotal, 100 - scoreTotal]
    } else {
        var scoreTotal = Math.floor(((4 * scoreObligated) + scoreRecom) / 5 * 10);
        var scoreData = [scoreTotal, 100 - scoreTotal]
    }
    if (null !== ctxPieScore) {
        var dataPie = {
            labels: ['Score', 'Score'],
            datasets: [
                {
                    data: scoreData,
                    backgroundColor: [
                        'rgba(67, 154, 70, 0.7)',
                        'rgba(203, 23, 30, 0.7)'
                    ],
                    hoverBackgroundColor: [
                        'rgba(67, 154, 70, 0.7)',
                        'rgba(203, 23, 30, 0.7)'
                    ]
                }]
        };

        objectScorePieChart = new Chart(ctxPieScore, {
            type: 'doughnut',
            data: dataPie,
            options: {
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    }
                }
            }
        });
        $('.score-counter').each(function () {
            $(this).prop('Counter', 0).animate({
                //Counter: scoreData[0] / 10
                Counter: scoreData[0]
            }, {
                duration: 1500,
                easing: 'swing',
                step: function (now) {
                    var score = now.toFixed(1).toString();
                    var scoreComma = parseFloat(score.replace(',', '').replace(' ', ''));
                    //$(this).text(scoreComma);
                    $(this).text(scoreComma + " %");
                }
            });
        });
    }
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    //DashboardPie
    var ctxPie = document.getElementById("pieChartDashboard");
    if (null !== ctxPie) {
        var dataPie = {
            labels: dataLabels,
            datasets: [
                {
                    data: datataskTypeAll,
                    backgroundColor: [
                        'rgba(203, 23, 30, 0.7)',
                        'rgba(203, 23, 30, 0.7)',
                        'rgba(255, 152, 0, 0.7)',
                        'rgba(255, 152, 0, 0.7)',
                        'rgba(67, 154, 70, 0.7)',
                        'rgba(67, 154, 70, 0.7)',
                        'rgba(33,150,243,0.7)',
                    ],
                    hoverBackgroundColor: [
                        'rgba(203, 23, 30, 0.9)',
                        'rgba(203, 23, 30, 0.9)',
                        'rgba(255, 152, 0, 0.9)',
                        'rgba(255, 152, 0, 0.9)',
                        'rgba(67, 154, 70, 0.9)',
                        'rgba(67, 154, 70, 0.9)',
                        'rgba(33,150,243,0.9)',
                    ]
                }]
        };
        objectPieChart = new Chart(ctxPie, {
            type: 'pie',
            data: dataPie,
            options: {
                legend: {
                    display: false,
                    labels: {
                        fontColor: 'rgb(255, 99, 132)'
                    }
                }
            }
        });
    }
}

function populateSelectButtonsBiPart(filter) {
    if (filter === 'element') {
        var $select = $('#select-element-bipart');
    } else {
        var $select = $('#select-object-bipart');
    }
    var uniques = [];
    for (var i = 0; i < conclusions.length; i++) {
        if (filter === 'element') {
            var id = conclusions[i].element_id;
            var name = conclusions[i].element_name;
        } else {
            var id = conclusions[i].object_id;
            var name = conclusions[i].object_name;
        }
        var found = uniques.some(function (el) {
            return el.id === id;
        });
        if (!found) {
            uniques.push({id: id, name: name});
        }
    }
    uniques.sort(function (a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    })
    for (var i = 0; i < uniques.length; i++) {
        $select.append('<option value=' + uniques[i].id + '>' + uniques[i].name + '</option>');
    }
}

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        if (sortOrder == -1) {
            return b[property].localeCompare(a[property]);
        } else {
            return a[property].localeCompare(b[property]);
        }
    }
}

function populateSelectButton() {
    var $selectResponsible = $('#select-responsible-report');
    var $selectResponsibleBiPart = $('#select-responsible-element-bipart');
    var $selectCategoryBiPart = $('#select-category-bipart');
    //var $selectElementBiPart = $('#select-element-bipart');
    var array = [];
    //populate objectbutton
    var elmsToAppend = [];
    conclusions.sort(dynamicSort('object_name'));
    for (var i = 0; i < conclusions.length; i++) {
        if (0 === i) {
            elmsToAppend.push('<option>' + 'Alle gebouwen' + '</option>');
        }
        var id = conclusions[i].object_id;
        var name = conclusions[i].object_name;
        if ((id in array) == false) {
            array[id] = name;
            elmsToAppend.push('<option value=' + id + '>' + name + '</option>');
        }
    }
    $('#select-object-report').html(elmsToAppend)
    var array = [];
    var catArray = [];
    //populate responsiblebutton
    for (var i = 0; i < conclusions.length; i++) {
        if (0 === i) {
            $selectResponsible.append('<option value="0">' + 'Alle verantwoordelijken' + '</option>');
            $selectResponsibleBiPart.append('<option value="0">' + 'Alle verantwoordelijken' + '</option>');
            $selectCategoryBiPart.append('<option value="0">' + 'Alle afdelingen' + '</option>');
        }
        var id = conclusions[i].responsible_id;
        var catId = conclusions[i].object_category_id;
        var name = conclusions[i].responsible_name;
        var catName = conclusions[i].object_category_name;
        if ((id in array) == false) {
            array[id] = name;
            $selectResponsible.append('<option value=' + id + '>' + name + '</option>');
            $selectResponsibleBiPart.append('<option value=' + id + '>' + name + '</option>');
        }
        if ((catId in catArray) == false) {
            catArray[catId] = catName;
            $selectCategoryBiPart.append('<option value=' + catId + '>' + catName + '</option>');
        }
    }

}

function createDataForBiPartObjects(objects, filterObject) {
    var filteredData = [];
    if (typeof filterObject === "undefined" || filterObject === null || filterObject === 0) {
        for (var i = 0; i < objects.length; i++) {
            filteredData.push(objects[i]);
        }
    } else {
        for (var i = 0; i < objects.length; i++) {
            if (objects[i].element_id === filterObject) {
                filteredData.push(objects[i]);
            }
        }
    }
    var data = [];
    for (var i = 0; i < filteredData.length; i++) {
        var conclusion;
        conclusion = getConclusion(filteredData[i].conclusion);
        data.push([conclusion, filteredData[i].conclusion, filteredData[i].object_category_name, 1]);
    }

    function sortData(a, b) {
        a = a[0];
        b = b[0];
        return a < b ? -1 : (a > b ? 1 : 0);
    }

    data.sort(sortData);
    return data;
};

function createDataForBiPartElements(elements, filterObject) {
    var filteredData = [];
    if (typeof filterObject === "undefined" || filterObject === null || filterObject === 0) {
        for (var i = 0; i < elements.length; i++) {
            filteredData.push(elements[i]);
        }
    } else {
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].object_id === filterObject) {
                filteredData.push(elements[i]);
            }
        }
    }
    var data = [];
    var test;
    for (var i = 0; i < filteredData.length; i++) {
        var conclusion;
        conclusion = getConclusion(filteredData[i].conclusion);
        if (4 === conclusion) {
            test = filteredData[i];
        }
        data.push([conclusion, filteredData[i].conclusion, filteredData[i].element_name, 1]);
    }

    function sortData(a, b) {
        a = a[0];
        b = b[0];
        return a < b ? -1 : (a > b ? 1 : 0);
    }

    data.sort(sortData);
    return data;
};

function createBiPartChartObjects() {
    var filteredData = [];
    if (typeof filterObject === "undefined" || filterObject === null || filterObject === 0) {
        for (var i = 0; i < conclusions.length; i++) {
            filteredData.push(conclusions[i]);
        }
    } else {
        for (var i = 0; i < conclusions.length; i++) {
            if (conclusions[i].element_id === filterObject) {
                filteredData.push(conclusions[i]);
            }
        }
    }
    var data = [];
    for (var i = 0; i < filteredData.length; i++) {
        var conclusion;
        conclusion = getConclusion(filteredData[i].conclusion);
        data.push([conclusion, filteredData[i].conclusion, filteredData[i].object_category_name, 1, filteredData[i].object_category_id]);
    }

    function sortData(a, b) {
        a = a[0];
        b = b[0];
        return a < b ? -1 : (a > b ? 1 : 0);
    }

    data.sort(sortData);
    var initWidth = $('#row-dpchart > .col-sm-12').width() + 30;
    var maxWidth = initWidth;
    var width = (initWidth > maxWidth) ? maxWidth : initWidth;
    var initHeight = width * (5 / 10);
    var maxHeight = 500;
    var height = (initHeight > maxHeight) ? maxHeight : initHeight;
    //var colorOriginal ={Elite:"#3366CC", Grand:"#DC3912",  Lite:"#FF9900", Medium:"#109618", Plus:"#990099", Small:"#0099C6"};
    var color = {
        "Document ontbreekt (wet)": "rgba(203, 23, 30, 0.7)",
        "Datum gepasseerd (wet)": "rgba(203, 23, 30, 0.7)",
        "Document ontbreekt (aanbevolen)": "rgba(255, 152, 0, 0.7)",
        "Datum gepasseerd (aanbevolen)": "rgba(255, 152, 0, 0.7)",
        "Voldoet (wet)": "rgba(67, 154, 70, 0.7)",
        "Voldoet (aanbevolen)": "rgba(67, 154, 70, 0.7)",
        "In behandeling": "rgba(33,150,243,0.7)",
    };
    var bp;
    bp = viz.bP()
        .data(data)
        .min(12)
        .pad(1)
        .height(height * 0.8)
        .width(width)
        .keyPrimary(function (d) {
            return d[1]
        })
        .keySecondary(function (d) {
            return d[2]
        })
        .value(function (d) {
            return d[3]
        })
        .orient("horizontal")
        .barSize(35)
        .fill(function (d) {
            return color[d.primary]
        })
        .sortPrimary(sortData);
    var svg = d3.select("#dpchart1").append("svg").attr("width", width).attr("height", height);
    var g = svg.append("g").attr("transform", "translate(0,30)");
    $('#select-element-bipart').change(function () {
        var elementId = parseInt($(this).val());
        //createBiPartChartObjects(conclusions, elementId, true); //3rd param = if update chart is true or false
        data = createDataForBiPartObjects(conclusions, elementId);
        d3.selectAll("#dpchart1 > *").remove();
        var bp = viz.bP()
            .data(data)
            .min(12)
            .pad(1)
            .height(height * 0.8)
            .width(width)
            .keyPrimary(function (d) {
                return d[1]
            })
            .keySecondary(function (d) {
                return d[2]
            })
            .value(function (d) {
                return d[3]
            })
            .orient("horizontal")
            .barSize(35)
            .fill(function (d) {
                return color[d.primary]
            })
            .sortPrimary(sortData);
        var svg = d3.select("#dpchart1").append("svg").attr("width", width).attr("height", height);
        var g = svg.append("g").attr("transform", "translate(0,30)");
        g.call(bp);
        g.selectAll(".mainBars")
            .on("mouseover", mouseover)
            .on("mouseout", mouseout)
            .on('click', click)
        g.selectAll(".mainBars").append("text").attr("class", "label")
            //.attr("x", d=>(d.part == "primary" ? 0 : 0))
            .attr("x", function (d) {
                return d.part == "primary" ? 0 : 0
            })
            //.attr("y", d=>+30)
            .attr("y", function (d) {
                return +30
            })
            .text(function (d) {
                return d.key
            })
            //.attr("text-anchor", d=>(d.part == "primary" ? "end" : "start"));
            .attr("text-anchor", function (d) {
                return d.part == "primary" ? "end" : "start"
            });
        g.selectAll(".mainBars").append("text").attr("class", "perc")
            //.attr("x", d=>(d.part == "primary" ? 0 : 0))
            .attr("x", function (d) {
                return d.part == "primary" ? 0 : 0
            })
            //.attr("y", d=>-30)
            .attr("y", function (d) {
                return -30
            })
            .text(function (d) {
                return d3.format(".0f")(d.value)
            })
            //.attr("text-anchor", d=>(d.part == "primary" ? "end" : "start"));
            .attr("text-anchor", function (d) {
                return d.part == "primary" ? "end" : "start"
            });

        function mouseover(d) {
            bp.mouseover(d);
            g.selectAll(".mainBars")
                .select(".perc")
                .text(function (d) {
                    return d3.format(".0f")(d.value)
                })
        }

        function mouseout(d) {
            bp.mouseout(d);
            g.selectAll(".mainBars")
                .select(".perc")
                .text(function (d) {
                    return d3.format(".0f")(d.value)
                })
        }
    });
    g.call(bp);
    g.selectAll(".mainBars")
        .on("mouseover", mouseover)
        .on("mouseout", mouseout)
        .on('click', click)

    function click(d) {
        var gebouwId = 0;
        var gebouwName = "Alle gebouwen";
        var categoryId = 0;
        var categoryName = "Alle afdelingen";
        var elementId = parseInt($("#select-element-bipart").val());
        var elementName = $("#select-element-bipart option[value=" + elementId + "]").text();
        var responsibleId = $("#select-responsible-element-bipart").val();
        var responsibleName = ('Alle verantwoordelijken' === responsibleId) ? responsibleId : $("#select-responsible-report option[value=" + responsibleId + "]").text();
        var conclusionId = 0;
        var conclusionName = "Alle conclusies"
        if ('secondary' === d.part) {
            categoryId = $('#select-category-bipart option').filter(function () {
                //var test = $(this).text();
                return $(this).text() == d.key;
            }).val();
            if (d.key === 'Onbekend') {
                categoryId = null;
            }
            categoryName = d.key;
        } else {
            conclusionName = d.key;
            conclusionId = getConclusion(conclusionName);
        }

        $('#modalSubmitClickChart .object-name').text('Gebouw: ' + gebouwName);
        $('#modalSubmitClickChart .category-name').text('Afdeling: ' + categoryName);
        $('#modalSubmitClickChart .conclusion-name').text('Conclusie: ' + conclusionName);
        $('#modalSubmitClickChart .element-name').text('Element: ' + elementName);
        $('#modalSubmitClickChart .responsible-name').text('Verantwoordelijke: ' + responsibleName);
        var modalSubmitClickChart = new bootstrap.Modal(document.getElementById('modalSubmitClickChart'));
        modalSubmitClickChart.show();
        $('#dashboard-click-submit').click(function (e) {
            e.preventDefault();
            var path = Routing.generate('tasks_index_filter');
            var params = [gebouwId, elementId, conclusionId, responsibleId, categoryId];
            var method = "post"; // Set method to post by default if not specified.
            // The rest of this code assumes you are not using a library.
            // It can be made less wordy if you use one.
            var form = document.createElement("form");
            form.setAttribute("method", method);
            form.setAttribute("action", path);
            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    var hiddenField = document.createElement("input");
                    hiddenField.setAttribute("type", "hidden");
                    hiddenField.setAttribute("name", key);
                    hiddenField.setAttribute("value", params[key]);
                    form.appendChild(hiddenField);
                }
            }
            document.body.appendChild(form);
            form.submit();
        });
    }

    g.selectAll(".mainBars").append("text").attr("class", "label")
        //.attr("x", d=>(d.part == "primary" ? 0 : 0))
        .attr("x", function (d) {
            return d.part == "primary" ? 0 : 0
        })
        //.attr("y", d=>+30)
        .attr("y", function (d) {
            return +30
        })
        .text(function (d) {
            return d.key
        })
        //.attr("text-anchor", d=>(d.part == "primary" ? "end" : "start"));
        .attr("text-anchor", function (d) {
            return d.part == "primary" ? "end" : "start"
        });
    g.selectAll(".mainBars").append("text").attr("class", "perc")
        //.attr("x", d=>(d.part == "primary" ? 0 : 0))
        .attr("x", function (d) {
            return d.part == "primary" ? 0 : 0
        })
        .attr("y", function (d) {
            return -30
        })
        .text(function (d) {
            return d3.format(".0f")(d.value)
        })
        //.attr("text-anchor", d=>(d.part == "primary" ? "end" : "start"));
        .attr("text-anchor", function (d) {
            return d.part == "primary" ? "end" : "start"
        });

    function mouseover(d) {
        bp.mouseover(d);
        g.selectAll(".mainBars")
            .select(".perc")
            .text(function (d) {
                return d3.format(".0f")(d.value)
            })
    }

    function mouseout(d) {
        bp.mouseout(d);
        g.selectAll(".mainBars")
            .select(".perc")
            .text(function (d) {
                return d3.format(".0f")(d.value)
            })
    }

    d3.select(self.frameElement).style("height", height);
}

function createBiPartChartElements() {
    var filteredData = [];
    if (typeof filterElement === "undefined" || filterElement === null || filterElement === 0) {
        for (var i = 0; i < conclusions.length; i++) {
            filteredData.push(conclusions[i]);
        }
    } else {
        for (var i = 0; i < conclusions.length; i++) {
            if (conclusions[i].element_id === filterElement) {
                filteredData.push(objects[i]);
            }
        }
    }
    var data = [];
    for (var i = 0; i < filteredData.length; i++) {
        var conclusion;
        conclusion = getConclusion(filteredData[i].conclusion);
        data.push([conclusion, filteredData[i].conclusion, filteredData[i].element_name, 1]);
    }

    //function sortData(a,b){ return d3.ascending(data.indexOf(a),data.indexOf(b)); }
    function sortData(a, b) {
        a = a[0];
        b = b[0];
        return a < b ? -1 : (a > b ? 1 : 0);
    }

    data.sort(sortData);
    var initHeight = ("window", $(window).height()) - 75;
    var maxHeight = 1000;
    var height = (initHeight > maxHeight) ? maxHeight : initHeight;
    var widthHeightRatio = 1.2;
    var initWidth = $('#row-dpchart > .row-dpchart-vertical').width() + 30;
    var maxWidth = $('#row-dpchart > .row-dpchart-vertical').width() + 30;
    var width = (initWidth > maxWidth) ? maxWidth : initWidth;
    //var colorOriginal ={Elite:"#3366CC", Grand:"#DC3912",  Lite:"#FF9900", Medium:"#109618", Plus:"#990099", Small:"#0099C6"};
    var color = {
        "Document ontbreekt (wet)": "rgba(203, 23, 30, 0.7)",
        "Datum gepasseerd (wet)": "rgba(203, 23, 30, 0.7)",
        "Document ontbreekt (aanbevolen)": "rgba(255, 152, 0, 0.7)",
        "Datum gepasseerd (aanbevolen)": "rgba(255, 152, 0, 0.7)",
        "Voldoet (wet)": "rgba(67, 154, 70, 0.7)",
        "Voldoet (aanbevolen)": "rgba(67, 154, 70, 0.7)",
        "In behandeling": "rgba(33,150,243,0.7)",
    };
    var svg = d3.select("#dpchart2").append("svg").attr("width", width).attr("height", height);
    var g = svg.append("g").attr("transform", "translate(150,15)");
    var bp = viz.bP()
        .data(data)
        .min(12)
        .pad(1)
        .height(height - 30)
        .width(width * 0.7)
        .keyPrimary(function (d) {
            return d[1]
        })
        .keySecondary(function (d) {
            return d[2]
        })
        .value(function (d) {
            return d[3]
        })
        .orient("vertical")
        .barSize(35)
        .fill(function (d) {
            return color[d.primary]
        })
        .sortPrimary(sortData)
    $('#select-object-bipart').change(function () {
        var objectId = parseInt($(this).val());
        //createBiPartChartObjects(conclusions, elementId, true); //3rd param = if update chart is true or false
        data = createDataForBiPartElements(conclusions, objectId);
        d3.selectAll("#dpchart2 > *").remove();
        var bp = viz.bP()
            .data(data)
            .min(12)
            .pad(1)
            .height(height - 30)
            .width(width * 0.7)
            .keyPrimary(function (d) {
                return d[1]
            })
            .keySecondary(function (d) {
                return d[2]
            })
            .value(function (d) {
                return d[3]
            })
            .orient("vertical")
            .barSize(35)
            .fill(function (d) {
                return color[d.primary]
            })
            .sortPrimary(sortData)
        var svg = d3.select("#dpchart2").append("svg").attr("width", width).attr("height", height);
        var g = svg.append("g").attr("transform", "translate(150,15)");
        g.call(bp);
        g.selectAll(".mainBars")
            .on("mouseover", mouseover)
            .on("mouseout", mouseout)
            .on('click', click)
        g.selectAll(".mainBars").append("text").attr("class", "label")
            .attr("x", function (d) {
                return d.part == "primary" ? -20 : 20
            })
            .attr("y", function (d) {
                return 0
            })
            .text(function (d) {
                return d.key
            })
            .attr("text-anchor", function (d) {
                return d.part == "primary" ? "end" : "start"
            });
        g.selectAll(".mainBars").append("text").attr("class", "perc")
            .attr("x", function (d) {
                return d.part == "primary" ? 0 : 0
            })
            .attr("y", function (d) {
                return 0
            })
            .text(function (d) {
                return d3.format(".0f")(d.value)
            })
            .attr("text-anchor", function (d) {
                return d.part == "primary" ? "end" : "start"
            });

        function mouseover(d) {
            bp.mouseover(d);
            g.selectAll(".mainBars")
                .select(".perc")
                .text(function (d) {
                    return d3.format(".0f")(d.value)
                })
        }

        function mouseout(d) {
            bp.mouseout(d);
            g.selectAll(".mainBars")
                .select(".perc")
                .text(function (d) {
                    return d3.format(".0f")(d.value)
                })
        }
    });
    g.call(bp);
    g.selectAll(".mainBars")
        .on("mouseover", mouseover)
        .on("mouseout", mouseout)
        .on('click', click)

    function click(d) {
        var gebouwId = parseInt($("#select-object-bipart").val());
        var gebouwName = $("#select-object-bipart option[value=" + gebouwId + "]").text();
        var elementId = 0;
        var elementName = "Alle elementen";
        var conclusionId = 0;
        var conclusionName = "Alle conclusies";
        var responsibleId = 0;
        if ('secondary' === d.part) {
            elementId = $('#select-element-bipart option').filter(function () {
                return $(this).html() == d.key;
            }).val();
            elementName = d.key;
        } else {
            conclusionName = d.key;
            conclusionId = getConclusion(conclusionName);
        }
        $('#modalSubmitClickChart .object-name').text('Gebouw: ' + gebouwName);
        $('#modalSubmitClickChart .conclusion-name').text('Conclusie: ' + conclusionName);
        $('#modalSubmitClickChart .element-name').text('Element: ' + elementName);
        //$('#modalSubmitClickChart').modal('show');
        var modalSubmitClickChart = new bootstrap.Modal(document.getElementById('modalSubmitClickChart'));
        modalSubmitClickChart.show();
        $('#dashboard-click-submit').click(function (e) {
            e.preventDefault();
            var path = Routing.generate('tasks_index_filter');
            var params = [gebouwId, elementId, conclusionId, responsibleId];
            var method = "post"; // Set method to post by default if not specified.
            // The rest of this code assumes you are not using a library.
            // It can be made less wordy if you use one.
            var form = document.createElement("form");
            form.setAttribute("method", method);
            form.setAttribute("action", path);
            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    var hiddenField = document.createElement("input");
                    hiddenField.setAttribute("type", "hidden");
                    hiddenField.setAttribute("name", key);
                    hiddenField.setAttribute("value", params[key]);
                    form.appendChild(hiddenField);
                }
            }
            document.body.appendChild(form);
            form.submit();
        });
    }

    g.selectAll(".mainBars").append("text").attr("class", "label")
        .attr("x", function (d) {
            return d.part == "primary" ? -20 : 20
        })
        .attr("y", function (d) {
            return 0
        })
        .text(function (d) {
            return d.key
        })
        .attr("text-anchor", function (d) {
            return d.part == "primary" ? "end" : "start"
        });
    g.selectAll(".mainBars").append("text").attr("class", "perc")
        .attr("x", function (d) {
            return d.part == "primary" ? 0 : 0
        })
        .attr("y", function (d) {
            return 0
        })
        .text(function (d) {
            return d3.format(".0f")(d.value)
        })
        .attr("text-anchor", function (d) {
            return d.part == "primary" ? "end" : "start"
        });

    function mouseover(d) {
        bp.mouseover(d);
        g.selectAll(".mainBars")
            .select(".perc")
            .text(function (d) {
                return d3.format(".0f")(d.value)
            })
    }

    function mouseout(d) {
        bp.mouseout(d);
        g.selectAll(".mainBars")
            .select(".perc")
            .text(function (d) {
                return d3.format(".0f")(d.value)
            })
    }

    d3.select(self.frameElement).style("height", height);

};
var objectBarChart;
var objectPieChart;
// Chart 'Samenvatting rapportage' -> keuze gebouw en keuze verantwoordelijke
$(function ($) {
    var objectId = 0;
    var responsibleId = 0;
    //var objectData;
    var dataUpdated = 0;
    var inspectionDate = new Date();
    $('#select-responsible-report').change(function () {
        responsibleId = parseInt($(this).val());
        if (isNaN(responsibleId)) {
            objectId = 0;
        }
        dataUpdated = sortData();
    });
    $('#select-object-report').change(function () {
        objectId = parseInt($(this).val());
        if (isNaN(objectId)) {
            objectId = 0;
        }
        var myObj = {};
        myObj["management-object"] = objectId;
        window.localStorage.setItem("dashboard", JSON.stringify(myObj));
        dataUpdated = sortData();
    });
    //{"time":1502795664258,"start":0,"length":10,"order":[[0,"asc"]],"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true},"columns":[{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}},{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}},{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}},{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}},{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}},{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}},{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}},{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}},{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}},{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}},
    //{"visible":true,"search":{"search":"","smart":true,"regex":false,"caseInsensitive":true}}],"ColReorder":[0,1,2,3,4,5,6,7,8,9,10]}
    $('#select-inspection-date-report').change(function () {
        var dateString = $(this).val().split("-");
        inspectionDate = new Date(dateString[2], dateString[1] - 1, dateString[0]);
        dataUpdated = sortData();
    });

    function sortData(filterType, filterId) {
        if (typeof filterType === "undefined" || filterType === null) {
            filterType = null;
        }
        if (typeof filterId === "undefined" || filterId === null) {
            filterId = null;
        }
        var dataUpdated = [0, 0, 0, 0, 0, 0, 0];
        var a = 0, b = 0, c = 0, d = 0, e = 0, f = 0, g = 0;
        var data = [a, b, c, d, e, f, g];
        var datataskTypeOne = [a, b, c, d, e, f, g];
        var datataskTypeTwo = [a, b, c, d, e, f, g];
        if (objectId === 0 && responsibleId === 0) {
            for (var i = 0; i < conclusions.length; i++) {
                var type = conclusions[i].oetask_planning_type;
                var conclusionByDate = getConclusionByDate(conclusions[i], inspectionDate);
                //var conclusion = getConclusion(conclusionByDate);
                var conclusion = conclusionByDate;
                if (type === 2 || type === 3) {
                    //task is or planned or there is a contract for the task
                    datataskTypeTwo[conclusion] = datataskTypeTwo[conclusion] + 1;
                } else {
                    //task is not planned
                    datataskTypeOne[conclusion] = datataskTypeOne[conclusion] + 1;
                }
                dataUpdated[conclusion] = dataUpdated[conclusion] + 1;
                //dataUpdated[key] = dataUpdated[key] + 1;
            }
        } else if (objectId === 0 && responsibleId !== 0) {
            for (var i = 0; i < conclusions.length; i++) {
                if (conclusions[i][filters.responsibleId] == responsibleId) {
                    var type = conclusions[i].oetask_planning_type;
                    var conclusionByDate = getConclusionByDate(conclusions[i], inspectionDate);
                    //var conclusion = getConclusion(conclusionByDate);
                    var conclusion = conclusionByDate;
                    if (type === 2 || type === 3) {
                        //task is or planned or there is a contract for the task
                        datataskTypeTwo[conclusion] = datataskTypeTwo[conclusion] + 1;
                    } else {
                        //task is not planned
                        datataskTypeOne[conclusion] = datataskTypeOne[conclusion] + 1;
                    }
                    dataUpdated[conclusion] = dataUpdated[conclusion] + 1;
                }
            }
        } else if (objectId !== 0 && responsibleId === 0) {
            for (var i = 0; i < conclusions.length; i++) {
                if (conclusions[i][filters.objectId] == objectId) {
                    var type = conclusions[i].oetask_planning_type;
                    var conclusionByDate = getConclusionByDate(conclusions[i], inspectionDate);
                    //var conclusion = getConclusion(conclusionByDate);
                    var conclusion = conclusionByDate;
                    if (type === 2 || type === 3) {
                        //task is or planned or there is a contract for the task
                        datataskTypeTwo[conclusion] = datataskTypeTwo[conclusion] + 1;
                    } else {
                        //task is not planned
                        datataskTypeOne[conclusion] = datataskTypeOne[conclusion] + 1;
                    }
                    dataUpdated[conclusion] = dataUpdated[conclusion] + 1;
                }
            }
        } else if (objectId !== 0 && responsibleId !== 0) {
            for (var i = 0; i < conclusions.length; i++) {
                if (conclusions[i][filters.objectId] == objectId && conclusions[i][filters.responsibleId] == responsibleId) {
                    var type = conclusions[i].oetask_planning_type;
                    var conclusionByDate = getConclusionByDate(conclusions[i], inspectionDate);
                    //var conclusion = getConclusion(conclusionByDate);
                    var conclusion = conclusionByDate;
                    if (type === 2 || type === 3) {
                        //task is or planned or there is a contract for the task
                        datataskTypeTwo[conclusion] = datataskTypeTwo[conclusion] + 1;
                    } else {
                        //task is not planned
                        datataskTypeOne[conclusion] = datataskTypeOne[conclusion] + 1;
                    }
                    dataUpdated[conclusion] = dataUpdated[conclusion] + 1;
                }
            }
        }
        var datataskTypeAll = dataUpdated;
        var totalObligated = datataskTypeAll[0] + datataskTypeAll[1] + datataskTypeAll[4];
        var totalRecom = datataskTypeAll[2] + datataskTypeAll[3] + datataskTypeAll[5];
        var scoreObligated = ((datataskTypeAll[0] / totalObligated) * -10) + ((datataskTypeAll[1] / totalObligated) * -4) + 10;
        var scoreRecom = ((datataskTypeAll[2] / totalRecom) * -10) + ((datataskTypeAll[3] / totalRecom) * -4) + 10;
        var scoreTotal = Math.floor(((4 * scoreObligated) + scoreRecom) / 5 * 10);
        var scoreDataUpdated = [scoreTotal, 100 - scoreTotal]
        objectBarChart.config.data.datasets[0].data = datataskTypeOne;
        objectBarChart.config.data.datasets[1].data = datataskTypeTwo;
        objectBarChart.update();

        if (undefined !== objectPieChart) {
            objectPieChart.config.data.datasets[0].data = dataUpdated;
            objectPieChart.update();
        }

        if (undefined !== objectScorePieChart) {
            objectScorePieChart.config.data.datasets[0].data = scoreDataUpdated;
            objectScorePieChart.update();
        }

        $('.score-counter').each(function () {
            $(this).prop('Counter', 0).animate({
                //Counter: scoreDataUpdated[0] / 10
                Counter: scoreDataUpdated[0]
            }, {
                duration: 500,
                easing: 'swing',
                step: function (now) {
                    var score = now.toFixed(1).toString();
                    var scoreComma = parseFloat(score.replace(',', '.').replace(' ', ''));
                    //$(this).text(scoreComma);
                    $(this).text(scoreComma + " %");
                }
            });
        });
    }
})
;

function initSaveState() {
    var saveStateString = window.localStorage.getItem('dashboard');
    var saveStateObject = JSON.parse(saveStateString);
    if (null !== saveStateObject) {
        managementObjectId = saveStateObject['management-object'];
        objectId = saveStateObject['management-object'];
    } else {
        managementObjectId = null;
        objectId = null;
    }
    //$('select[name=select-object-report]').val(managementObjectId);
}

/*
jQuery(document).ready(function () {

})
*/
$(document).ready(function () {
    if (window.location.href.indexOf("dashboard") > -1 || window.location.href.indexOf("/object/") > -1) {
        initDashboard();
    }
});


