/*
 -----------------------------------------------
 -----------------------------------------------
 Contract task objects
 -----------------------------------------------
 -----------------------------------------------
 */

var $contracttaskHolder;
var $contractAttachmentHolder;


// setup an "add a child" link
var $addContracttaskLink = $(
    '<a class="btn btn-primary add_contracttask_link" href="#">\n\
<i class="fa fa-plus"></i> Taak toevoegen</a>'
);

var $newContracttaskLinkLi = $('<li class="contracttask-add-link list-unstyled"></li>').append($addContracttaskLink);

var $addContractAttachmentLink = $(
    '<a class="btn btn-primary add_contract_attachment_link" href="#">\n\
<i class="fa fa-plus"></i> Bestand toevoegen</a>'
);

var $newContractAttachmentLinkLi = $('<li class="contract-attachment-add-link list-unstyled"></li>').append($addContractAttachmentLink);


jQuery(document).ready(function () {

    // Get the ul that holds the collection of child
    $contracttaskHolder = $('ul.contracttask-parent');
    $contractAttachmentHolder = $('ul.contract-attachments');

    // add a delete link to all of the existing child form li elements
    $contracttaskHolder.find('li.contracttask-child').each(function () {
        addContracttaskFormDeleteLink($(this));
    });    // add a delete link to all of the existing child form li elements
    // $contracttaskHolder.find('ul.contracttask-object-parent').each(function () {
    //     addContracttaskObjectLinkLi($(this));
    // });
    // add a delete link to all of the existing child-answers form li elements
    // $contracttaskHolder.find('li.contracttask-object-child').each(function () {
    //     addContracttaskObjectFormDeleteLink($(this));
    // });
    // add a delete link to all of the existing child-answers form li elements
    $contractAttachmentHolder.find('li.attachment').each(function () {
        addContracttaskObjectFormDeleteLink($(this));
    });


    // add the "add a child" anchor and li to the child ul
    $contractAttachmentHolder.append($newContractAttachmentLinkLi);

    // add the "add a child" anchor and li to the child ul
    $contracttaskHolder.append($newContracttaskLinkLi);

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $contracttaskHolder.data('index', $contracttaskHolder.find('.contracttask-child').length);

    $addContracttaskLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // add a new child form (see next code block)
        addContracttaskForm($contracttaskHolder, $newContracttaskLinkLi);
    });


});

$('body').on('click', 'a.add_contract_attachment_link', function (e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();

    addContractAttachmentForm($(this));
});


$('body').on('click', 'a.add_contracttask_link', function (e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();

    addContracttaskForm($(this));
});



$('body').on('click', 'a.add-child-contracttask-object-link', function (e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();

    addContracttaskObjectForm($(this));
});

function addContractAttachmentForm($addAttachmentButton) {
    // var indexButton = $addChildObjectButton.data('index-contracttask-object-children');

    // Get all the li from the childanswers (only from the active childelement
    var taskAttachmentHolder = $('ul#contract-attachments-container-' + 0);


    // Get the data-prototype explained earlier
    //var prototypeHolder = $('#prots');
    //var prototypeTaskObject = $('#contractor_agreement_contractortaskobjects___task___contractorobjects___object__').data('prototype-answer');
    var prototypeContractAttachment = $('#proto-attachment').data('prototype-attachment');

    //var prototype = $childrenHolder.data('prototype');

    // get the new index
    var indexAttachment = taskAttachmentHolder.data('index');

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newFormTaskObject = prototypeContractAttachment.replace(/__name__/g, indexAttachment);


    // Display the form in the page in an li, before the "Add a child" link li
    var $newTaskFormLi = $('<li class="list-unstyled well contracttask-object-child"></li>').append(newFormTaskObject);
    $addAttachmentButton.before($newTaskFormLi);
    taskAttachmentHolder.data('index', indexAttachment + 1);

    // add a delete link to the new form
    addContracttaskFormDeleteLink($newTaskFormLi);
}

function addContracttaskObjectForm($addChildObjectButton) {

    var indexButton = $addChildObjectButton.data('index-contracttask-object-children');

    // Get all the li from the childanswers (only from the active childelement
    var taskObjectHolder = $('ul#contracttask-object-parent-' + indexButton);
    var addAnswerButton = taskObjectHolder.find('.child-contracttask-object-add-link');
    //var childElementHolder = $('ul#children-container');


    // Get the data-prototype explained earlier
    //var prototypeHolder = $('#prots');
    //var prototypeTaskObject = $('#contractor_agreement_contractortaskobjects___task___contractorobjects___object__').data('prototype-answer');
    var prototypeTaskObject = $('#prototype-contracttask-object').data('prototype-object');
    var prototypeTaskUser = $('#prototype-contracttask-object').data('prototype-user');
    //var prototype = $childrenHolder.data('prototype');

    // get the new index
    var indexTask = taskObjectHolder.data('index-contracttask-object-children');
    var indexObject = taskObjectHolder.find('li.contracttask-object-child').length;

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newFormTaskObject = prototypeTaskObject.replace(/__name__/g,indexTask ).replace(/__contract_object__/g, indexObject);
    var newFormTaskUser = prototypeTaskUser.replace(/__name__/g, indexObject).replace(/__contract_object__/g, indexTask);

    // increase the index with one for the next item
    taskObjectHolder.data('index', indexObject + 1);
    $addChildObjectButton.data('index-contracttask-object-children', indexButton+1);

    // Display the form in the page in an li, before the "Add a child" link li
    var $newTaskFormLi = $('<li class="list-unstyled well contracttask-object-child"></li>').append(newFormTaskObject).append(newFormTaskUser);
    addAnswerButton.before($newTaskFormLi);

    // add a delete link to the new form
    addContracttaskFormDeleteLink($newTaskFormLi);
}

function addContracttaskForm($taskHolder, $newTaskLinkLi) {
    // Get the data-prototype explained earlier
    var prototypeTask = $('#prototype-contracttask-object').data('prototype-task');
    //var prototypeTaskObject = $('#contractor_agreement_contractortaskobjects___task___contractorobjects___object__').data('prototype-answer');
    // var prototypeTaskObject = $('#prototype-contracttask-object').data('prototype-object');
    // var prototypeTaskUser = $('#prototype-contracttask-object').data('prototype-user');

    // get the new index
    var indexTask = $taskHolder.data('index');


    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    // var newFormTaskObject = prototypeTaskObject.replace(/__contract_object__/g, indexObject).replace(/__name__/g, indexTask);
    // var newFormTaskUser = prototypeTaskUser.replace(/__user__/g, indexObject).replace(/__taskuser__/g, indexTask).replace(/element_children_0_answers_0/g, 'element_children_' + indexTask + '_answers_' + indexObject);
    var liObject = $('<li class="list-unstyled well contracttask-object-child">' +
        '</li>');
    var ulObject = $('<ul data-index-contracttask-object-children="' + indexTask + '" id="contracttask-object-parent-' + indexTask + '" class="collection contracttask-object-parent">' +
        '</ul>').append(liObject);
    var htmlPanelHeading = $('<div class="panel-heading" role="tab" id="heading' + indexTask + '">' +
        '<h4 class="panel-title">' +
        '<a role="button" data-bs-toggle="collapse" data-parent="#accordion' + indexTask + '" href="#collapse' + indexTask + '"' +
        'aria-expanded="false" aria-controls="collapse' + indexTask + '">' +
        '<span class="clickable">' +
        '<i class="pull-left glyphicon glyphicon-minus-sign"></i> Gebouwen' +
        '</span></a></h4></div>');
    var htmlPanelBody = $('<div id="collapse' + indexTask + '" class="panel-collapse" role="tabpanel"' +
        'aria-labelledby="heading' + indexTask + '">' +
        '<div class="panel-body">' +
        '</div></div>');
    htmlPanelBody.find('.panel-body').append(ulObject)


    var newFormTaskObjectUl =
        $('<div class="test form-group">' +
            '<div class="panel-group" id="accordion' + indexTask + '" role="tablist" aria-multiselectable="true">' +
            '<div class="panel panel-default panel-parent">' +
            '</div></div></div>');
    newFormTaskObjectUl.find('.panel-parent').append(htmlPanelHeading).append(htmlPanelBody);
    var newFormTask = $('<div class="form-group"></div>').append(prototypeTask.replace(/__name__/g, indexTask));

    // newFormTask.find('[id*="_objects"]').prepend("<div class=\"checkbox\"><label><input class=\"all_objects_contracttask\" type=\"checkbox\" > Alle gebouwen</label></div>");
    newFormTask.find('[id$="_objects"]').prepend("<div class=\"checkbox\"><label><input data-index-checkbox-contractobject=" + indexTask + " class=\"all_objects_contracttask\" type=\"checkbox\" > Alle gebouwen</label></div>");

    // increase the index with one for the next item
    $taskHolder.data('index', indexTask + 1);

    // Display the form in the page in an li, before the "Add a child" link li
    // var $newTaskFormLi = $('<div class="well"></div>').append(newFormTask).append(newFormTaskObjectUl);
    var $newTaskFormLi = $('<div class="well"></div>').append(newFormTask);
    var $newTaskFormWell = $('<li data-index-contracttask-child="' + indexTask + '" class="list-unstyled"></li>').append($newTaskFormLi);

    $newTaskLinkLi.before($newTaskFormWell);






    // addContracttaskObjectLinkLi(ulObject);
    // addContracttaskFormDeleteLink(liObject);

    // add a delete link to the new form
    addContracttaskFormDeleteLink($newTaskFormLi);
}




function addContracttaskObjectLinkLi($newFormLi) {
    var indexButton = $newFormLi.data('index-contracttask-object-children');

    // setup an "add a child" link
    var $addTaskLink = $(
        '<a data-index-contracttask-object-children="' + indexButton + '" class="btn btn-primary add-child-contracttask-object-link" href="#">\n\
    <i class="fa fa-plus"></i> Gebouw toevoegen</a>'
    );

    var $newTaskLinkLi = $('<li data-index-contracttask-object-children="' + indexButton + '" class="child-contracttask-object-add-link list-unstyled"></li>').append($addTaskLink);
    $newFormLi.append($newTaskLinkLi);

    /*
     var $childElementAnswersHolder = $newFormLi.closest('ul.child-answers');

     // add the "add a child" anchor and li to the child ul
     $childElementAnswersHolder.append($newTaskLinkLi);
     */


}

function addContracttaskObjectFormDeleteLink($newFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    //$newFormLi.append($removeFormA);
    $newFormLi.append($removeFormA);

    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // remove the li for the child form
        $newFormLi.remove();
    });
}

function addContracttaskFormDeleteLink($newFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i> Verwijder taak</a>');
    $newFormLi.append($removeFormA);

    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // remove the li for the child form
        $newFormLi.remove();
    });
}
