/* ----------------------------------------------- -----------------------------------------------
Child elements ----------------------------------------------- ----------------------------------------------- */
var $instructionListParent;
// setup an "add a child" link
var $addinstructionListChildLink = $('<a class="btn btn-primary" href="#" class="add_instruction_child_link"><i class="fa fa-plus"> </i> Instructielijst toevoegen </a>');
var $newInstructionListChildLinkLi = $('<li class="instruction-list-child-add-link list-unstyled"></li>').prepend($addinstructionListChildLink);
jQuery(document).ready(function () {
    // Get the ul that holds the collection of child
    $instructionListParent = $('ul.instruction-list-parent');
    // add a delete link to all of the existing child form li elements
    $instructionListParent.find('li.instruction-list-child').each(function () {
        addInstructionListChildFormDeleteLink($(this));
    });
    // add a 'add condition' link to all of the existing child form li elements
    $instructionListParent.find('ul.instruction-parent').each(function () {
        addInstructionChildLinkLi($(this));
    });
    // add a delete link to all of the existing child-answers form li elements

    $instructionListParent.find('li.instruction-child').each(function () {
        addInstructionChildFormDeleteLink($(this));
        var test_str = $(this).find('[id^="task_instructionlists_"][id *=_instructions_]').attr('id');
        //"task_elementanswertaskgroups_0_elementsanswerstasks_0"
        //$(this).find('[id ^=object_element_task_activities_][id $=_id]')
        //task_elementanswertaskgroups_0_elementsanswerstasks_0
        var start_pos = "task_instructionlists_";
        var end_pos = '_instructions___instructions___information';
        //var indexInstructionListChildString = test_str.substring(start_pos.length, test_str.indexOf(end_pos));
        //var start_pos_two = start_pos.length + end_pos.length + indexInstructionListChildString.length;
        //var indexInstructionListChild = parseInt(indexInstructionListChildString);
        //var indexInstructionString = test_str.substring(start_pos_two, test_str.length);
        //var indexinstructionListParent = parseInt(test_str.substring(start_pos.length, end_pos.length));
        var indexInstructionString = parseInt(test_str.substring(start_pos.length, end_pos.length));
        var indexInstruction = parseInt(indexInstructionString);
        //angular.element(document.getElementById('TaskController')).scope().initDynamicSelect(indexInstructionListChild, indexInstruction);
    });

    // add the "add a child" anchor and li to the child ul
    $instructionListParent.append($newInstructionListChildLinkLi);
    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $instructionListParent.data('index', $instructionListParent.find('li.instruction-list-child').length);
    if ($instructionListParent.data('index') > 0) {
        //task_elementanswertaskgroups
        var test_str = $instructionListParent.find('[id^="task_instructionlists_"]:last').attr('id');
        var start_pos = "task_instructionlists_";
        var end_pos = '_instructions___instructions___information';
        var indexinstructionListParent = parseInt(test_str.substring(start_pos.length, end_pos.length));
        //var indexinstructionListParent = $instructionListParent.find('li.instruction-list-child:last').data('index-instruction-list-child');
        $instructionListParent.data('index', indexinstructionListParent + 1);
    }
    $addinstructionListChildLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();        // add a new child form (see next code block)
        addInstructionListChildForm($instructionListParent, $newInstructionListChildLinkLi);
    });
});

function addInstructionListChildForm($instructionListParent, $newInstructionListChildLinkLi) {
    // Get the data-prototype explained earlier
    var prototypeInstructionListChild = $('#prototype-instruction-list').data('prototype-instruction-list');
    // get the new index
    var indexInstructionListChild = $instructionListParent.data('index');
    var newFormInstructionChild = $('<div class="form-instruction-list"></div>').append(prototypeInstructionListChild.replace(/__instructionlistsproto__/g, indexInstructionListChild));
    var instructionListParent = newFormInstructionChild.find('ul#instruction-parent-' + indexInstructionListChild);
    var indexInstructionChild = instructionListParent.find('.instruction-child').length;
    var prototypeInstructionChild = $('#prototype-instruction-list').data('prototype-instruction-child');
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    //var newFormInstructionChild = prototypeInstructionChild.replace(/__instructionsproto__/g, indexInstructionChild).replace(/__instructionlistsproto__/g, indexInstructionListChild).replace(/group_child_0_condition_child_0/g, 'groups_' + indexInstructionListChild + '_conditions_' + indexInstructionChild);
    //var liConditionChild = $('<li class="instruction-child list-unstyled">' + '</li>').append(newFormInstructionChild);
    //var ulinstructionListParent = $('<ul data-index-instruction-parent="' + indexInstructionListChild + '" id="instruction-parent-' + indexInstructionListChild + '" class="collection instruction-parent">' + '</ul>').append(liConditionChild);
    var ulinstructionListParent = $('<ul data-index-instruction-parent="' + indexInstructionListChild + '" id="instruction-parent-' + indexInstructionListChild + '" class="collection instruction-parent">' + '</ul>');
    var htmlPanelHeading = $('<div class="card-header" role="tab" id="heading' + indexInstructionListChild + '">' + '<h4 class="panel-title">' + '<a role="button" data-bs-toggle="collapse" href="#collapse' + indexInstructionListChild + '"' + 'aria-expanded="true" aria-controls="collapse' + indexInstructionListChild + '">' + '<span class="clickable">' + '<i class="pull-left glyphicon glyphicon-minus-sign"></i>Instructies ' + '</span></a></h4></div>');
    var htmlPanelBody = $('<div id="collapse' + indexInstructionListChild + '" class="panel-collapse" role="tabpanel"' + 'aria-labelledby="heading' + indexInstructionListChild + '">' + '<div class="panel-body">' + '</div></div>');
    htmlPanelBody.find('.panel-body').append(ulinstructionListParent)
    var newFormInstructionChildUl = $('<div class="form-instruction-list">' + '<div class="panel-group" id="accordion' + indexInstructionListChild + '" role="tablist" aria-multiselectable="true">' + '<div class="panel panel-default panel-parent">' + '</div></div></div>');
    newFormInstructionChildUl.find('.panel-parent').append(htmlPanelHeading).append(htmlPanelBody);
    var newFormInstructionChild = $('<div class="form-instruction-list"></div>').append(prototypeInstructionListChild.replace(/__instructionlistsproto__/g, indexInstructionListChild));
    // Display the form in the page in an li, before the "Add a child" link li
    var $newInstructionListChildFormLi = $('<div class="well"></div>').append(newFormInstructionChild).append(newFormInstructionChildUl);
    var $newInstructionListChildFormWell = $('<li data-index-instruction-list-child="' + indexInstructionListChild + '" class="list-unstyled"></li>').append($newInstructionListChildFormLi);
    $newInstructionListChildLinkLi.before($newInstructionListChildFormWell);
    //angular.element(document.getElementById('AttributeController')).append($newInstructionListChildFormWell);
    addInstructionChildLinkLi(ulinstructionListParent);
    //addInstructionListChildFormDeleteLink(liConditionChild);
    // add a delete link to the new form
    addInstructionListChildFormDeleteLink($newInstructionListChildFormLi);
    // Create dynamic fields for the element-answer combination (1. parent-el -> 2. child-el -> 3. answer)
    //angular.element(document.getElementById('TaskController')).scope().initDynamicSelectNew(indexInstructionListChild, indexInstructionChild, newFormInstructionChild);
    // increase the index with one for the next item
    $instructionListParent.data('index', indexInstructionListChild + 1);
}

function addInstructionChildLinkLi($newFormLi) {
    var indexButton = $newFormLi.data('index-instruction-parent');
    // setup an "add a child" link
    var $addinstructionListChildLink = $('<a data-index-instruction-list-child="' + indexButton + '" class="btn btn-primary add-instruction-child-link" href="#"><i class="fa fa-plus"></i> Instructie toevoegen</a>');
    var $newInstructionListChildLinkLi = $('<li data-index-instruction-list-child="' + indexButton + '" class="instruction-list-child-instruction-child-add-link list-unstyled"></li>').append($addinstructionListChildLink);
    $newFormLi.append($newInstructionListChildLinkLi);
}

function addInstructionChildFormDeleteLink($newFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    //$newFormLi.append($removeFormA);
    $newFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
    });
}

function addInstructionListChildFormDeleteLink($newFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    $newFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
    });
}

$(document).on('click', 'a.add-instruction-child-link', function (e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();
    addInstructionChildForm($(this));
});

function addInstructionChildForm($addInstructionChildButton) {
    var indexInstructionListChild = $addInstructionChildButton.data('index-instruction-list-child');
    // Get all the li from the childanswers (only from the active childelement
    var instructionListParent = $('ul#instruction-parent-' + indexInstructionListChild);
    var addInstructionChildButton =
        instructionListParent.find('.instruction-list-child-instruction-child-add-link');
    ////////////////////////////////////////////////////////////////////
    // Get the data-prototype explained earlier
    var prototypeInstructionListChild = $('#prototype-instruction-list').data('prototype-instruction-list');
    // get the new index
    //var indexInstructionListChild = $instructionListParent.data('index');
    //var indexInstructionListChild = indexButton;
    // get the new index
    //var indexInstructionListChild = instructionListParent.data('index-instruction-list-child');
    var indexInstructionChild = instructionListParent.find('li.instruction-child').length;
    var newFormInstructionChild = $('<div class="form-instruction-list"></div>').append(prototypeInstructionListChild.replace(/__instructionsproto__/g, indexInstructionChild));
    //var prototypeInstructionChild = newFormInstructionChild.find('#task_elementanswertaskgroups_' + indexInstructionListChild + '_elementsanswerstasks').data('prototype');
    var prototypeInstructionChild = $('#prototype-instruction-list').data('prototype-instruction-child');
    ////////////////////////////////////////////////////////////////////
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newFormInstructionGroup = prototypeInstructionChild.replace(/__instructionsproto__/g, indexInstructionChild).replace(/__instructionlistsproto__/g, indexInstructionListChild);
    // Display the form in the page in an li, before the "Add a child" link li
    var $newInstructionListChildFormLi = $('<li class="instruction-child list-unstyled"></li>').append(newFormInstructionGroup);
    addInstructionChildButton.before($newInstructionListChildFormLi);
    // add a delete link to the new form
    addInstructionListChildFormDeleteLink($newInstructionListChildFormLi);
    // Create dynamic fields for the element-answer combination (1. parent-el -> 2. child-el -> 3. answer)
    //angular.element(document.getElementById('TaskController')).scope().initDynamicSelectNew(indexInstructionListChild, indexInstructionChild, newFormInstructionChild);
    // increase the index with one for the next item
    instructionListParent.data('index', indexInstructionListChild + 1);
}
