import $ from 'jquery';
import * as bootstrap from 'bootstrap';
import {isNull} from "pdfmake/src/helpers";
require('bootstrap-datepicker')
require('eonasdan-bootstrap-datetimepicker')

/*
$(document).ready(function () {
    $('#lego-submit').click(function (e) {
        e.preventDefault();
        alert("check lego save");
        var post = $(this);
        var position = post.position().top;

        $('.item').each(function(){
            $(this).removeClass("active");
            positions.push({position:$(this).position().top, element: $(this)});
        });

        alert("check lego save : " + $(window).scrollTop());
        //$('#delete-form').submit();
    });
});
*/

// function stickyDiv() {
//     var top = $(window).scrollTop();
//     var divTop = $('.anchor').offset().top;
//     if (top > divTop) {
//         $('.sticky').addClass('stick');
//     } else {
//         $('.sticky').removeClass('stick');
//     }
// }
//
// $(window).scroll(function(){
//     stickyDiv();
// });
// stickyDiv();

// $(document).ready(function () {
$(document).on('click', '.all_objects_contracttask', function(){

        var parent = $(this).parent().parent().parent().parent();

        var checkBoxes = parent.find("input[type=checkbox]");
        var checkedStatus = this.checked;
        checkBoxes.each(function () {
            $(this).prop('checked', checkedStatus);
        });
    });
// });

$(function () {
    $('.back-button').click(function (e) {
        e.preventDefault();
        history.back();
    });
});
$(function () {
    $('.lego-button').click(function (e) {
        e.preventDefault();
        let id = $(this).closest(".lego-list").attr("id");
        let page = document.title;

        if (typeof (history.pushState) !== "undefined") {
            var obj = {Page: page, Url: 'edit#' + id};
            history.pushState(obj, obj.Page, obj.Url);
        } else {
            alert("Browser does not support HTML5.");
        }

        console.log($(this).href);
        let href = $(this).attr('href');
        window.location.href = href;
        //location.href = $(this).href;
    });
});

$(function () {
    $('#lego-submit').click(function (e) {
        e.preventDefault();
        // get the scroll position of the document + half the window height
        var scrollTop = $(document).scrollTop();
        var positions = [];

        // push each of the items we want to check against to an array with their position and selector
        $('.lego-list').each(function () {

            positions.push({position: $(this).position().top, element: $(this)});
        });

        var getClosest = closest(positions, scrollTop);
        //getClosest
        console.log(getClosest.attr("id"));
    });

    // finds the nearest position (from an array of objects) to the specified number
    function closest(array, number) {
        var num = 0;
        for (var i = array.length - 1; i >= 0; i--) {
            if (Math.abs(number - array[i].position) < Math.abs(number - array[num].position)) {
                num = i;
            }
        }
        return array[num].element;
    }
});

/*
$(function(){
    $(window).scroll(function(){
        // get the scroll position of the document + half the window height
        var scrollTop = $(document).scrollTop() + ($(window).height() / 2);
        var positions = [];

        // push each of the items we want to check against to an array with their position and selector
        $('.item').each(function(){
            $(this).removeClass("active");
            positions.push({position:$(this).position().top, element: $(this)});
        });

        var getClosest = closest(positions,scrollTop);
        getClosest.addClass("active"); // the element closest to the middle of the screen
        console.log( getClosest.attr("data-page") );
    });

    // finds the nearest position (from an array of objects) to the specified number
    function closest(array, number) {
        var num = 0;
        for (var i = array.length - 1; i >= 0; i--) {
            if(Math.abs(number - array[i].position) < Math.abs(number - array[num].position)){
                num = i;
            }
        }
        return array[num].element;
    }
});
*/


// $(document).ready(function() {
//     var stickyTop = $('#myAffix').offset().top;
//
//     $(window).scroll(function() {
//         var windowTop = $(window).scrollTop() + 85;
//         // if (stickyTop < windowTop && $("#myScrollspy").height() + $("#myScrollspy").offset().top - $("#myAffix").height() > windowTop) {
//         if (stickyTop < windowTop) {
//             $('#myAffix').css('position', 'fixed').css('top', '85px');
//         } else {
//             $('#myAffix').css('position', 'relative').css('top', 'initial');
//         }
//     });
// });

$.fn.datepicker.dates["nl"] = {
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    daysMin: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
    months: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
    monthsShort: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    today: "Vandaag",
    clear: "Wis",
    format: "dd-mm-yyyy",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 1
};
$.fn.datepicker.defaults.format = "dd-mm-yyyy";
$.fn.datepicker.defaults.todayBtn = "linked";
$.fn.datepicker.defaults.autoclose = true;
$.fn.datepicker.defaults.clearBtn = true;
$.fn.datepicker.defaults.language = "nl";

let flashModalElement = null;
jQuery(document).ready(function () {
    flashModalElement = document.getElementById('flashModal');
    if (!isNull(flashModalElement)){
        const flashModal = new bootstrap.Modal(flashModalElement);
        flashModal.show();
    }

});

$(document).ready(function () {
    // get current URL path and assign 'active' class
    // var pathname = window.location.pathname;
    // $('.nav > li > a[href="' + pathname + '"]').parent().addClass('active');
    // $('.nav > li > a[href="' + pathname + '"]').closest('.panel-collapse').collapse();
});
$(document).ready(function () {
    $(".toggle-accordion").on("click", function () {
        $(".collapse").collapse('toggle');
    })
});
$(function () {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })
});
//Get the number of worklist items
$(document).ready(function () {
    var url = Routing.generate('worklist_api');
    $.ajax({
        url: url,
        type: 'GET',
        cache: false,
        success: function (response) {
            responseFunction(response);
        },
        error: function (e) {
            // Fail message
        }
    })

    function responseFunction(response) {
        $.each(response.data, function (name, count) {
            var className = '.badge-' + name;
            if (count > 0) {
                $(className).text(count);
                $(className).removeClass('hidden');
            }
        })
    }
});
//End of: Get the number of worklist items
$(".scroll-top .btn").on('click', function (event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();
        // Store hash
        var hash = this.hash;
        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 800, function (eventTwo) {
            // Add hash (#) to URL when done scrolling (default click behavior)
            //eventTwo.preventDefault();
            window.location.hash = hash;
        });
    }  // End if
});
// Add smooth scrolling on all links inside the navbar
$("#myAffix a").on('click', function (event) {
    var offset = 122;
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;
        window.location.hash = hash;
        var top = $(hash).offset().top - offset;
        window.scrollTo({
            top: top,
            left: 0,
            behavior: "smooth"
        });
    }
    // var offset = 125;
    // // Make sure this.hash has a value before overriding default behavior
    // if (this.hash !== "") {
    //     // Prevent default anchor click behavior
    //     event.preventDefault();
    //     // Store hash
    //     var hash = this.hash;
    //     // Using jQuery's animate() method to add smooth page scroll
    //     // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
    //     $('html, body').animate({
    //         scrollTop: $(hash).offset().top - offset
    //     }, 800, function () {
    //         // Add hash (#) to URL when done scrolling (default click behavior)
    //         window.location.hash = hash;
    //     });
    // }  // End if
});
$(function ($) {
    $(".clickable-row").click(function () {
        window.location.href = ($(this).data("url"));
    });
});
$(document).on('click', '#calendar-table tr', function (e) {
    var table = $('#calendar-table').DataTable();
    var row_object = table.row(this).data();
    var row_object_id = row_object[0];
    var url = Routing.generate('tasks_edit', {id: row_object_id});
    window.location.href = url;
    // console.log(row_object);
});
$("#barChartDashboard").mouseleave(function () {
    $("#barChartDashboard").css("cursor", "default");
});
$("#barChartDashboard").mouseenter(function () {
    $("#barChartDashboard").css("cursor", "pointer");
});
$(document).ready(function () {
    $('#back-to-top').click(function () {
        // $('#back-to-top').tooltip('hide');
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
});
$(window).load(function () {
    // $('#modal-taak-info').modal('show');
    // $('#modal-loading').modal('show');
});
$(document).ready(function () {

    $('#delete-form-submit').click(function (e) {
        e.preventDefault();
        $('#delete-form').submit();
    });
});
jQuery.fn.exists = function () {
    return this.length > 0;
};
$(window).resize(function () {
    $('.affix').width($('.side-body').width() - 30);
    $('.affix-custom').width($('.side-body').width() - 30);
});
$(document).ready(function () {
    $('.affix').width($('.side-body').width() - 30);
    $('.affix-custom').width($('.side-body').width() - 30);
});
$(document).scroll(function () {
    $('.affix').width($('.side-body').width() - 30);
    $('.affix-custom').width($('.side-body').width() - 30);
});
$(document).ready(function () {
    $('#send-mail-report').click(function () {
        var url = Routing.generate('mail_report');
        $('#mailreport-progressbar').removeAttr("style");
        $('#mailreport-progressbar').css('width', '50%').attr('aria-valuenow', 50);
        $('#finished').removeAttr("style");
        $('#finished').hide();
        $('#wait').removeAttr("style");
        $('#wait').show();
        $('#modal-response').show();
        $.ajax({
            url: url,
            type: 'POST',
            cache: false,
            success: function (e) {


                $('#mailreport-progressbar').removeAttr("style");
                $('#mailreport-progressbar').css('width', '100%').attr('aria-valuenow', 100);
                $('#finished').html(e.message);
                $('#finished').removeAttr("style");
                $('#finished').show();
                $('#wait').removeAttr("style");
                $('#wait').hide();

            },
            error: function (e) {

                // Fail message
                var test = e;
            }
        })
    });
});
$(document).ready(function () {
    $('.report-select-all').click(function () {
        var id = $(this).data('parent');
        var checked = $(this).data('selectAll');
        if (false === checked) {
            $('#' + id + ' input:checkbox').prop("checked", true);
            $(this).data('selectAll', true);
        } else {
            $('#' + id + ' input:checkbox').prop("checked", false);
            $(this).data('selectAll', false);
        }
    });
});
$(document).ready(function () {
    //object-select-report
    $(".select-all").change(function () {  //"select all" change
        var id = $(this).data('parent');
        $('#' + id + ' input:checkbox').prop('checked', $(this).prop("checked")); //change all ".checkbox" checked status
    });
//".checkbox" change
    $(':checkbox').change(function () {
        //uncheck "select all", if one of the listed checkbox item is unchecked
        if (false == $(this).prop("checked")) { //if this item is unchecked
            var id = $(this).data('parent');
            $('#form_' + id + '_0').prop('checked', false); //change "select all" checked status to false
        }
        //check "select all" if all checkbox items are checked
        if ($('.checkbox:checked').length == $('.checkbox').length) {
            var id = $(this).data('parent');
            $('#form_' + id + '_0').prop('checked', true);
        }
    });
});
$(function () {
    $('#object_buildingYear').datepicker({
        orientation: "top left",
        clearBtn: true,
        autoclose: true,
        format: " yyyy", // Notice the Extra space at the beginning
        viewMode: "years",
        minViewMode: "years",
        maxViewMode: 3
    });
});
$(function () {
    $('.datepicker').datepicker({
        orientation: "top left",
        format: "dd-mm-yyyy",
        todayBtn: "linked",
        calendarWeeks: true,
        autoclose: true
    });
    var date = new Date();
    var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    $('.datepicker-min').datepicker({
        orientation: "top left",
        format: "dd-mm-yyyy",
        todayBtn: "linked",
        calendarWeeks: true,
        autoclose: true,
        startDate: today,
    });
    $('.datepicker-max').datepicker({
        orientation: "auto",
        format: "dd-mm-yyyy",
        todayBtn: "linked",
        calendarWeeks: true,
        autoclose: true,
        endDate: today,
    });
});
$(function () {
    $('.datetimepicker').datetimepicker({
        showClose: true,
        format: 'DD-MM-YYYY HH:mm',
        showTodayButton: true,
    });
});
$(".btn").mouseup(function () {
    $(this).blur();
})
var taskTable;
var allTasksTable;
$(document).ready(function () {
    $('.clockpicker').clockpicker({
        placement: 'top',
        align: 'left',
        donetext: 'Done',
        autoclose: true,
    });
    //if mail report occurance is weeklijk show the day
    var $occuranceMail = $('#mail_report_frequency');

    function checkOccuranceMail(val) {
        if (2 == val) {
            $('.mail-occuranceForm-week').css("display", "initial");
            $('.mail-occuranceForm-month').css("display", "none");
        } else if (3 == val) {
            $('.mail-occuranceForm-week').css("display", "initial");
            $('.mail-occuranceForm-month').css("display", "initial");
        } else {
            $('.mail-occuranceForm-week').css("display", "none");
            $('.mail-occuranceForm-month').css("display", "none");
        }
    }

    $occuranceMail.change(function () {
        //if task occurance is recurrant show the frequencyNumber and frequencyPeriod
        checkOccuranceMail($occuranceMail.val())
    });
    checkOccuranceMail($occuranceMail.val())
    //if task occurance is recurrant show the frequencyNumber and frequencyPeriod
    var $occurance = $('#task_occurance');

    function checkOccurance(val) {
        if (2 == val) {
            $('#task_occuranceForm').css("display", "initial");
        } else {
            $('#task_occuranceForm').css("display", "none");
        }
    }

    $occurance.change(function () {
        //if task occurance is recurrant show the frequencyNumber and frequencyPeriod
        checkOccurance($occurance.val())
    });
    checkOccurance($occurance.val());
    // $('.datatable-custom').DataTable({
    //     "language": {
    //         "url": "https://cdn.datatables.net/plug-ins/1.10.15/i18n/Dutch.json"
    //     },
    //     "targets": 'sort',
    //     "orderable": false,
    //     initComplete: function () {
    //         this.api().columns().every(function () {
    //             var column = this;
    //             var select = $('<select><option value=""></option></select>')
    //                 .appendTo($(column.footer()).empty())
    //                 .on('change', function () {
    //                     var val = $.fn.dataTable.util.escapeRegex(
    //                         $(this).val()
    //                     );
    //                     column
    //                         .search(val ? '^' + val + '$' : '', true, false)
    //                         .draw();
    //                 });
    //             column.data().unique().sort().each(function (d, j) {
    //                 if (column.search() === '^' + d + '$') {
    //                     select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
    //                 } else {
    //                     select.append('<option value="' + d + '">' + d + '</option>')
    //                 }
    //             });
    //         });
    //     }
    // });
    // var planningTable = $('#planningTable').DataTable({
    //     "language": {
    //         "url": "//cdn.datatables.net/plug-ins/1.10.15/i18n/Dutch.json"
    //     },
    //     "targets": 'sort',
    //     "stateSave": true,
    //     colReorder: true,
    //     //responsive: true,
    //     "orderable": true,
    //     dom: 'Bfrtip',
    //     buttons: [
    //         {
    //             extend: 'copy',
    //             text: '<i class="fa fa-files-o" aria-hidden="true"></i>',
    //             titleAttr: 'Kopieer naar klembord'
    //         }, {
    //             extend: 'excel',
    //             text: '<i class="fa fa-file-excel-o" aria-hidden="true"></i>',
    //             titleAttr: 'Exporteer naar excel',
    //             customize: customizeExcelOptions
    //         }
    //         // , {
    //         //     extend: 'colvis',
    //         //     text: '<i class="fa fa-columns" aria-hidden="true"></i>',
    //         //     titleAttr: 'Zichtbaarheid kolommen',
    //         //     postfixButtons: ['colvisRestore']
    //         // }
    //     ],
    //     initComplete: function () {
    //         this.api().columns().every(function () {
    //             var column = this;
    //             var select = $('<select><option value=""></option></select>')
    //                 .appendTo($(column.footer()).empty())
    //                 .on('change', function () {
    //                     var val = $.fn.dataTable.util.escapeRegex(
    //                         $(this).val()
    //                     );
    //                     column
    //                         .search(val ? '^' + val + '$' : '', true, false)
    //                         .draw();
    //                 });
    //             column.data().unique().sort().each(function (d, j) {
    //                 if (column.search() === '^' + d + '$') {
    //                     select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
    //                 } else {
    //                     select.append('<option value="' + d + '">' + d + '</option>')
    //                 }
    //             });
    //         });
    //     }
    // }).on('stateSaveParams.dt', function (e, settings, data) {
    //     data.search.search = "";
    //     $.each(data.columns, function (i, object) {
    //         object.search.search = "";
    //     });
    // });
    //
    //
    //
    //alltaskstable
    // if ($('#allTasksTable').length) {
    //     allTasksTable = $('#allTasksTable').DataTable({
    //         "processing": true,
    //         "serverSide": true,
    //         "ajax": "../takenlijst/api"
    //     })
    // }
    //end of alltaskstable

    // $('#tasksTable').DataTable();
    // #tasksTable (logboek)
    // if ($('#tasksTable').length) {
    //     taskTable = $('#tasksTable').DataTable({
    //         "language": {
    //             "url": "//cdn.datatables.net/plug-ins/1.10.15/i18n/Dutch.json"
    //         },
    //         "targets": 'sort',
    //         "search": {
    //             "regex": true
    //         },
    //         // "stateSave": true,
    //         autoWidth: false,
    //         colReorder: true,
    //         //bStateSave: true,
    //         "bJQueryUI": true,
    //         // responsive: {
    //         //     details: {
    //         //         display: $.fn.dataTable.Responsive.display.modal({
    //         //             header: function (row) {
    //         //                 var data = row.data();
    //         //                 return 'Details voor ' + data[2] + ' ' + data[3];
    //         //             }
    //         //         }),
    //         //         renderer: function (api, rowIdx, columns) {
    //         //             var data = $.map(columns, function (col, i) {
    //         //                 //prevent first 2 columns: 0 and 1 to show up in the modal
    //         //                 if (i > 1) {
    //         //                     return '<tr>' +
    //         //                         '<td>' + col.title + ':' + '</td> ' +
    //         //                         '<td>' + col.data + '</td>' +
    //         //                         '</tr>';
    //         //                 }
    //         //             }).join('');
    //         //             return $('<table/>').append(data);
    //         //         }
    //         //     }
    //         // },
    //         "orderable": true,
    //         "order": [[2, "asc"]],
    //         "columns": [
    //             {"orderable": false, "width": "4%"},
    //             {"orderable": false, "width": "4%"},
    //             {"width": "10%"},
    //             {"width": "10%"},
    //             {"width": "10%"},
    //             null,
    //             null,
    //             {"visible": false},
    //             /*
    //             {
    //                 "render": function (data, type, full, meta) {
    //                     return moment(data).format("D-M-Y", "D-M-YYYY", "nl");
    //                 }
    //             },
    //             */
    //             //{"render": $.fn.dataTable.render.moment("D-M-Y", "D-M-YYYY", "nl")},
    //             //{ type: 'date-dd-mmm-yyyy'},
    //             {"render": $.fn.dataTable.render.moment("D-M-Y", "D-M-YYYY", "nl")},
    //             //null,
    //             null,
    //             null,
    //             null,
    //             null,
    //             null,
    //         ],
    //         dom: 'Blfrtip',
    //         buttons: [
    //             {
    //                 extend: 'copy',
    //                 text: '<i class="fa fa-files-o" aria-hidden="true"></i>',
    //                 titleAttr: 'Kopieer naar klembord'
    //             }, {
    //                 extend: 'excel',
    //                 text: '<i class="fa fa-file-excel-o" aria-hidden="true"></i>',
    //                 titleAttr: 'Exporteer naar excel',
    //                 customize: customizeExcelOptions
    //             }
    //             // , {
    //             //     extend: 'colvis',
    //             //     text: '<i class="fa fa-columns" aria-hidden="true"></i>',
    //             //     titleAttr: 'Zichtbaarheid kolommen',
    //             //     postfixButtons: ['colvisRestore']
    //             // }
    //         ],
    //         initComplete: function () {
    //             this.api().columns().every(function (index) {
    //             });
    //         }
    //     }).on('stateSaveParams.dt', function (e, settings, data) {
    //         data.search.search = "";
    //         $.each(data.columns, function (i, object) {
    //             object.search.search = "";
    //         });
    //     });
    //     taskTable.on('column-sizing.dt', function (e, settings) {
    //         console.log('Column width recalculated in table');
    //     });
    //     var datepickerDefaults = {
    //         showTodayButton: true,
    //         showClear: true,
    //     };
    //     if (taskTable.data().any()) {
    //         yadcf.init(taskTable, [
    //                 {
    //                     column_number: 2,
    //                     filter_type: "multi_select",
    //                     select_type: "chosen",
    //                     //filter_container_id: 'external_filter_container_object'
    //                     filter_default_label: "Selecteer...",
    //                     select_type_options: {width: '200px'}
    //                 },
    //                 {
    //                     column_number: 3,
    //                     filter_type: "multi_select",
    //                     select_type: "chosen",
    //                     filter_default_label: "Selecteer...",
    //                     select_type_options: {width: '200px'}
    //                 },
    //                 {
    //                     column_number: 4,
    //                     filter_type: "multi_select",
    //                     select_type: "chosen",
    //                     filter_default_label: "Selecteer...",
    //                     select_type_options: {width: '200px'}
    //                 },
    //                 {
    //                     column_number: 5,
    //                     filter_type: "multi_select",
    //                     select_type: "chosen",
    //                     filter_default_label: "Selecteer...",
    //                     select_type_options: {width: '200px'}
    //                 },
    //                 {
    //                     column_number: 8,
    //                     filter_container_id: 'external_filter_container_date',
    //                     filter_type: 'range_date',
    //                     datepicker_type: 'bootstrap-datetimepicker',
    //                     date_format: 'DD-MM-YYYY',
    //                     filter_plugin_options: datepickerDefaults,
    //                     filter_default_label: "  ",
    //                 },
    //                 {
    //                     column_number: 9,
    //                     filter_type: "multi_select",
    //                     select_type: "chosen",
    //                     filter_default_label: "Selecteer...",
    //                     // filter_container_id: 'external_filter_container_responsible',
    //                     // select_type_options: {
    //                     //     width: '100%'
    //                     // },
    //                 },
    //                 {
    //                     column_number: 10,
    //                     filter_type: "multi_select",
    //                     select_type: "chosen",
    //                     filter_default_label: "Selecteer...",
    //                     // filter_container_id: 'external_filter_container_responsible_contact',
    //                     // select_type_options: {
    //                     //     width: '100%'
    //                     // },
    //                 },
    //                 {
    //                     column_number: 12,
    //                     filter_type: "multi_select",
    //                     select_type: "chosen",
    //                     filter_default_label: "Selecteer...",
    //                     // filter_container_id: 'external_filter_container_contractor',
    //                     // select_type_options: {
    //                     //     width: '100%'
    //                     // },
    //                 },
    //                 {
    //                     column_number: 13,
    //                     filter_type: "multi_select",
    //                     select_type: "chosen",
    //                     filter_default_label: "Selecteer...",
    //                     // filter_container_id: 'external_filter_container_responsible_executer',
    //                     // select_type_options: {
    //                     //     width: '100%'
    //                     // },
    //                 },
    //             ]
    //             //{cumulative_filtering: true}
    //         );
    //     }
    // }

    (function ($, window) {
        // Set the default datepicker format
        $.fn.datepicker.dates['nl'] = {
            days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            daysMin: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
            months: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
            monthsShort: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            today: "Vandaag",
            clear: "Wis",
            format: "dd-mm-yyyy",
            titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */

            weekStart: 1
        };
        $.fn.datepicker.defaults.format = "dd-mm-yyyy";
        $.fn.datepicker.defaults.todayBtn = 'linked';
        $.fn.datepicker.defaults.autoclose = true;
        $.fn.datepicker.defaults.clearBtn = true;
        $.fn.datepicker.defaults.language = 'nl';
        // Add support for datepickers globally to use input[type=date]
        var nativeDateFormat = /^\d{4}-\d{2}-\d{2}$/;
        var datepickerDateFormat = /^\d{2}\/\d{2}\/\d{2}$/;

        /*globals moment*/
        function bootstrapDatepickerMobile(ev) {
            var $inputs = $('input.date-picker');
            var isMobile = $(window).width() <= 480 || Modernizr.touch;
            $inputs.each(function () {
                var $input = $(this);
                var val = $input.val();
                var valMoment;
                if (nativeDateFormat.test(val)) {
                    valMoment = moment(val, 'dd-mm-yyyy');
                } else if (datepickerDateFormat.test(val)) {
                    valMoment = moment(val, 'dd-mm-yyyy');
                }
                var isMoment = moment.isMoment(valMoment);
                if (isMobile && Modernizr.inputtypes.date) {
                    if (isMoment) val = valMoment.format('dd-mm-yyyy');
                    $input.datepicker('remove');
                    $input.val(val);
                    $input.attr('type', 'date');
                } else {
                    if (isMoment) val = valMoment.format('dd-mm-yyyy');
                    $input.attr('type', 'text');
                    $input.val(val);
                    if (isMobile) {
                        $input.datepicker('remove');
                    } else {
                        if (isMoment)
                            $input.datepicker('update', valMoment.toDate());
                        else
                            $input.datepicker();
                        if ($input.is(':focus'))
                            $input.datepicker('show');
                    }
                }
            });
        }

        $(window).on('resize.bootstrapDatepickerMobile', bootstrapDatepickerMobile);
        bootstrapDatepickerMobile();
    }(jQuery, window));

    function customizeExcelOptions(xlsx) {
        var sheet = xlsx.xl.worksheets['sheet1.xml'];
        var col = $('col', sheet);
        col.each(function () {
            $(this).attr('width', 30);
        });
    }

// Full height of sidebody
    function fix_height() {
        var navbarHeight = $('#main-navbar').height();
        var docHeight = $(document).height();
        var marginTop = 65;
        var sideBodyHeight = docHeight - navbarHeight - marginTop;
        $('.side-body').css("min-height", sideBodyHeight + "px");
        $('.side-body').css("margin-top", marginTop + "px");
        $('.side-menu').css("top", navbarHeight + "px");
    }

    fix_height();
    $(window).resize(function () {
        fix_height();
    });
})
;




