$(document).on('click', '.panel-heading span.clickable', function (e) {
    var $this = $(this);
    if (!$this.hasClass('panel-collapsed')) {
        $this.parents('.panel').find('.panel-body').slideUp();
        $this.addClass('panel-collapsed');
        $this.find('i').removeClass('glyphicon-minus-sign').addClass('glyphicon-plus-sign');
    } else {
        $this.parents('.panel').find('.panel-body').slideDown();
        $this.removeClass('panel-collapsed');
        $this.find('i').removeClass('glyphicon-plus-sign').addClass('glyphicon-minus-sign');
    }
})
/*
 -----------------------------------------------
 -----------------------------------------------
 ElementAnswer in tasks
 -----------------------------------------------
 -----------------------------------------------
 */
var $elAnHolder;
// setup an "add a condition" link
var $addElAnLink = $(
    '<a class="btn btn-primary" href="#" class="add_condition_link">\n\
<i class="fa fa-plus"></i> Voeg voorwaarde toe</a>'
);
var $newElAnLinkLi = $('<li class="list-unstyled"></li>').prepend($addElAnLink);
jQuery(document).ready(function () {

    // Get the ul that holds the collection of tags
    $elAnHolder = $('ul.elanswers');
    // add a delete link to all of the existing image form li elements
    $elAnHolder.find('li').each(function () {
        addElAnFormDeleteLink($(this));
    });
    // add the "add a image" anchor and li to the tags ul
    $elAnHolder.prepend($newElAnLinkLi);
    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $elAnHolder.data('index', $elAnHolder.find(':input').length);
    $addElAnLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // add a new image form (see next code block)
        addElAnForm($elAnHolder, $newElAnLinkLi);
    });
});
function addElAnForm($elAnHolder, $newElAnLinkLi) {
    // Get the data-prototype explained earlier
    var prototype = $elAnHolder.data('prototype');
    // get the new index
    var index = $elAnHolder.data('index');
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newForm = prototype.replace(/__name__/g, index);
    // Display the form in the page in an li, before the "Add a image" link li
    var $newFormLi = $('<li class="list-unstyled"></li>').append(newForm);
    $newElAnLinkLi.after($newFormLi);
    // add a delete link to the new form
    addElAnFormDeleteLink($newFormLi);
    // Create dynamic fields for the element-answer combination (1. parent-el -> 2. child-el -> 3. answer)
    angular.element(document.getElementById('TaskController')).scope().initDynamicSelect(index, newForm);
    // increase the index with one for the next item
    $elAnHolder.data('index', index + 1);
}
function addElAnFormDeleteLink($imageFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    $imageFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the image form
        $imageFormLi.remove();
    });
}
/*
 -----------------------------------------------
 -----------------------------------------------
 Conditions in parent elements
 -----------------------------------------------
 -----------------------------------------------
 */
var $conditionHolder;
// setup an "add a condition" link
var $addConditionLink = $(
    '<a class="btn btn-primary" href="#" class="add_condition_link">\n\
<i class="fa fa-plus"></i> Add condition</a>'
);
var $newConditionLinkLi = $('<li class="list-unstyled"></li>').prepend($addConditionLink);
jQuery(document).ready(function () {

    // Get the ul that holds the collection of tags
    $conditionHolder = $('ul.conditions');
    // add a delete link to all of the existing image form li elements
    $conditionHolder.find('li').each(function () {
        addConditionFormDeleteLink($(this));
    });
    // add the "add a image" anchor and li to the tags ul
    $conditionHolder.prepend($newConditionLinkLi);
    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $conditionHolder.data('index', $conditionHolder.find(':input').length);
    $addConditionLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // add a new image form (see next code block)
        addConditionForm($conditionHolder, $newConditionLinkLi);
    });
});
function addConditionForm($conditionHolder, $newConditionLinkLi) {
    // Get the data-prototype explained earlier
    var prototype = $conditionHolder.data('prototype');
    // get the new index
    var index = $conditionHolder.data('index');
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newForm = prototype.replace(/__name__/g, index);
    // increase the index with one for the next item
    $conditionHolder.data('index', index + 1);
    // Display the form in the page in an li, before the "Add a image" link li
    var $newFormLi = $('<li class="list-unstyled"></li>').append(newForm);
    $newConditionLinkLi.after($newFormLi);
    // add a delete link to the new form
    addConditionFormDeleteLink($newFormLi);
}
function addConditionFormDeleteLink($imageFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    $imageFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the image form
        $imageFormLi.remove();
    });
}
/*
 -----------------------------------------------
 -----------------------------------------------
 Answer in parent elements
 -----------------------------------------------
 -----------------------------------------------
 */
var $answerHolder;
// setup an "add a answer" link
var $addAnswerLink = $(
    '<a class="btn btn-primary" href="#" class="add_answer_link">\n\
<i class="fa fa-plus"></i> Antwoord toevoegen</a>'
);
var $newAnswerLinkLi = $('<li class="list-unstyled"></li>').prepend($addAnswerLink);
jQuery(document).ready(function () {

    // Get the ul that holds the collection of answer
    $answerHolder = $('ul#parent-answers-container');
    // add a delete link to all of the existing answer form li elements
    $answerHolder.find('li.parent-answer').each(function () {
        addAnswerFormDeleteLink($(this));
    });
    // add the "add a answer" anchor and li to the answer ul
    $answerHolder.prepend($newAnswerLinkLi);
    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $answerHolder.data('index', $answerHolder.find(':input').length);
    $addAnswerLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // add a new answer form (see next code block)
        addAnswerForm($answerHolder, $newAnswerLinkLi);
    });
});
function addAnswerForm($answerHolder, $newAnswerLinkLi) {
    // Get the data-prototype explained earlier
    var prototype = $answerHolder.data('prototype');
    // get the new index
    var index = $answerHolder.data('index');
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newForm = prototype.replace(/__name__/g, index);
    // increase the index with one for the next item
    $answerHolder.data('index', index + 1);
    // Display the form in the page in an li, before the "Add a answer" link li
    var $newFormLi = $('<li class="list-unstyled"></li>').append(newForm);
    $newAnswerLinkLi.after($newFormLi);
    // add a delete link to the new form
    addAnswerFormDeleteLink($newFormLi);
}
function addAnswerFormDeleteLink($answerFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    $answerFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the answer form
        $answerFormLi.remove();
    });
}
/*
 -----------------------------------------------
 -----------------------------------------------
 Child elements
 -----------------------------------------------
 -----------------------------------------------
 */
var $childrenElementHolder;
// setup an "add a child" link
var $addChildLink = $(
    '<a class="btn btn-primary" href="#" class="add_child_link">\n\
<i class="fa fa-plus"></i> Element toevoegen</a>'
);
var $newChildLinkLi = $('<li class="child-element-add-link list-unstyled"></li>').prepend($addChildLink);
jQuery(document).ready(function () {

    // Get the ul that holds the collection of child
    $childrenElementHolder = $('ul.children');
    // add a delete link to all of the existing child form li elements
    $childrenElementHolder.find('li.child-element').each(function () {
        addChildFormDeleteLink($(this));
    });    // add a delete link to all of the existing child form li elements
    $childrenElementHolder.find('ul.child-answers').each(function () {
        addChildAnswerLinkLi($(this));
    });
    // add a delete link to all of the existing child-answers form li elements
    $childrenElementHolder.find('li.child-answer').each(function () {
        addChildAnswerFormDeleteLink($(this));
    });
    // add the "add a child" anchor and li to the child ul
    $childrenElementHolder.append($newChildLinkLi);
    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $childrenElementHolder.data('index', $childrenElementHolder.find('.child-element').length);
    $addChildLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // add a new child form (see next code block)
        addChildForm($childrenElementHolder, $newChildLinkLi);
    });
});
$('body').on('click', 'a.add-child-answer-link', function (e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();
    addChildAnswerForm($(this));
});
function addChildAnswerForm($addChildAnswerButton) {
    var indexButton = $addChildAnswerButton.data('index-child');
    // Get all the li from the childanswers (only from the active childelement
    var childAnswersHolder = $('ul#child-answers-container-' + indexButton);
    var addAnswerButton = childAnswersHolder.find('.child-element-answer-add-link');
    //var childElementHolder = $('ul#children-container');
    // Get the data-prototype explained earlier
    //var prototypeHolder = $('#prots');
    var prototypeElementAnswer = $('#element_children___name___answers').data('prototype-answer');
    //var prototype = $childrenHolder.data('prototype');
    // get the new index
    var indexElement = childAnswersHolder.data('index-child');
    var indexAnswer = childAnswersHolder.find(':input').length;
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newFormChildAnswer = prototypeElementAnswer.replace(/__child_element_answer__/g, indexAnswer).replace(/__name__/g, indexElement);
    // increase the index with one for the next item
    childAnswersHolder.data('index', indexAnswer + 1);
    // Display the form in the page in an li, before the "Add a child" link li
    var $newChildFormLi = $('<li class="child-element list-unstyled"></li>').append(newFormChildAnswer);
    addAnswerButton.before($newChildFormLi);
    // add a delete link to the new form
    addChildFormDeleteLink($newChildFormLi);
}
function addChildForm($childrenElementHolder, $newChildLinkLi) {
    // Get the data-prototype explained earlier
    var prototypeElement = $('#proto-element').data('prototype-element');
    var prototypeElementAnswer = $('#element_children___name___answers').data('prototype-answer');
    // get the new index
    var indexElement = $childrenElementHolder.data('index');
    var childAnswersHolder = $('ul#child-answers-container-' + indexElement);
    var indexAnswer = childAnswersHolder.find(':input').length;
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newFormChildAnswer = prototypeElementAnswer.replace(/__child_element_answer__/g, indexAnswer).replace(/__name__/g, indexElement).replace(/element_children_0_answers_0/g, 'element_children_' + indexElement + '_answers_' + indexAnswer);
    var liAnswer = $('<li class="child-answer list-unstyled">' +
        '</li>').append(newFormChildAnswer);
    var ulAnswer = $('<ul data-index-child="' + indexElement + '" id="child-answers-container-' + indexElement + '" class="collection child-answers">' +
        '</ul>').append(liAnswer);
    var htmlPanelHeading = $('<div class="panel-heading" role="tab" id="heading' + indexElement + '">' +
        '<h4 class="panel-title">' +
        '<a role="button" data-bs-toggle="collapse" data-parent="#accordion' + indexElement + '" href="#collapse' + indexElement + '"' +
        'aria-expanded="true" aria-controls="collapse' + indexElement + '">' +
        '<span class="clickable">' +
        '<i class="pull-left glyphicon glyphicon-minus-sign"></i>Answers' +
        '</span></a></h4></div>');
    var htmlPanelBody = $('<div id="collapse' + indexElement + '" class="panel-collapse" role="tabpanel"' +
        'aria-labelledby="heading' + indexElement + '">' +
        '<div class="panel-body">' +
        '</div></div>');
    htmlPanelBody.find('.panel-body').append(ulAnswer)
    var newFormChildAnswerUl =
        $('<div class="form-group">' +
            '<div class="panel-group" id="accordion' + indexElement + '" role="tablist" aria-multiselectable="true">' +
            '<div class="panel panel-default panel-parent">' +
            '</div></div></div>');
    newFormChildAnswerUl.find('.panel-parent').append(htmlPanelHeading).append(htmlPanelBody);
    var newFormChild = $('<div class="form-group"></div>').append(prototypeElement.replace(/__name__/g, indexElement));
    // increase the index with one for the next item
    $childrenElementHolder.data('index', indexElement + 1);
    // Display the form in the page in an li, before the "Add a child" link li
    var $newChildFormLi = $('<div class="well"></div>').append(newFormChild).append(newFormChildAnswerUl);
    var $newChildFormWell = $('<li data-index-child="' + indexElement + '" class="list-unstyled"></li>').append($newChildFormLi);
    $newChildLinkLi.before($newChildFormWell);
    addChildAnswerLinkLi(ulAnswer);
    addChildFormDeleteLink(liAnswer);
    // add a delete link to the new form
    addChildFormDeleteLink($newChildFormLi);
}
function addChildAnswerLinkLi($newFormLi) {
    var indexButton = $newFormLi.data('index-child');
    // setup an "add a child" link
    var $addChildLink = $(
        '<a data-index-child="' + indexButton + '" class="btn btn-primary add-child-answer-link" href="#">\n\
    <i class="fa fa-plus"></i> Antwoord toevoegen</a>'
    );
    var $newChildLinkLi = $('<li data-index-child="' + indexButton + '" class="child-element-answer-add-link list-unstyled"></li>').append($addChildLink);
    $newFormLi.append($newChildLinkLi);
    /*
     var $childElementAnswersHolder = $newFormLi.closest('ul.child-answers');

     // add the "add a child" anchor and li to the child ul
     $childElementAnswersHolder.append($newChildLinkLi);
     */
}
function addChildAnswerFormDeleteLink($newFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    //$newFormLi.append($removeFormA);
    $newFormLi.find(".form-group").append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
    });
}
function addChildFormDeleteLink($newFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    $newFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
    });
}
/*
 -----------------------------------------------
 -----------------------------------------------
 Users in organisation
 -----------------------------------------------
 -----------------------------------------------
 */
var $userHolder;
// setup an "add a user" link
var $addUserLink = $(
    '<a class="btn btn-primary" href="#" class="add_user_link">\n\
<i class="fa fa-plus"></i> Gebruiker toevoegen</a>'
);
var $newUserLinkLi = $('<li class="list-unstyled"></li>').prepend($addUserLink);
jQuery(document).ready(function () {

    // Get the ul that holds the collection of user
    $userHolder = $('ul#users-container');
    // add a delete link to all of the existing user form li elements
    $userHolder.find('li.parent-user').each(function () {
        addUserFormDeleteLink($(this));
    });
    // add the "add a user" anchor and li to the user ul
    $userHolder.prepend($newUserLinkLi);
    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $userHolder.data('index', $userHolder.find(':input').length);
    $addUserLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // add a new user form (see next code block)
        addUserForm($userHolder, $newUserLinkLi);
    });
});
function addUserForm($userHolder, $newUserLinkLi) {
    // Get the data-prototype explained earlier
    var prototype = $userHolder.data('prototype');
    // get the new index
    var index = $userHolder.data('index');
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newForm = prototype.replace(/__name__/g, index);
    // increase the index with one for the next item
    $userHolder.data('index', index + 1);
    // Display the form in the page in an li, before the "Add a user" link li
    var $newFormLi = $('<li class="list-unstyled well parent-user"></li>').append(newForm);
    $newUserLinkLi.after($newFormLi);
    // add a delete link to the new form
    addUserFormDeleteLink($newFormLi);
}
function addUserFormDeleteLink($userFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    $userFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the user form
        $userFormLi.remove();
    });
}
/*
 -----------------------------------------------
 -----------------------------------------------
 Organisations in users
 -----------------------------------------------
 -----------------------------------------------
 */
var $organisationHolder;
// setup an "add a organisation" link
var $addOrganisationLink = $(
    '<a class="btn btn-primary" href="#" class="add_organisation_link">\n\
<i class="fa fa-plus"></i> Organisatie toevoegen</a>'
);
//var $newOrganisationLinkLi = $('<li class="list-unstyled"></li>').prepend($addOrganisationLink);
var $newOrganisationLinkLi = $('<li class="list-unstyled"></li>');
jQuery(document).ready(function () {

    // Get the ul that holds the collection of organisation
    $organisationHolder = $('ul#organisation-container');
    // add a delete link to all of the existing organisation form li elements
    $organisationHolder.find('li.parent-organisation').each(function () {
        addorganisationFormDeleteLink($(this));
    });
    // add the "add a organisation" anchor and li to the organisation ul
    //$organisationHolder.prepend($newOrganisationLinkLi);
    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $organisationHolder.data('index', $organisationHolder.find(':input').length);
    addorganisationForm($organisationHolder, $newOrganisationLinkLi);
});
function addorganisationForm($organisationHolder, $newOrganisationLinkLi) {
    // Get the data-prototype explained earlier
    var prototype = $organisationHolder.data('prototype');
    // get the new index
    var index = $organisationHolder.data('index');
    if (prototype !== undefined) {
        // Replace '__name__' in the prototype's HTML to
        // instead be a number based on how many items we have
        var newForm = prototype.replace(/__name__/g, index);
        // increase the index with one for the next item
        $organisationHolder.data('index', index + 1);
        // Display the form in the page in an li, before the "Add a organisation" link li
        var $newFormLi = $('<li class="list-unstyled organisation"></li>').append(newForm);
        if ($organisationHolder.children('li').length === 0) {
            $organisationHolder.append($newFormLi);
        }
        // add a delete link to the new form
        //addorganisationFormDeleteLink($newFormLi);
    }
}
function addorganisationFormDeleteLink($organisationFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    $organisationFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the organisation form
        $organisationFormLi.remove();
    });
}




