
var $contractpartHolder;


// setup an "add a child" link
var $addContractpartLink = $(
    '<a class="btn btn-primary" href="#" class="add_contractpart_link">\n\
<i class="fa fa-plus"></i> Contractonderdeel toevoegen</a>'
);

var $newContractpartLinkLi = $('<li class="well contractpart-child list-unstyled contractpart-add-link"></li>').append($addContractpartLink);

jQuery(document).ready(function () {

    // Get the ul that holds the collection of child
    $contractpartHolder = $('ul.contractpart-parent');

    // add a delete link to all of the existing child form li elements
    $contractpartHolder.find('li.contractpart-child').each(function () {
        addChildFormDeleteLink($(this));
    });    // add a delete link to all of the existing child form li elements



    // add the "add a child" anchor and li to the child ul
    $contractpartHolder.append($newContractpartLinkLi);

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $contractpartHolder.data('index', $contractpartHolder.find('.contractpart-child').length);

    $addContractpartLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // add a new child form (see next code block)
        addContractpartForm($contractpartHolder, $newContractpartLinkLi);
    });


});

function addContractpartForm($contractpartHolder, $newContractpartLinkLi) {
    // Get the data-prototype explained earlier
    var prototypeContractpart = $('#appbundle_contract_contractparts').data('prototype-contractpart');

    // get the new index
    var indexContractpart = $contractpartHolder.data('index');

    var newFormContractpart = $('<div class="form-group"></div>').append(prototypeContractpart.replace(/__name__/g, indexContractpart));

    // increase the index with one for the next item
    $contractpartHolder.data('index', indexContractpart + 1);

    // Display the form in the page in an li, before the "Add a child" link li
    var $newContractpartFormLi = $('<div class="well"></div>').append(newFormContractpart);
    var $newContractpartFormWell = $('<li data-index-contractpart="' + indexContractpart + '" class="well contractpart-child list-unstyled"></li>').append($newContractpartFormLi);

    $newContractpartLinkLi.before($newContractpartFormWell);


    // add a delete link to the new form
    addChildFormDeleteLink($newContractpartFormLi);
}


function addChildFormDeleteLink($newFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    var $removeFormACol = $('<div class="col-md-1"></div>').append($removeFormA);



    var $newFormLiFind = $newFormLi.find('.form-row');

    // $newFormLi.append($removeFormACol);
    $newFormLiFind.append($removeFormACol);

    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // remove the li for the child form
        $newFormLi.remove();
    });
}

