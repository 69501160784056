///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//* LEGO (TOP-LEVEL-ELEMENT) *//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

var $collectionHolder;

// setup an "add a tag" link
var $addTagButton = $('<button type="button" class="add_tag_link">Voeg een dossier toe</button>');
var $newLinkLi = $('<li></li>').append($addTagButton);

jQuery(document).ready(function() {
    // Get the ul that holds the collection of tags
    $collectionHolder = $('ul.dossiers');

    $collectionHolder.find('li').each(function() {
        addTagFormDeleteLink($(this));
    });


    // add the "add a tag" anchor and li to the tags ul
    $collectionHolder.append($newLinkLi);

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $collectionHolder.data('index', $collectionHolder.find(':input').length);

    $addTagButton.on('click', function(e) {
        // add a new tag form (see next code block)
        addTagForm($collectionHolder, $newLinkLi);
    });
});

function addTagForm($collectionHolder, $newLinkLi) {
    // Get the data-prototype explained earlier
    var prototype = $collectionHolder.data('prototype');

    // get the new index
    var index = $collectionHolder.data('index');

    var newForm = prototype;
    // You need this only if you didn't set 'label' => false in your tags field in TaskType
    // Replace '__name__label__' in the prototype's HTML to
    // instead be a number based on how many items we have
    // newForm = newForm.replace(/__name__label__/g, index);

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    newForm = newForm.replace(/__name__/g, index);

    // increase the index with one for the next item
    $collectionHolder.data('index', index + 1);

    // Display the form in the page in an li, before the "Add a tag" link li
    var $newFormLi = $('<li></li>').append(newForm);
    $newLinkLi.before($newFormLi);

    addTagFormDeleteLink($newFormLi);

}

function addTagFormDeleteLink($tagFormLi) {
    var $removeFormButton = $('<button type="button">Verwijder dossier</button>');
    $tagFormLi.append($removeFormButton);

    $removeFormButton.on('click', function(e) {
        // remove the li for the tag form
        $tagFormLi.remove();
    });
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//* ADD LEGOSECTIONS IN LEGODOSSIER (SECOND-LEVEL-ELEMENT) *//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


var $sectionHolder;

// setup an "add a tag" link
var $addSectionButton = $('<button type="button" class="add_tag_link">Voeg een sectie toe</button>');
var $newSectionLinkLi = $('<li></li>').append($addSectionButton);

jQuery(document).ready(function() {
    // Get the ul that holds the collection of tags
    $sectionHolder = $('ul.sections');

    $sectionHolder.find('li').each(function() {
        addTagFormDeleteLink($(this));
    });


    // add the "add a tag" anchor and li to the tags ul
    $sectionHolder.append($newSectionLinkLi);

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $sectionHolder.data('index', $sectionHolder.find(':input').length);

    $addSectionButton.on('click', function(e) {
        // add a new tag form (see next code block)
        addSectionForm($sectionHolder, $newSectionLinkLi);
    });
});

function addSectionForm($sectionHolder, $newSectionLinkLi) {
    // Get the data-prototype explained earlier
    var prototype = $sectionHolder.data('prototype');

    // get the new index
    var index = $sectionHolder.data('index');

    var newForm = prototype;
    // You need this only if you didn't set 'label' => false in your tags field in TaskType
    // Replace '__name__label__' in the prototype's HTML to
    // instead be a number based on how many items we have
    // newForm = newForm.replace(/__name__label__/g, index);

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    newForm = newForm.replace(/__name__/g, index);

    // increase the index with one for the next item
    $sectionHolder.data('index', index + 1);

    // Display the form in the page in an li, before the "Add a tag" link li
    var $newFormLi = $('<li></li>').append(newForm);
    $newSectionLinkLi.before($newFormLi);

    addSectionFormDeleteLink($newFormLi);

}

function addSectionFormDeleteLink($sectionFormLi) {
    var $removeFormButton = $('<button type="button">Verwijder sectie</button>');
    $sectionFormLi.append($removeFormButton);

    $removeFormButton.on('click', function(e) {
        // remove the li for the tag form
        $sectionFormLi.remove();
    });
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//* ADD LEGOSUBJECTS IN LEGOSECTION (THIRD-LEVEL-ELEMENT) *//
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


var $subjectHolder;

// setup an "add a tag" link
var $addSubjectButton = $('<button type="button" class="add_tag_link">Voeg een onderdeel toe</button>');
var $newSubjectLinkLi = $('<li></li>').append($addSubjectButton);

jQuery(document).ready(function() {
    // Get the ul that holds the collection of tags
    $subjectHolder = $('ul.subjects');

    $subjectHolder.find('li').each(function() {
        addTagFormDeleteLink($(this));
    });


    // add the "add a tag" anchor and li to the tags ul
    $subjectHolder.append($newSubjectLinkLi);

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $subjectHolder.data('index', $subjectHolder.find(':input').length);

    $addSubjectButton.on('click', function(e) {
        // add a new tag form (see next code block)
        addSubjectForm($subjectHolder, $newSubjectLinkLi);
    });
});

function addSubjectForm($subjectHolder, $newSubjectLinkLi) {
    // Get the data-prototype explained earlier
    var prototype = $subjectHolder.data('prototype');

    // get the new index
    var index = $subjectHolder.data('index');

    var newForm = prototype;
    // You need this only if you didn't set 'label' => false in your tags field in TaskType
    // Replace '__name__label__' in the prototype's HTML to
    // instead be a number based on how many items we have
    // newForm = newForm.replace(/__name__label__/g, index);

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    newForm = newForm.replace(/__name__/g, index);

    // increase the index with one for the next item
    $subjectHolder.data('index', index + 1);

    // Display the form in the page in an li, before the "Add a tag" link li
    var $newFormLi = $('<li></li>').append(newForm);
    $newSubjectLinkLi.before($newFormLi);

    addSubjectFormDeleteLink($newFormLi);

}

function addSubjectFormDeleteLink($subjectFormLi) {
    var $removeFormButton = $('<button type="button">Verwijder onderdeel</button>');
    $subjectFormLi.append($removeFormButton);

    $removeFormButton.on('click', function(e) {
        // remove the li for the tag form
        $subjectFormLi.remove();
    });
}
