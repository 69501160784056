// var app = angular.module('myApp', ["ngResource", "frapontillo.bootstrap-duallistbox"])
var app = angular.module('myApp', ["ngResource"])
        .config(function ($httpProvider) {
            $httpProvider.interceptors.push('myHttpInterceptor');
            var spinnerFunction = function (data, headersGetter) {
                $('#modal-loading').modal();
                return data;
            };
            $httpProvider.defaults.transformRequest.push(spinnerFunction);
        })
// register the interceptor as a service, intercepts ALL angular ajax http calls
        .factory('myHttpInterceptor', function ($q, $window) {
            return function (promise) {
                return promise.then(function (response) {
                    $('#modal-loading').modal('hide');
                    return response;
                }, function (response) {
                    $('#modal-loading').modal('hide');
                    return $q.reject(response);
                });
            };
        });

app.config(function ($interpolateProvider) {
    $interpolateProvider.startSymbol('{[{').endSymbol('}]}');
});

app.controller('objectListController', function ($scope, $http, $location) {

    $scope.deleteObject = function (objectId) {

        var url = Routing.generate('object_delete_json', {id: objectId}, true);

        $http({
            url: url,
            method: 'DELETE'
        }).then(function (response) {
            $('#modal-loading').modal('hide');
            $('#modal-success').modal();
            $('#Object' + objectId).remove();
        }, function (response) {
            $('#modal-loading').modal('hide');
            $('#modal-error').modal();
            $('#Object' + objectId).remove();
        });
    };

});

app.controller('objectNewController', function ($scope, $http, $location) {

    queryParams = $window.location.href.match(/\?name=(.*)/)
    $scope.name = queryParams == null? "...": queryParams[1];
    $scope.location = $window.location.href;

});


