$.fn.exists = function () {
    return this.length !== 0;
}

jQuery(document).ready(function () {

    var roleSelector;

    // Get the ul that holds the collection of child
    var $userRolesHolder = $('#form_roles');
    var $userRolesHolderTwo = $('#user_roles');
    var $organisationSelector = $('#form_organisationusers_0_organisation_id');
    var $contractorSelector = $('#form_organisationusers_0_contractor_id');
    var $userOrganisationSelector = $('#user_organisationusers_0_organisation_id');
    var $userContractorSelector = $('#user_organisationusers_0_contractor_id');

    function checkOccuranceUserRole(form) {
        var val = form.val();
        if (undefined !== val) {
            var id = $(form)[0].id;
            if ("" !== val) {
            } else {
                $(form).css("display", "none");
                $('label[for="' + id + '"]').css("display", "none");
            }
        }
    }

    function checkOccuranceUserRoleChanged(roleSelector) {
        //alert('user role changed!');
        // alert('user role changed to: ' + roleSelector.val());
        if ('ROLE_CONTRACTOR' == roleSelector.val()){

            $($organisationSelector).prop('selectedIndex',0);
            $($userOrganisationSelector).prop('selectedIndex',0);

            $($organisationSelector).css("display", "none");
            var labelIdOne = $($organisationSelector).exists() ? $($organisationSelector)[0].id : "";
            $('label[for="' + labelIdOne + '"]').css("display", "none");
            $($userOrganisationSelector).css("display", "none");
            var labelIdTwo = $($userOrganisationSelector).exists() ? $($userOrganisationSelector)[0].id : "";
            $('label[for="' + labelIdTwo + '"]').css("display", "none");

            $($contractorSelector).css("display", "block");
            var labelIdThree = $($contractorSelector).exists() ? $($contractorSelector)[0].id : "";
            $('label[for="' + labelIdThree + '"]').css("display", "block");

            $($userContractorSelector).css("display", "block");
            var labelIdFour = $($userContractorSelector).exists() ? $($userContractorSelector)[0].id : "";
            $('label[for="' + labelIdFour + '"]').css("display", "block");
        }else {
            $($userContractorSelector).prop('selectedIndex',0);
            $($userContractorSelector).prop('selectedIndex',0);



            $($organisationSelector).css("display", "block");
            var labelIdOne = $($organisationSelector).exists() ? $($organisationSelector)[0].id : "";
            $('label[for="' + labelIdOne + '"]').css("display", "block");
            $($userOrganisationSelector).css("display", "block");
            var labelIdTwo = $($userOrganisationSelector).exists() ? $($userOrganisationSelector)[0].id : "";
            $('label[for="' + labelIdTwo + '"]').css("display", "block");

            $($contractorSelector).css("display", "none");
            var labelIdThree = $($contractorSelector).exists() ? $($contractorSelector)[0].id : "";
            $('label[for="' + labelIdThree + '"]').css("display", "none");

            $($userContractorSelector).css("display", "none");
            var labelIdFour = $($userContractorSelector).exists() ? $($userContractorSelector)[0].id : "";
            $('label[for="' + labelIdFour + '"]').css("display", "none");

        }


    }

    checkOccuranceUserRole($organisationSelector)
    checkOccuranceUserRole($contractorSelector)
    checkOccuranceUserRole($userOrganisationSelector)
    checkOccuranceUserRole($userContractorSelector)
    $($userRolesHolder).change(function () {  //"select all" change
        checkOccuranceUserRoleChanged($(this));
    });
    $($userRolesHolderTwo).change(function () {  //"select all" change
        checkOccuranceUserRoleChanged($(this));
    });
});
