/* ----------------------------------------------- -----------------------------------------------
Child elements ----------------------------------------------- ----------------------------------------------- */
var $groupParent;
// setup an "add a child" link
var $addGroupChildLink = $('<a class="btn btn-primary" href="#" class="add_group_child_link"><i class="fa fa-plus"> </i> Voorwaardengroep toevoegen </a>');
var $newGroupChildLinkLi = $('<li class="group-child-add-link list-unstyled"></li>').prepend($addGroupChildLink);
jQuery(document).ready(function () {
    // Get the ul that holds the collection of child
    $groupParent = $('ul.group-parent');
    // add a delete link to all of the existing child form li elements
    $groupParent.find('li.group-child').each(function () {
        addGroupChildFormDeleteLink($(this));
    });
    // add a 'add condition' link to all of the existing child form li elements
    $groupParent.find('ul.condition-parent').each(function () {
        addConditionChildLinkLi($(this));
    });
    // add a delete link to all of the existing child-answers form li elements
    $groupParent.find('li.condition-child').each(function () {
        addConditionChildFormDeleteLink($(this));
        var test_str = $(this).find('[id^="task_elementanswertaskgroups_"][id *=_elementsanswerstasks_]').attr('id');
        //"task_elementanswertaskgroups_0_elementsanswerstasks_0"
        //$(this).find('[id ^=object_element_task_activities_][id $=_id]')
        //task_elementanswertaskgroups_0_elementsanswerstasks_0
        var start_pos = "task_elementanswertaskgroups_";
        var end_pos = '_elementsanswerstasks_';
        var indexGroupChildString = test_str.substring(start_pos.length, test_str.indexOf(end_pos));
        var start_pos_two = start_pos.length + end_pos.length + indexGroupChildString.length;
        var indexGroupChild = parseInt(indexGroupChildString);
        var indexConditionString = test_str.substring(start_pos_two, test_str.length);
        var indexCondition = parseInt(indexConditionString);
        angular.element(document.getElementById('TaskController')).scope().initDynamicSelect(indexGroupChild, indexCondition);
    });
    // add the "add a child" anchor and li to the child ul
    $groupParent.append($newGroupChildLinkLi);
    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $groupParent.data('index', $groupParent.find('li.group-child').length);
    if ($groupParent.data('index') > 0) {
        //task_elementanswertaskgroups
        var test_str = $groupParent.find('[id^="task_elementanswertaskgroups"]:last').attr('id');
        var start_pos = "task_elementanswertaskgroups_";
        var end_pos = '_elementsanswerstasks_0_answer';
        var indexGroupParent = parseInt(test_str.substring(start_pos.length, end_pos.length));
        //var indexGroupParent = $groupParent.find('li.group-child:last').data('index-group-child');
        $groupParent.data('index', indexGroupParent + 1);
    }
    $addGroupChildLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();        // add a new child form (see next code block)
        addGroupChildForm($groupParent, $newGroupChildLinkLi);
    });

});

function addGroupChildForm($groupParent, $newGroupChildLinkLi) {
    // Get the data-prototype explained earlier
    var prototypeGroupChild = $('#prototype-condition-group').data('prototype-condition-group');
    // get the new index
    var indexGroupChild = $groupParent.data('index');
    var newFormConditionChild = $('<div class="form-group"></div>').append(prototypeGroupChild.replace(/__groups__/g, indexGroupChild));
    var conditionParent = newFormConditionChild.find('ul#condition-parent-' + indexGroupChild);
    var indexConditionChild = conditionParent.find('.condition-child').length;
    var prototypeConditionChild = $('#prototype-condition-group').data('prototype-condition-child');
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    //var newFormConditionChild = prototypeConditionChild.replace(/__conditions__/g, indexConditionChild).replace(/__groups__/g, indexGroupChild).replace(/group_child_0_condition_child_0/g, 'groups_' + indexGroupChild + '_conditions_' + indexConditionChild);
    //var liConditionChild = $('<li class="condition-child list-unstyled">' + '</li>').append(newFormConditionChild);
    //var ulConditionParent = $('<ul data-index-condition-parent="' + indexGroupChild + '" id="condition-parent-' + indexGroupChild + '" class="collection condition-parent">' + '</ul>').append(liConditionChild);
    var ulConditionParent = $('<ul data-index-condition-parent="' + indexGroupChild + '" id="condition-parent-' + indexGroupChild + '" class="collection condition-parent">' + '</ul>');
    var htmlPanelHeading = $('<div class="panel-heading" role="tab" id="heading' + indexGroupChild + '">' + '<h4 class="panel-title">' + '<a role="button" data-bs-toggle="collapse" href="#collapse' + indexGroupChild + '"' + 'aria-expanded="true" aria-controls="collapse' + indexGroupChild + '">' + '<span class="clickable">' + '<i class="pull-left glyphicon glyphicon-minus-sign"></i>Vraagantwoord combinatie' + '</span></a></h4></div>');
    var htmlPanelBody = $('<div id="collapse' + indexGroupChild + '" class="panel-collapse" role="tabpanel"' + 'aria-labelledby="heading' + indexGroupChild + '">' + '<div class="panel-body">' + '</div></div>');
    htmlPanelBody.find('.panel-body').append(ulConditionParent)
    var newFormConditionChildUl = $('<div class="form-group">' + '<div class="panel-group" id="accordion' + indexGroupChild + '" role="tablist" aria-multiselectable="true">' + '<div class="panel panel-default panel-parent">' + '</div></div></div>');
    newFormConditionChildUl.find('.panel-parent').append(htmlPanelHeading).append(htmlPanelBody);
    var newFormConditionChild = $('<div class="form-group"></div>').append(prototypeGroupChild.replace(/__groups__/g, indexGroupChild));
    // Display the form in the page in an li, before the "Add a child" link li
    var $newGroupChildFormLi = $('<div class="well"></div>').append(newFormConditionChild).append(newFormConditionChildUl);
    var $newGroupChildFormWell = $('<li data-index-group-child="' + indexGroupChild + '" class="list-unstyled"></li>').append($newGroupChildFormLi);

    $newGroupChildLinkLi.before($newGroupChildFormWell);
    //angular.element(document.getElementById('AttributeController')).append($newGroupChildFormWell);
    addConditionChildLinkLi(ulConditionParent);
    //addGroupChildFormDeleteLink(liConditionChild);
    // add a delete link to the new form
    addGroupChildFormDeleteLink($newGroupChildFormLi);
    // Create dynamic fields for the element-answer combination (1. parent-el -> 2. child-el -> 3. answer)
    angular.element(document.getElementById('TaskController')).scope().initDynamicSelectNew(indexGroupChild, indexConditionChild, newFormConditionChild);

    // increase the index with one for the next item
    $groupParent.data('index', indexGroupChild + 1);
}

function addConditionChildLinkLi($newFormLi) {
    var indexButton = $newFormLi.data('index-condition-parent');
    // setup an "add a child" link
    var $addGroupChildLink = $('<a data-index-group-child="' + indexButton + '" class="btn btn-primary add-condition-child-link" href="#"><i class="fa fa-plus"></i> Voorwaarde toevoegen</a>');
    var $newGroupChildLinkLi = $('<li data-index-group-child="' + indexButton + '" class="group-child-condition-child-add-link list-unstyled"></li>').append($addGroupChildLink);
    $newFormLi.append($newGroupChildLinkLi);
}

function addConditionChildFormDeleteLink($newFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    //$newFormLi.append($removeFormA);
    $newFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
    });
}

function addGroupChildFormDeleteLink($newFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    $newFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
    });
}

$(document).on('change', '#group-parent', function (e) {
    //[id ^=task_elementanswertaskgroups_][id $=_allObjects]
    // prevent the link from creating a "#" on the URL
    e.preventDefault();
    toggleAllObjects(e);
});
$(document).on('click', '#group-parent', function (e) {
    //[id ^=task_elementanswertaskgroups_][id $=_allObjects]
    // prevent the link from creating a "#" on the URL
    //e.preventDefault();
    toggleAllObjects(e);
});
$(document).on('click', 'a.add-condition-child-link', function (e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();
    addConditionChildForm($(this));
});

function checkVisibilityObjectSelecter() {

    $('.task-condition-allObjects').each(function () {
        var parentIndex = $(this).data('index');
        var allObjectHolder = $('#task_elementanswertaskgroups_'+parentIndex+'_allObjects');
        var otherObjectHolder = '#task-condition-objects-' + parentIndex;
        var collapsePanel = '#collapse-objects-'+parentIndex;
        var buttonHolder = '#show-building-task-group-holder-'+parentIndex;

        var inputAllObjects = $( "input[id='task_elementanswertaskgroups_"+parentIndex+"_allObjects'");
        var valueAllObjects = $(inputAllObjects)[0].checked;

        //task_elementanswertaskgroups_0_objects
        var $inputHolderOtherObjects = $("#task_elementanswertaskgroups_"+parentIndex+"_objects");
        var valueOtherObjects = false;
        $inputHolderOtherObjects.find('input[type=checkbox]').each(function (){
            var checkBox = $(this);
            var checkBoxChecked = $(checkBox)[0].checked;
            if (true === checkBoxChecked){
                valueOtherObjects = true;
            }
        });

        //var valueOtherObjects = $(inputAllObjects)[0].checked;
        //task_elementanswertaskgroups_0_elementsanswerstasks_0

        if (true === valueAllObjects){
            $(otherObjectHolder).find('input[type=checkbox]:checked').attr('checked', false);
            $(otherObjectHolder).hide();
            $(collapsePanel).collapse();
            $(buttonHolder).hide();

        } if (true === valueOtherObjects){
            $(collapsePanel).collapse();
            $(buttonHolder).hide();

        }
    });



}

function toggleAllObjects(e) {
    var target = e.target;
    //task_elementanswertaskgroups_0_allObjects
    var id = target.id;
    if (id.endsWith("_allObjects") && id.startsWith("task_elementanswertaskgroups_")) {
        var parent = target.closest('.task-condition-allObjects');
        var parentIndex = $(parent).data('index');
        var allObjects = target.checked;
        var objectHolder = '#task-condition-objects-' + parentIndex;
        var questionAskCombinationHolder = target.closest('li.group-child');
        $(questionAskCombinationHolder).find('li.condition-child').each(function () {
            $(this).remove();
        });

        if (allObjects) {
            $(objectHolder).find('input[type=checkbox]:checked').removeAttr('checked');
            $(objectHolder).hide();
        } else {
            $(objectHolder).show();
        }
    }



    if (id.startsWith("show-building-task-group-")) {
        var parent = target.closest('.show-building-task-group');
        var parentIndex = $(parent).data('index');
        var buttonHolder = '#show-building-task-group-holder-'+parentIndex;

        $(buttonHolder).hide();

    }
    else if(id.startsWith("remove-building-task-group-")) {

        var parent = target.closest('.remove-building-task-group');
        var parentIndex = $(parent).data('index');
        var buttonHolder = '#show-building-task-group-holder-'+parentIndex;
        var objectCheckers = '#task-condition-allObjects-holder-'+parentIndex;
        var objectHolder = '#task-condition-objects-' + parentIndex;

        $(buttonHolder).show();
        $(objectCheckers).find('input[type=checkbox]:checked').removeAttr('checked');
        $(objectHolder).show();
    }
}

function addConditionChildForm($addConditionChildButton) {
    var indexGroupChild = $addConditionChildButton.data('index-group-child');
    // Get all the li from the childanswers (only from the active childelement
    var conditionParent = $('ul#condition-parent-' + indexGroupChild);
    var addConditionChildButton =
        conditionParent.find('.group-child-condition-child-add-link');
    ////////////////////////////////////////////////////////////////////
    // Get the data-prototype explained earlier
    var prototypeGroupChild = $('#prototype-condition-group').data('prototype-condition-group');
    // get the new index
    //var indexGroupChild = $groupParent.data('index');
    //var indexGroupChild = indexButton;
    // get the new index
    //var indexGroupChild = conditionParent.data('index-group-child');
    var indexConditionChild = conditionParent.find('li.condition-child').length;
    var newFormConditionChild = $('<div class="form-group"></div>').append(prototypeGroupChild.replace(/__conditions__/g, indexConditionChild));
    //var prototypeConditionChild = newFormConditionChild.find('#task_elementanswertaskgroups_' + indexGroupChild + '_elementsanswerstasks').data('prototype');
    var prototypeConditionChild = $('#prototype-condition-group').data('prototype-condition-child');
    ////////////////////////////////////////////////////////////////////
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newFormConditionGroup = prototypeConditionChild.replace(/__conditions__/g, indexConditionChild).replace(/__groups__/g, indexGroupChild);
    // Display the form in the page in an li, before the "Add a child" link li
    var $newGroupChildFormLi = $('<li class="condition-child list-unstyled"></li>').append(newFormConditionGroup);
    addConditionChildButton.before($newGroupChildFormLi);
    // add a delete link to the new form
    addGroupChildFormDeleteLink($newGroupChildFormLi);
    // Create dynamic fields for the element-answer combination (1. parent-el -> 2. child-el -> 3. answer)
    angular.element(document.getElementById('TaskController')).scope().initDynamicSelectNew(indexGroupChild, indexConditionChild, newFormConditionChild);
    // increase the index with one for the next item
    conditionParent.data('index', indexGroupChild + 1);
}






jQuery(document).ready(function () {
    checkVisibilityObjectSelecter();
})























