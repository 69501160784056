import $ from 'jquery';
import * as bootstrap from 'bootstrap';

var force = false;
let submitActivityFormModal = null;
jQuery(document).ready(function () {
    submitActivityFormModal = document.getElementById('modal-submit-activity');
});


function checkForOpenActions(actionform) {
    var status = actionform.find('[id ^=object_element_task_activities_][id $=status]').val();
    var result = ("1" !== status) ? true : false;
    return result;
}

$('form[name="object_element_task"]').submit(function () {
    var openActions = false;
    var popup = false
    $activitiesHolder = $('ul.activities');
    $activitiesHolder.find('li.activitie').each(function () {
        var statusVal = ($(this)).find('[id ^=object_element_task_activities][id $=status]').val();
        var statusActivity = ("1" === statusVal) ? true : false;
        if (!statusActivity) {
            $activitiesHolder.find('li.action').each(function () {
                openActions = checkForOpenActions($(this));
            });
        }
        if (!statusActivity && !openActions) {
            popup = true;
        }
    });
    if (force) {
        return;
    } else if (popup) {
        submitActivityFormModal.toggle();
        return false;
    } else {
        return;
    }
});

function submitActivityForm(forceParam) {
    force = (undefined === force) ? false : forceParam;
    submitActivityFormModal.hide();
    $('form[name="object_element_task"]').submit();
}
window.submitActivityForm=submitActivityForm;

/*
 -----------------------------------------------
 -----------------------------------------------
 Actions in activity
 -----------------------------------------------
 -----------------------------------------------
 */
//$('[id^=object_element_task_activities_][id$=conclusion]').on('change', function (e) {
var userChoiceList;
//$('#old-activities')
$('[id$=-activities]').on('change', '[id^=object_element_task_activities_][id$=conclusion]', function (e) {
    //alert("Handler for .change() called.");
    var startString = 'object_element_task_activities_';
    var endString = '_conclusion';
    var idString = e.target.id;
    var intId = idString.substring(idString.indexOf(startString) + startString.length, idString.indexOf(endString));
    var value = parseInt(e.target.value);
    if (value === 2) {
        $('#activity_' + intId + '_actions_form-group').show();
    } else {
        $('#activity_' + intId + '_actions_form-group').hide();
    }
});
$('[id$=-activities]').on('change', '[id^=object_element_task_activities_][id$=status]', function (e) {
    //alert("Handler for .change() called.");
    var startString = 'object_element_task_activities_';
    var endString = '_status';
    var idString = e.target.id;
    var intId = idString.substring(idString.indexOf(startString) + startString.length, idString.indexOf(endString));
    var value = parseInt(e.target.value);
    if (value === 2) {
        var reasonInput = $('#object_element_task_activities_' + intId + '_reasonUnapproved');
        var reasonInputHolder = $(reasonInput).parent().show();
        $('#activity_' + intId + '_reason_form-group').show();
    } else {
        var reasonInput = $('#object_element_task_activities_' + intId + '_reasonUnapproved');
        var reasonInputHolder = $(reasonInput).parent().hide();
        $('#activity_' + intId + '_reason_form-group').hide();
    }
});
/*
function getUsersToDelegateAction() {
    var url = Routing.generate('users_list_choice');
    var objectElementTaskId = $('#objectelementtask-id').data('entity-id');
    var postData = {'objectElementTaskId': objectElementTaskId}
    $.ajax({
        url: url,
        type: 'POST',
        cache: false,
        data: postData,
        success: function (response) {
            userChoiceList = response;
        },
        error: function (e) {

            // Fail message
        }
    })
}
*/
var $activitiesHolder;
var $newActivitiesHolder;
// setup an "add a child" link
var $addActivityLink = $(
    '<a class="btn btn-primary" href="#" id="activity-add-button">\n\
<i class="fa fa-plus"></i> Activiteit toevoegen</a>'
);
var $newActivityLinkLi = $('<li class="activity-add-link list-unstyled"></li>').prepend($addActivityLink);
jQuery(document).ready(function () {
    var userRole = $('#user-role');
    var isContractor = userRole.data('contractor');
    //getUsersToDelegateAction();
    // Get the ul that holds the collection of child
    $activitiesHolder = $('ul.activities');
    $newActivitiesHolder = $('ul.new-activities');
    // add a delete link to all of the existing 'activiteiten' form li elements
    if (!isContractor) {
        $activitiesHolder.find('li.activitie').each(function () {
            addActivityFormDeleteLink($(this));
        });
    }
    // add a add 'vervolgactie' link to all of the existing 'activiteiten' form li elements
    $activitiesHolder.find('ul.actions').each(function () {
        addActionLinkLi($(this));
    });
    // add a add 'attachment' link to all of the existing 'attachments' form li elements
    $activitiesHolder.find('ul.attachments').each(function () {
        addAttachmentLinkLi($(this));
    });
    // add a delete link to all of the existing 'attachments' form li elements
    if (!isContractor) {
        $activitiesHolder.find('li.attachment').each(function () {
            addAttachmentFormDeleteLink($(this));
        });
    }
    // add a delete link to all of the existing 'vervolacties' form li elements
    $activitiesHolder.find('li.action').each(function () {
        // addActionFormDeleteLink($(this));
    });
    // add the "add a child" anchor and li to the child ul
    //$activitiesHolder.prepend($newActivityLinkLi);
    $newActivitiesHolder.prepend($newActivityLinkLi);
    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    //$activitiesHolder.data('index', $activitiesHolder.find('li.activitie').length);
    $addActivityLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        var target = $(e.target);
        target.blur();
        // add a new child form (see next code block)
        addActivityForm($activitiesHolder, $newActivityLinkLi);
    });
});
$('body').on('click', 'a.add-actions-link', function (e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();
    addActionForm($(this));
});
$('body').on('click', 'a.add-attachments-link', function (e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();
    addAttachmentForm($(this));
});

function addAttachmentForm($addAttachmentButton) {
    var indexButton = $addAttachmentButton.data('index-activity');
    // Get all the li from the attachments (only from the active activity)
    var attachmentsHolder = $('ul#attachments-container-' + indexButton);
    var addAttachmentButton = attachmentsHolder.find('.activity-attachments-add-link');
    //var childElementHolder = $('ul#children-container');
    // Get the data-prototype explained earlier
    //var prototypeHolder = $('#prots');
    //var prototypeAttachment = $("[id$='attachments']").data('prototype-attachment');
    var prototypeAttachment = $('#appbundle_legosubject_activities___name___attachments').data('prototype-attachment');
    if (undefined === prototypeAttachment) {
        prototypeAttachment = $('#object_element_task_activities___name___attachments').data('prototype-attachment');
    }
    if (undefined === prototypeAttachment) {
        prototypeAttachment = $('#prototype-attachment').data('prototype-attachment');
    }
    var test = $('#prototype-attachment');
    //var prototype = $childrenHolder.data('prototype');
    // get the new index
    //var indexActivity = $activitiesHolder.data('index');
    //var dataIndexActivity = parseInt($activitiesHolder.find('li.activitie:last-child').data('index-activity'));
    //var indexActivity = (isNaN(dataIndexActivity)) ? 0 : dataIndexActivity;
    var indexActivity = indexButton;
    //var indexAttachment = attachmentsHolder.find('li.attachment').length;
    //var test = attachmentsHolder.find('li.attachment');
    var test = attachmentsHolder.children('li.attachment').last();
    var testIndex;
    var dataIndexAttachment = parseInt(attachmentsHolder.children('li.attachment').last().data('index-attachment'));
    var indexAttachment = (isNaN(dataIndexAttachment)) ? 0 : dataIndexAttachment + 1;
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newFormAttachment = prototypeAttachment.replace(/__activity_attachment__/g, indexAttachment).replace(/__name__/g, indexActivity);
    // increase the index with one for the next item
    attachmentsHolder.data('index', indexAttachment + 1);
    // Display the form in the page in an li, before the "Add a child" link li
    var $newAttachmentFormLi = $('<li data-index-attachment="' + indexAttachment + '" class="attachment list-unstyled"></li>').append(newFormAttachment);
    addAttachmentButton.before($newAttachmentFormLi);
    // add a delete link to the new form
    addAttachmentFormDeleteLink($newAttachmentFormLi);
    $(function () {
        $('.datepicker').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true
        });
        var date = new Date();
        var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        $('.datepicker-min').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true,
            startDate: today,
        });
        $('.datepicker-max').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true,
            endDate: today,
        });
    });
    //updateSelectExecuter(indexActivity, indexAttachment);
}

function addActionForm($addActionButton) {
    var indexButton = $addActionButton.data('index-activity');
    // Get all the li from the actions (only from the active activity)
    var actionsHolder = $('ul#actions-container-' + indexButton);
    var addActionButton = actionsHolder.find('.activity-actions-add-link');
    //var childElementHolder = $('ul#children-container');
    // Get the data-prototype explained earlier
    //var prototypeHolder = $('#prots');
    //var prototypeAction = $("[id$='actions']").data('prototype-action');
    var prototypeAction = $('#object_element_task_activities___name___actions').data('prototype-action');
    //var prototype = $childrenHolder.data('prototype');
    // get the new index
    //var indexActivity = $activitiesHolder.data('index');
    //var dataIndexActivity = parseInt($activitiesHolder.find('li.activitie:last-child').data('index-activity'));
    //var indexActivity = (isNaN(dataIndexActivity)) ? 0 : dataIndexActivity;
    var indexActivity = indexButton;
    //var indexAction = actionsHolder.find('li.action').length;
    //var test = actionsHolder.find('li.action');
    var test = actionsHolder.children('li.action').last();
    var testIndex;
    var dataIndexAction = parseInt(actionsHolder.children('li.action').last().data('index-action'));
    var indexAction = (isNaN(dataIndexAction)) ? 0 : dataIndexAction + 1;
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newFormAction = prototypeAction.replace(/__activity_action__/g, indexAction).replace(/__name__/g, indexActivity);
    // increase the index with one for the next item
    actionsHolder.data('index', indexAction + 1);
    // Display the form in the page in an li, before the "Add a child" link li
    var $newActionFormLi = $('<li data-index-action="' + indexAction + '" class="action list-unstyled"></li>').append(newFormAction);
    addActionButton.before($newActionFormLi);
    // add a delete link to the new form
    addActionFormDeleteLink($newActionFormLi);
    $(function () {
        $('.datepicker').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true
        });
        var date = new Date();
        var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        $('.datepicker-min').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true,
            startDate: today,
        });
        $('.datepicker-max').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true,
            endDate: today,
        });
    });
    //updateSelectExecuter(indexActivity, indexAction);
}

/*
function updateSelectExecuter(indexActivity, indexAction) {
    var selectActionExecuter = $('select[id=object_element_task_activities_' + indexActivity + '_actions_' + indexAction + '_executer');
    selectActionExecuter.empty();

     var answers = $scope.allOptions.filter(function (s) {
     return s.ChildId == value;
     });
    var choiceList = userChoiceList;
    selectActionExecuter.prepend("<option value='' selected='selected'></option>");
    selectActionExecuter.append('<optgroup label="Swedish Cars"></option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');
    selectActionExecuter.append('<optgroup label="German Cars"></option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');

     $.each(answers, function (key, value) {
     answerHolder.append($("<option></option>")
     .attr("value", value.Id).text(value.OptionName));
     });
}
*/
function addActivityForm($activitiesHolder, $newActivityLinkLi) {
    // Get the data-prototype explained earlier
    var prototypeActivity = $('#proto').data('prototype-activity');
    //var prototypeAction = $("[id$='actions']").data('prototype-action');
    var prototypeAction = $('#object_element_task_activities___name___actions').data('prototype-action');
    var prototypeAttachment = $('#appbundle_legosubject_activities___name___attachments').data('prototype-attachment');
    if (undefined == prototypeAttachment) {
        var prototypeAttachment = $('#object_element_task_activities___name___attachments').data('prototype-attachment');
    }
    //
    // get the new index
    var dataIndexActivity = parseInt($activitiesHolder.data('index'));
    //var dataIndexActivity = parseInt($activitiesHolder.find('li.activitie:last-child').data('index-activity'));
    var indexActivity = (isNaN(dataIndexActivity)) ? 0 : dataIndexActivity + 1;
    var actionsHolder = $('ul#actions-container-' + indexActivity);
    var attachmentsHolder = $('ul#attachments-container-' + indexActivity);
    //var indexAction = actionsHolder.find('li.action').length;
    var dataIndexAction = parseInt($activitiesHolder.find('li.action:last-child').data('index-action'));
    var dataIndexAttachment = parseInt($activitiesHolder.find('li.attachment:last-child').data('index-attachment'));
    var indexAction = (isNaN(dataIndexAction)) ? 0 : dataIndexAction + 1;
    var indexAttachment = (isNaN(dataIndexAttachment)) ? 0 : dataIndexAttachment + 1;
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    //CASE 1: in this case the actionform is already inserted
    //var newFormAction = prototypeAction.replace(/__activity_action__/g, indexAction).replace(/__name__/g, indexActivity).replace(/activity_0_actions_0/g, 'activity_' + indexActivity + '_actions_' + indexAction);
    //var liAction = $('<li class="action list-unstyled" data-index-action="'+indexAction+'">' +
    //'</li>').append(newFormAction);
    //CASE 2: in this case the actionform is not inserted but: the panel, the <ul> and the 'add-action-button' is already prepared
    //var newFormAction = prototypeAction.replace(/__activity_action__/g, indexAction).replace(/__name__/g, indexActivity).replace(/activity_0_actions_0/g, 'activity_' + indexActivity + '_actions_' + indexAction);
    var liAction = $('<li class="action list-unstyled" data-index-action="' + indexAction + '">' +
        '</li>').append();
    var ulAction = $('<ul data-index-activity="' + indexActivity + '" id="actions-container-' + indexActivity + '" class="collection actions">' +
        '</ul>').append(liAction);
    var htmlPanelHeading = $('<div class="panel-heading" role="tab" id="heading' + indexActivity + '">' +
        '<h4 class="panel-title">' +
        '<a role="button" data-bs-toggle="collapse" data-parent="#accordion' + indexActivity + '" href="#collapse' + indexActivity + '"' +
        'aria-expanded="true" aria-controls="collapse' + indexActivity + '">' +
        '<span class="clickable">' +
        '<i class="pull-left glyphicon glyphicon-minus-sign"></i>Vervolgacties' +
        '</span></a></h4></div>');
    var htmlPanelBody = $('<div id="collapse' + indexActivity + '" class="panel-collapse" role="tabpanel"' +
        'aria-labelledby="heading' + indexActivity + '">' +
        '<div class="panel-body">' +
        '</div></div>');
    htmlPanelBody.find('.panel-body').append(ulAction)
    var newFormActionUl =
        $('<div class="form-group" style="display: none;" id="activity_' + indexActivity + '_actions_form-group">' +
            '<div class="panel-group" id="accordion' + indexActivity + '" role="tablist" aria-multiselectable="true">' +
            '<div class="panel panel-default panel-parent">' +
            '</div></div></div>');
    newFormActionUl.find('.panel-parent').append(htmlPanelHeading).append(htmlPanelBody);
    //attachmentform
    var liAttachment = $('<li class="attachment list-unstyled" data-index-attachment="' + indexAttachment + '">' +
        '</li>').append();
    var ulAttachment = $('<ul data-index-activity="' + indexActivity + '" id="attachments-container-' + indexActivity + '" class="collection attachments">' +
        '</ul>').append(liAttachment);
    var htmlPanelHeading = $('<div class="panel-heading" role="tab" id="heading' + indexActivity + '">' +
        '<h4 class="panel-title">' +
        '<a role="button" data-bs-toggle="collapse" data-parent="#accordion' + indexActivity + '" href="#collapse' + indexActivity + '"' +
        'aria-expanded="true" aria-controls="collapse' + indexActivity + '">' +
        '<span class="clickable">' +
        '<i class="pull-left glyphicon glyphicon-minus-sign"></i>Bestanden' +
        '</span></a></h4></div>');
    var htmlPanelBody = $('<div id="collapse' + indexActivity + '" class="panel-collapse" role="tabpanel"' +
        'aria-labelledby="heading' + indexActivity + '">' +
        '<div class="panel-body">' +
        '</div></div>');
    htmlPanelBody.find('.panel-body').append(ulAttachment)
    var newFormAttachmentUl =
        $('<div class="form-group" id="activity_' + indexActivity + '_attachments_form-group">' +
            '<div class="panel-group" id="accordion' + indexActivity + '" role="tablist" aria-multiselectable="true">' +
            '<div class="panel panel-default panel-parent">' +
            '</div></div></div>');
    newFormAttachmentUl.find('.panel-parent').append(htmlPanelHeading).append(htmlPanelBody);
    //end of attachmentform
    var newFormActivity = $('<div class="form-group"></div>').append(prototypeActivity.replace(/__name__/g, indexActivity));
    // hide form group: object_element_task_activities___name___reasonUnapproved
    // increase the index with one for the next item
    $activitiesHolder.data('index', indexActivity + 1);
    // Display the form in the page in an li, before the "Add a child" link li
    var $newActivityFormLi = $('<li data-index-activity="' + indexActivity + '" class="well activitie list-unstyled"></li>').append(newFormActivity).append(newFormActionUl).append(newFormAttachmentUl);
    //var $newActivityFormWell = $('<li data-index-activity="' + indexActivity + '" class="well activitie list-unstyled"></li>').append($newActivityFormLi);
    $newActivityLinkLi.after($newActivityFormLi);
    checkVisibilityReasonUnapprove(indexActivity);
    addActionLinkLi(ulAction);
    addAttachmentLinkLi(ulAttachment);
    //addActionFormDeleteLink(liAction);
    // add a delete link to the new form
    addActivityFormDeleteLink($newActivityFormLi);
    $activitiesHolder.data('index', indexActivity);
    //updateSelectExecuter(indexActivity, indexAction);
    $(function () {
        $('.datepicker').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true
        });
        var date = new Date();
        var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        $('.datepicker-min').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true,
            startDate: today,
        });
        $('.datepicker-max').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true,
            endDate: today,
        });
    });
    checkActivityAddEnabled();
}

function checkVisibilityReasonUnapprove(indexActivity) {
    var reasonInput = $('#object_element_task_activities_' + indexActivity + '_reasonUnapproved');
    var reasonInputHolder = $(reasonInput).parent().hide();
}

function checkActivityAddEnabled() {
    var enable = true;
    $newActivitiesHolder.find('li.activitie').each(function () {
        //object_element_task_activities_0_id
        var test = $(this).find('[id ^=object_element_task_activities_][id $=_id]').val();
        if ("" === test) {
            enable = false;
        }
    });
    if (true === enable) {
        if ($('#activity-add-button').hasClass("disabled")) {
            $('#activity-add-button').removeClass("disabled");
        }
    } else {
        if (!$('#activity-add-button').hasClass("disabled")) {
            $('#activity-add-button').addClass("disabled");
        }
    }
}

function addActionLinkLi($newFormLi) {
    var indexButton = $newFormLi.data('index-activity');
    // setup an "add a child" link
    var $addChildLink = $(
        '<a data-index-activity="' + indexButton + '" class="btn btn-primary add-actions-link" href="#">\n\
    <i class="fa fa-plus"></i> Vervolgactie toevoegen</a>'
    );
    var $newChildLinkLi = $('<li data-index-activity="' + indexButton + '" class="activity-actions-add-link list-unstyled"></li>').append($addChildLink);
    $newFormLi.append($newChildLinkLi);
    /*
     var $childElementAnswersHolder = $newFormLi.closest('ul.child-answers');

     // add the "add a child" anchor and li to the child ul
     $childElementAnswersHolder.append($newChildLinkLi);
     */
}

function addAttachmentLinkLi($newFormLi) {
    var indexButton = $newFormLi.data('index-activity');
    // setup an "add a child" link
    var $addChildLink = $(
        '<a data-index-activity="' + indexButton + '" class="btn btn-primary add-attachments-link" href="#">\n\
    <i class="fa fa-plus"></i> Bestand toevoegen</a>'
    );
    var $newChildLinkLi = $('<li data-index-activity="' + indexButton + '" class="activity-attachments-add-link list-unstyled"></li>').append($addChildLink);
    $newFormLi.append($newChildLinkLi);
    /*
     var $childElementAnswersHolder = $newFormLi.closest('ul.child-answers');

     // add the "add a child" anchor and li to the child ul
     $childElementAnswersHolder.append($newChildLinkLi);
     */
}

function addActionFormDeleteLink($newFormLi) {
    var $removeFormA = $('<div class="form-group"><a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i> Vervolgactie verwijderen</a></div>');
    $newFormLi.append($removeFormA);
    //$newFormLi.find(".well-sm").append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
    });
}

function addAttachmentFormDeleteLink($newFormLi) {
    var $removeFormA = $('<div class="form-group"><a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i> Bestand verwijderen</a></div>');
    $newFormLi.append($removeFormA);
    //$newFormLi.find(".well-sm").append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
    });
}

function addActivityFormDeleteLink($newFormLi) {
    var $removeFormA = $('<div class="form-group"><a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i> Activiteit verwijderen</a></div>');
    $newFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
        checkActivityAddEnabled();
    });
}

/*
 -----------------------------------------------
 -----------------------------------------------
 Actions in plannedplannedactivity
 -----------------------------------------------
 -----------------------------------------------
 */
//$('[id^=object_element_task_plannedactivities_][id$=conclusion]').on('change', function (e) {
var userChoiceList;
// if planned activity is executed show part of form to fill in to make it an execute task
$('#plannedactivities').on('change', '[id^=object_element_task_plannedactivities_][id$=executed]', function (e) {
    //alert("Handler for .change() called.");
    var startString = 'object_element_task_plannedactivities_';
    var endString = '_executed';
    var idString = e.target.id;
    var intId = idString.substring(idString.indexOf(startString) + startString.length, idString.indexOf(endString));
    var value = e.target.checked;
    if (true === value) {
        $('#plannedactivity_' + intId + '_executed_form-group').show();
    } else {
        $('#plannedactivity_' + intId + '_executed_form-group').hide();
    }
});
// if conclusion == follow up acion needed -> show follow up action form
$('#plannedactivities').on('change', '[id^=object_element_task_plannedactivities_][id$=conclusion]', function (e) {
    //alert("Handler for .change() called.");
    var startString = 'object_element_task_plannedactivities_';
    var endString = '_conclusion';
    var idString = e.target.id;
    var intId = idString.substring(idString.indexOf(startString) + startString.length, idString.indexOf(endString));
    var value = parseInt(e.target.value);
    if (value === 2) {
        $('#plannedactivity_' + intId + '_actions_form-group').show();
    } else {
        $('#plannedactivity_' + intId + '_actions_form-group').hide();
    }
});
// if activity is not approved -> show text input to explain why
$('#plannedactivities').on('change', '[id^=object_element_task_plannedactivities_][id$=status]', function (e) {
    //alert("Handler for .change() called.");
    var startString = 'object_element_task_plannedactivities_';
    var endString = '_status';
    var idString = e.target.id;
    var intId = idString.substring(idString.indexOf(startString) + startString.length, idString.indexOf(endString));
    var value = parseInt(e.target.value);
    if (value === 2) {
        var reasonInput = $('#object_element_task_plannedactivities_' + intId + '_reasonUnapproved');
        var reasonInputHolder = $(reasonInput).parent().show();
        $('#plannedactivity_' + intId + '_reason_form-group').show();
    } else {
        var reasonInput = $('#object_element_task_plannedactivities_' + intId + '_reasonUnapproved');
        var reasonInputHolder = $(reasonInput).parent().hide();
        $('#plannedactivity_' + intId + '_reason_form-group').hide();
    }
});
/*
function getUsersToDelegateAction() {
    var url = Routing.generate('users_list_choice');
    var objectElementTaskId = $('#objectelementtask-id').data('entity-id');
    var postData = {'objectElementTaskId': objectElementTaskId}
    $.ajax({
        url: url,
        type: 'POST',
        cache: false,
        data: postData,
        success: function (response) {
            userChoiceList = response;
        },
        error: function (e) {

            // Fail message
        }
    })
}
*/
var $plannedactivitiesHolder;
// setup an "add a child" link
var $addplannedactivityLink = $(
    '<a class="btn btn-primary" href="#" id="plannedactivity-add-button">\n\
<i class="fa fa-plus"></i> Geplande activiteit toevoegen</a>'
);
var $newplannedactivityLinkLi = $('<li class="plannedactivity-add-link list-unstyled"></li>').prepend($addplannedactivityLink);
jQuery(document).ready(function () {
    //getUsersToDelegateAction();
    // Get the ul that holds the collection of child
    $plannedactivitiesHolder = $('ul.plannedactivities');
    // add a delete link to all of the existing child form li elements
    $plannedactivitiesHolder.find('li.plannedactivity').each(function () {
        addplannedactivityFormDeleteLink($(this));
    });    // add a delete link to all of the existing child form li elements
    $plannedactivitiesHolder.find('ul.plannedactivity-actions').each(function () {
        addPlannedActivityActionLinkLi($(this));
    });
    // add a delete link to all of the existing child-answers form li elements
    $plannedactivitiesHolder.find('li.plannedactivity-action').each(function () {
        addPlannedActivityActionFormDeleteLink($(this));
    });
    // add the "add a child" anchor and li to the child ul
    $plannedactivitiesHolder.prepend($newplannedactivityLinkLi);
    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    //$plannedactivitiesHolder.data('index', $plannedactivitiesHolder.find('li.activitie').length);
    $addplannedactivityLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        var target = $(e.target);
        target.blur();
        // add a new child form (see next code block)
        addplannedactivityForm($plannedactivitiesHolder, $newplannedactivityLinkLi);
    });
});
$('body').on('click', 'a.add-plannedactivity-actions-link', function (e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();
    addPlannedActivityActionForm($(this));
});

function addPlannedActivityActionForm($addActionButton) {
    var indexButton = $addActionButton.data('index-plannedactivity');
    // Get all the li from the actions (only from the active plannedactivity)
    var actionsHolder = $('ul#plannedactivity-actions-container-' + indexButton);
    var addActionButton = actionsHolder.find('.plannedactivity-actions-add-link');
    //var childElementHolder = $('ul#children-container');
    // Get the data-prototype explained earlier
    //var prototypeHolder = $('#prots');
    //var prototypeAction = $("[id$='actions']").data('prototype-action');
    var prototypeAction = $('#object_element_task_plannedactivities___name___actions').data('prototype-action');
    //var prototype = $childrenHolder.data('prototype');
    // get the new index
    //var indexplannedactivity = $plannedactivitiesHolder.data('index');
    //var dataIndexplannedactivity = parseInt($plannedactivitiesHolder.find('li.activitie:last-child').data('index-plannedactivity'));
    //var indexplannedactivity = (isNaN(dataIndexplannedactivity)) ? 0 : dataIndexplannedactivity;
    var indexplannedactivity = indexButton;
    //var indexAction = actionsHolder.find('li.action').length;
    //var test = actionsHolder.find('li.action');
    var test = actionsHolder.children('li.plannedactivity-action').last();
    var testIndex;
    var dataIndexAction = parseInt(actionsHolder.children('li.action').last().data('index-action'));
    var indexAction = (isNaN(dataIndexAction)) ? 0 : dataIndexAction + 1;
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newFormAction = prototypeAction.replace(/__plannedactivity_action__/g, indexAction).replace(/__name__/g, indexplannedactivity);
    // increase the index with one for the next item
    actionsHolder.data('index', indexAction + 1);
    // Display the form in the page in an li, before the "Add a child" link li
    var $newActionFormLi = $('<li data-index-action="' + indexAction + '" class="action list-unstyled"></li>').append(newFormAction);
    addActionButton.before($newActionFormLi);
    // add a delete link to the new form
    addActionFormDeleteLink($newActionFormLi);
    $(function () {
        $('.datepicker').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true
        });
        var date = new Date();
        var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        $('.datepicker-min').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true,
            startDate: today,
        });
        $('.datepicker-max').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true,
            endDate: today,
        });
    });
    //updateSelectExecuter(indexplannedactivity, indexAction);
}

/*
function updateSelectExecuter(indexplannedactivity, indexAction) {
    var selectActionExecuter = $('select[id=object_element_task_plannedactivities_' + indexplannedactivity + '_actions_' + indexAction + '_executer');
    selectActionExecuter.empty();

     var answers = $scope.allOptions.filter(function (s) {
     return s.ChildId == value;
     });
    var choiceList = userChoiceList;
    selectActionExecuter.prepend("<option value='' selected='selected'></option>");
    selectActionExecuter.append('<optgroup label="Swedish Cars"></option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');
    selectActionExecuter.append('<optgroup label="German Cars"></option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');
    selectActionExecuter.append('<option value="volvo">Volvo</option>');

     $.each(answers, function (key, value) {
     answerHolder.append($("<option></option>")
     .attr("value", value.Id).text(value.OptionName));
     });
}
*/
function addplannedactivityForm($plannedactivitiesHolder, $newplannedactivityLinkLi) {
    // Get the data-prototype explained earlier
    var test = $('#proto-planned');
    var prototypeplannedactivity = $('#proto-planned').data('prototype-plannedactivity');
    //var prototypeAction = $("[id$='actions']").data('prototype-action');
    var prototypeAction = $('#object_element_task_plannedactivities___name___actions').data('prototype-action');
    // get the new index
    var dataIndexplannedactivity = parseInt($plannedactivitiesHolder.data('index'));
    //var dataIndexplannedactivity = parseInt($plannedactivitiesHolder.find('li.activitie:last-child').data('index-plannedactivity'));
    var indexplannedactivity = (isNaN(dataIndexplannedactivity)) ? 0 : dataIndexplannedactivity + 1;
    var actionsHolder = $('ul#plannedactivity-actions-container-' + indexplannedactivity);
    //var indexAction = actionsHolder.find('li.action').length;
    var dataIndexAction = parseInt($plannedactivitiesHolder.find('li.plannedactivity-action:last-child').data('index-action'));
    var indexAction = (isNaN(dataIndexAction)) ? 0 : dataIndexAction + 1;
    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    //CASE 1: in this case the actionform is already inserted
    //var newFormAction = prototypeAction.replace(/__plannedactivity_action__/g, indexAction).replace(/__name__/g, indexplannedactivity).replace(/plannedactivity_0_actions_0/g, 'plannedactivity_' + indexplannedactivity + '_actions_' + indexAction);
    //var liAction = $('<li class="action list-unstyled" data-index-action="'+indexAction+'">' +
    //'</li>').append(newFormAction);
    //CASE 2: in this case the actionform is not inserted but: the panel, the <ul> and the 'add-action-button' is already prepared
    //var newFormAction = prototypeAction.replace(/__plannedactivity_action__/g, indexAction).replace(/__name__/g, indexplannedactivity).replace(/plannedactivity_0_actions_0/g, 'plannedactivity_' + indexplannedactivity + '_actions_' + indexAction);
    var liAction = $('<li class="plannedactivity-action list-unstyled" data-index-action="' + indexAction + '">' +
        '</li>').append();
    var ulAction = $('<ul data-index-plannedactivity="' + indexplannedactivity + '" id="plannedactivity-actions-container-' + indexplannedactivity + '" class="collection plannedactivity-actions">' +
        '</ul>').append(liAction);
    var htmlPanelHeading = $('<div class="panel-heading" role="tab" id="heading' + indexplannedactivity + '">' +
        '<h4 class="panel-title">' +
        '<a role="button" data-bs-toggle="collapse" data-parent="#accordion' + indexplannedactivity + '" href="#collapse' + indexplannedactivity + '"' +
        'aria-expanded="true" aria-controls="collapse' + indexplannedactivity + '">' +
        '<span class="clickable">' +
        '<i class="pull-left glyphicon glyphicon-minus-sign"></i>Vervolgacties' +
        '</span></a></h4></div>');
    var htmlPanelBody = $('<div id="collapse' + indexplannedactivity + '" class="panel-collapse" role="tabpanel"' +
        'aria-labelledby="heading' + indexplannedactivity + '">' +
        '<div class="panel-body">' +
        '</div></div>');
    htmlPanelBody.find('.panel-body').append(ulAction)
    var newFormActionUl =
        $('<div class="form-group" style="display: none;" id="plannedactivity_' + indexplannedactivity + '_actions_form-group">' +
            '<div class="panel-group" id="accordion' + indexplannedactivity + '" role="tablist" aria-multiselectable="true">' +
            '<div class="panel panel-default panel-parent">' +
            '</div></div></div>');
    newFormActionUl.find('.panel-parent').append(htmlPanelHeading).append(htmlPanelBody);
    var newFormplannedactivity = $('<div class="form-group"></div>').append(prototypeplannedactivity.replace(/__name__/g, indexplannedactivity));
    // hide form group: object_element_task_plannedactivities___name___reasonUnapproved
    // increase the index with one for the next item
    $plannedactivitiesHolder.data('index', indexplannedactivity + 1);
    // Display the form in the page in an li, before the "Add a child" link li
    var $newplannedactivityFormLi = $('<li data-index-plannedactivity="' + indexplannedactivity + '" class="well plannedactivity list-unstyled"></li>').append(newFormplannedactivity).append(newFormActionUl);
    //var $newplannedactivityFormWell = $('<li data-index-plannedactivity="' + indexplannedactivity + '" class="well activitie list-unstyled"></li>').append($newplannedactivityFormLi);
    $newplannedactivityLinkLi.after($newplannedactivityFormLi);
    checkVisibilityReasonUnapprovePlannedActivity(indexplannedactivity);
    addPlannedActivityActionLinkLi(ulAction);
    //addActionFormDeleteLink(liAction);
    // add a delete link to the new form
    addplannedactivityFormDeleteLink($newplannedactivityFormLi);
    $plannedactivitiesHolder.data('index', indexplannedactivity);
    //updateSelectExecuter(indexplannedactivity, indexAction);
    $(function () {
        $('.datepicker').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true
        });
        var date = new Date();
        var today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        $('.datepicker-min').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true,
            startDate: today,
        });
        $('.datepicker-max').datepicker({
            format: "dd-mm-yyyy",
            todayBtn: "linked",
            calendarWeeks: true,
            autoclose: true,
            endDate: today,
        });
    });
    checkplannedactivityAddEnabled();
    var $formHolder = $('#object_element_task_plannedactivities_' + indexplannedactivity + ' .form-group');
    //2 t/m 4
    var $divPlanned = $("<div/>", {
        id: 'plannedactivity_' + indexplannedactivity + '_planned_form-group',
        style: 'display: block',
    });
    //5 t/m 9
    var $divExecuted = $("<div/>", {
        id: 'plannedactivity_' + indexplannedactivity + '_executed_form-group',
        style: 'display: none',
    });
    $formHolder.slice(2, 5).wrapAll($divPlanned);
    $formHolder.slice(5, 11).wrapAll($divExecuted);
}

function checkVisibilityReasonUnapprovePlannedActivity(indexplannedactivity) {
    var reasonInput = $('#object_element_task_plannedactivities_' + indexplannedactivity + '_reasonUnapproved');
    var reasonInputHolder = $(reasonInput).parent().hide();
}

function checkplannedactivityAddEnabled() {
    var enable = true;
    $plannedactivitiesHolder.find('li.plannedactivity').each(function () {
        //object_element_task_plannedactivities_0_id
        var test = $(this).find('[id ^=object_element_task_plannedactivities_][id $=_id]').val();
        if ("" === test) {
            enable = false;
        }
    });
    if (true === enable) {
        if ($('#plannedactivity-add-button').hasClass("disabled")) {
            $('#plannedactivity-add-button').removeClass("disabled");
        }
    } else {
        if (!$('#plannedactivity-add-button').hasClass("disabled")) {
            $('#plannedactivity-add-button').addClass("disabled");
        }
    }
}

function addPlannedActivityActionLinkLi($newFormLi) {
    var indexButton = $newFormLi.data('index-plannedactivity');
    // setup an "add a child" link
    var $addChildLink = $(
        '<a data-index-plannedactivity="' + indexButton + '" class="btn btn-primary add-plannedactivity-actions-link" href="#">\n\
    <i class="fa fa-plus"></i> Vervolgactie toevoegen</a>'
    );
    var $newChildLinkLi = $('<li data-index-plannedactivity="' + indexButton + '" class="plannedactivity-actions-add-link list-unstyled"></li>').append($addChildLink);
    $newFormLi.append($newChildLinkLi);
    /*
     var $childElementAnswersHolder = $newFormLi.closest('ul.child-answers');

     // add the "add a child" anchor and li to the child ul
     $childElementAnswersHolder.append($newChildLinkLi);
     */
}

function addPlannedActivityActionFormDeleteLink($newFormLi) {
    var $removeFormA = $('<div class="form-group"><a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i> Vervolgactie verwijderen</a></div>');
    $newFormLi.append($removeFormA);
    //$newFormLi.find(".well-sm").append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
    });
}

function addplannedactivityFormDeleteLink($newFormLi) {
    var $removeFormA = $('<div class="form-group"><a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i> Geplande activiteit verwijderen</a></div>');
    $newFormLi.append($removeFormA);
    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();
        // remove the li for the child form
        $newFormLi.remove();
        checkplannedactivityAddEnabled();
    });
}
