import {Controller} from '@hotwired/stimulus';
import "leaflet/dist/leaflet.css"
// import L from "leaflet"
import { useDispatch} from "stimulus-use";


export default class extends Controller {
    static targets = [
    ]

    connect() {
        useDispatch(this, {debug:true})
    }

    async onChange(event){
        this.dispatch('changed',{
            event
        })
    }

}


