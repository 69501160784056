(function () {

    //SYMFONY + ANGULAR FORMS
    app = angular.module('SymfonyAngularForm', [])

        //ANGULAR + SYMFONY FORM CONTROLLER
        //handles form submission validation and collections
        .controller('SymfonyAngularFormController', ['$scope', '$window', '$sce', function ($scope, $window, $sce) {

            $scope.collections = []; //this holds the new "models" (the collection's HTML)
            $scope.collectionIndexes = []; //this holds the "model" indexes. We can not reuse the same index when we add/delete (angular will be weird with values)


            queryParams = $window.location.href.match(/\?name=(.*)/)
            $scope.name = queryParams == null? "...": queryParams[1];
            $scope.location = $window.location.href;

            //$scope.name = 'test';

            $scope.collection = function (collectionId) {
                $scope.collections[collectionId] = [];  //adds new collection with index key to keep track of our index
                $scope.collectionIndexes[collectionId] = 1;  //starts off our index at 1
            };

            //Adds one item onto the specific collection
            $scope.collectionAdd = function (collectionId, collectionName, html) {
                var regEx = new RegExp('__' + collectionName + '__', 'g'); //want to replace the prototype placeholders with our collection index
                $scope.collections[collectionId].push({
                    html: $sce.trustAsHtml(html.replace(regEx, $scope.collectionIndexes[collectionId])),
                });
                $scope.collectionIndexes[collectionId]++;
            }

            //Removes one item from specific collection, optinal message param if you want a message warning to confirm deletion of the item from the collection
            $scope.collectionRemove = function (collectionId, index, message) {
                if (message !== undefined) {
                    if (confirm(message)) {
                        $scope.collections[collectionId].splice(index, 1);
                    }
                } else {
                    $scope.collections[collectionId].splice(index, 1);
                }
            }

            $scope.submitForm = function (form, e) {
                var formErrors = form.$error;
                if (form.$valid != true) {
                    for (var validation in formErrors) {
                        for (var error in formErrors[validation]) {
                            formErrors[validation][error].$setDirty();
                        }
                    }
                    $('html, body').animate({
                        scrollTop: $("form[name='" + form.$name + "']").offset().top - 100
                    }, 2000);
                    alert('Please review the form and correct any errors. All fields should be "green"');
                    e.preventDefault();
                }


            };

        }])
        //END ANGULAR + SYMFONY FORM CONTROLLER

        //this compiles the HTML so angular can "see" it
        .directive('compileTemplate', function ($compile, $parse) {
            return {
                link: function (scope, element, attr) {
                    var parsed = $parse(attr.ngBindHtml);

                    function getStringValue() {
                        return (parsed(scope) || '').toString();
                    }

                    //Recompile if the template changes
                    scope.$watch(getStringValue, function () {
                        $compile(element, null, -9999)(scope);  //The -9999 makes it skip directives so that we do not recompile ourselves
                    });
                }
            }
        })

})();