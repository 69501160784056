/* global angular */
import * as bootstrap from "bootstrap";

(function () {
    const modalLoading = ($( "#modal-loading").length ) ? new bootstrap.Modal(document.getElementById('modal-loading')) : null;
    // const modalSaveObject = ($( "#modal-save-object").length ) ? new bootstrap.Modal(document.getElementById('modal-save-object')) : null;
    // const modalSuccess = ($( "#modal-success").length ) ? new bootstrap.Modal(document.getElementById('modal-success')) : null;
    // const modalError = ($( "#modal-error").length ) ? new bootstrap.Modal(document.getElementById('modal-error')) : null;
    'use strict';
    var app = angular.module('formlyTask', [
        'formly',
        'formlyBootstrap',
        'ngAnimate',
        'ngMessages'
    ]);
    app.run(function (formlyConfig, formlyValidationMessages) {
        formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'fc.$touched || form.$submitted';
        formlyValidationMessages.addStringMessage('required', 'This field is required');
    });
    app.config(function ($httpProvider) {
            $httpProvider.interceptors.push('myHttpInterceptor');
            var spinnerFunction = function (data, headersGetter) {
                return data;
            };
            $httpProvider.defaults.transformRequest.push(spinnerFunction);
        })

        // register the interceptor as a service, intercepts ALL angular ajax http calls
        .factory('myHttpInterceptor', function ($q, $window) {
            return function (promise) {
                return promise.then(function (response) {
                    modalLoading.hide();
                    return response;
                }, function (response) {
                    modalLoading.hide();
                    return $q.reject(response);
                });
            };
        });
    app.config(function (formlyConfigProvider) {
        formlyConfigProvider.setWrapper({
            name: 'validation',
            types: ['input'],
            templateUrl: 'error-messages.html'
        });
        formlyConfigProvider.setWrapper({
            name: 'panel',
            templateUrl: 'panel.html'
        });
    });
    app.config(function ($interpolateProvider) {
        $interpolateProvider.startSymbol('{[{').endSymbol('}]}');
    });
    app.controller('TaskCtrl', function TaskCtrl(formlyVersion, $scope, $http, $location) {
        $scope.taskId;
        $scope.response;
        $scope.$watch("taskId", function () {
            var urlSet = Routing.generate('task_edit_api', {id: $scope.taskId});
            var urlNull = Routing.generate('task_edit_api', {id: 'null'});
            var url = ('' !== $scope.taskId) ? urlSet : urlNull;
            modalLoading.show();
            $http({
                method: 'GET',
                url: url
            }).then(function (response) {
                $scope.response = response;
                modalLoading.hide();
                populateTask();
                responseFunction(response);
            }, function (response) {
                modalLoading.hide();
            });
        });
        function responseFunction(response) {
            initFormData(response)
        }

        function adaptChildren(indexGroup, indexCondition, value) {
            //var childId = 'task_elementsanswerstasks_' + index + '_child_element';
            var childId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_child_element';
            var childHolder = $("select[id=" + childId + "]");
            //var answerId = 'task_elementsanswerstasks_' + index + '_answer';
            var answerId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_answer';
            var answerHolder = $("select[id=" + answerId + "]");
            childHolder.empty();
            answerHolder.empty();
            var children = $scope.allChildren.filter(function (s) {
                return s.ParentId == value;
            });
            childHolder.prepend("<option value='' selected='selected'></option>");
            $.each(children, function (key, value) {
                childHolder.append($("<option></option>")
                    .attr("value", value.Id).text(value.ChildName));
            });
        }

        function adaptChildrenNew(indexGroup, indexCondition, value) {

            //var childId = 'task_elementsanswerstasks_' + index + '_child_element';
            var childId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_child_element';
            var childHolder = $("select[id=" + childId + "]");
            //var answerId = 'task_elementsanswerstasks_' + index + '_answer';
            var answerId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_answer';
            var answerHolder = $("select[id=" + answerId + "]");
            childHolder.empty();
            answerHolder.empty();
            var children = $scope.allChildren.filter(function (s) {
                return s.ParentId == value;
            });
            childHolder.prepend("<option value='' selected='selected'></option>");
            $.each(children, function (key, value) {
                childHolder.append($("<option></option>")
                    .attr("value", value.Id).text(value.ChildName));
            });
        }

        function adaptAnswers(index, value) {
            var answerId = 'task_elementsanswerstasks_' + index + '_answer';
            var answerHolder = $("select[id=" + answerId + "]");
            answerHolder.empty();
            var answers = $scope.allOptions.filter(function (s) {
                return s.ChildId == value;
            });
            answerHolder.prepend("<option value='' selected='selected'></option>");
            $.each(answers, function (key, value) {
                answerHolder.append($("<option></option>")
                    .attr("value", value.Id).text(value.OptionName));
            });
        }

        function adaptAnswersNew(indexGroup, indexCondition, value) {
            //var answerId = 'task_elementsanswerstasks_' + index + '_answer';
            var answerId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_answer';
            var answerHolder = $("select[id=" + answerId + "]");
            answerHolder.empty();
            var answers = $scope.allOptions.filter(function (s) {
                return s.ChildId == value;
            });
            answerHolder.prepend("<option value='' selected='selected'></option>");
            $.each(answers, function (key, value) {
                answerHolder.append($("<option></option>")
                    .attr("value", value.Id).text(value.OptionName));
            });
        }

        //Make select values dependent
        $scope.initDynamicSelect = function (indexGroup, indexCondition) {
            //task_elementanswertaskgroups_0_elementsanswerstasks_0_parent_element
            var parentId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_parent_element';
            var parentHolder = $("select[id=" + parentId + "]");
            //task_elementanswertaskgroups_0_elementsanswerstasks_0_child_element
            var childId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_child_element';
            var childHolder = $("select[id=" + childId + "]");
            //task_elementanswertaskgroups_0_elementsanswerstasks_0_answer
            var answerId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_answer';
            var answerHolder = $("select[id=" + answerId + "]");
            parentHolder.attr('ng-model', 'parent');
            parentHolder.attr('data-index-group', indexGroup);
            parentHolder.attr('data-index-condition', indexCondition);
            parentHolder.attr('data-level', 'parent');
            childHolder.attr('ng-model', 'child');
            childHolder.attr('data-index-group', indexGroup);
            childHolder.attr('data-index-condition', indexCondition);
            childHolder.attr('data-level', 'child');
            answerHolder.attr('ng-model', 'option');
            var selectedParent = parentHolder.val();
            var selectedChild = childHolder.val();
            var selectedAnswer = answerHolder.val();
            parentHolder.empty();
            childHolder.empty();
            //answerHolder.empty();
            //parentHolder.prepend("<option value='' selected='selected'></option>");
            /*
             $.when(responseFunction()).done(function(){
             // the code here will be executed when all four ajax requests resolve.
             // a1, a2, a3 and a4 are lists of length 3 containing the response text,
             // status, and jqXHR object for each of the four ajax calls respectively.
             });
             */
            var urlSet = Routing.generate('task_edit_api', {id: $scope.taskId});
            var urlNull = Routing.generate('task_edit_api', {id: 'null'});
            var url = ('' !== $scope.taskId) ? urlSet : urlNull;
            modalLoading.show();
            $http({
                method: 'GET',
                url: url
            }).then(function (response) {
                $scope.response = response;
                modalLoading.hide();
                populateTask();
                responseFunction(response);
                $.each($scope.parents, function (key, value) {
                    parentHolder.append($("<option></option>")
                        .attr("value", value.Id).text(value.ParentName));
                });
                parentHolder.val(selectedParent);
                adaptChildrenNew(indexGroup, indexCondition, selectedParent);
                childHolder.val(selectedChild);
                adaptAnswersNew(indexGroup, indexCondition, selectedChild);
                answerHolder.val(selectedAnswer);
                //data-index-group-child="0"
                $('body').on('change', '#group-parent', function (e) {
                    var level = e.target.dataset.level;
                    var indexGroup = e.target.dataset.indexGroup;
                    var indexCondition = e.target.dataset.indexCondition;
                    var value = e.target.value;
                    if (level === 'parent') {
                        adaptChildrenNew(indexGroup, indexCondition, value);
                    }
                    if (level === 'child') {
                        adaptAnswersNew(indexGroup, indexCondition, value);
                    }
                });
            }, function (response) {
                modalLoading.hide();
            });
        }
        //Make select values dependent
        // This function is for the ConditionGroups
        $scope.initDynamicSelectNew = function (indexGroup, indexCondition, newForm) {

            //task_elementanswertaskgroups_0_elementsanswerstasks_0_parent_element
            var parentId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_parent_element';
            var parentHolder = $("select[id=" + parentId + "]");
            //task_elementanswertaskgroups_0_elementsanswerstasks_0_child_element
            var childId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_child_element';
            var childHolder = $("select[id=" + childId + "]");
            //task_elementanswertaskgroups_0_elementsanswerstasks_0_answer
            var answerId = 'task_elementanswertaskgroups_' + indexGroup + '_elementsanswerstasks_' + indexCondition + '_answer';
            var answerHolder = $("select[id=" + answerId + "]");
            parentHolder.attr('ng-model', 'parent');
            parentHolder.attr('data-index-group', indexGroup);
            parentHolder.attr('data-index-condition', indexCondition);
            parentHolder.attr('data-level', 'parent');
            childHolder.attr('ng-model', 'child');
            childHolder.attr('data-index-group', indexGroup);
            childHolder.attr('data-index-condition', indexCondition);
            childHolder.attr('data-level', 'child');
            answerHolder.attr('ng-model', 'option');
            parentHolder.empty();
            childHolder.empty();
            answerHolder.empty();
            parentHolder.prepend("<option value='' selected='selected'></option>");
            $.each($scope.parents, function (key, value) {
                parentHolder.append($("<option></option>")
                    .attr("value", value.Id).text(value.ParentName));
            });
            $('body').on('change', '#group-parent', function (e) {
                var level = e.target.dataset.level;
                var indexGroup = e.target.dataset.indexGroup;
                var indexCondition = e.target.dataset.indexCondition;
                var value = e.target.value;
                if (level === 'parent') {
                    adaptChildrenNew(indexGroup, indexCondition, value);
                }
                if (level === 'child') {
                    adaptAnswersNew(indexGroup, indexCondition, value);
                }
            });
        }
        function populateTask() {
            var response = $scope.response;
            $scope.data = response.data || "Request failed";
            $scope.status = response.status;
            $scope.eLanTasks = {};
            var AllElAnTasks = response.data.object.elements.parents;
            $scope.eLanTasks = AllElAnTasks;
            $scope.elements = AllElAnTasks;
            $scope.elementOptions = AllElAnTasks;
        }


        function initFormData(response) {
            $scope.parent = {};
            $scope.child = {};
            $scope.option = {};
            $scope.allParents = response.data.object.elements.parents;
            $scope.allChildren = response.data.object.elements.children;
            $scope.allOptions = response.data.object.elements.options;
            $scope.data = response.data || "Request failed";
            $scope.status = response.status;
            $scope.parents = $scope.allParents;
            $scope.$watch('parent', function () {
                $scope.children = $scope.allChildren.filter(function (s) {
                    return s.ParentId == $scope.parent.Id;
                });
                $scope.option = {};
                $scope.child = {};
                $scope.options = [];
            });
            $scope.$watch('child', function () {
                $scope.options = $scope.allOptions.filter(function (c) {
                    return c.ChildId == $scope.child.Id;
                });
                $scope.option = {};
            });
        }

        $scope.settings = {
            bootstrap2: true,
            filterClear: 'Show all',
            filterPlaceHolder: 'Filter',
            moveSelectedLabel: 'Move selected only',
            moveAllLabel: 'Move all!',
            removeSelectedLabel: 'Remove selected only',
            removeAllLabel: 'Remove all',
            nonSelectedListLabel: 'Non-selected',
            selectedListLabel: 'Selected',
            preserveSelectionOnMove: 'moved',
            moveOnSelect: false,
            postfix: '_helperz',
            selectMinHeight: 200,
            filter: true,
            filterNonSelected: null,
            filterSelected: null,
            infoAll: 'Showing all {0}',
            infoFiltered: '<span class="label label-warning">Filtered</span> {0} from {1}',
            infoEmpty: 'Empty list',
            filterValues: true
        };
    });
    app.controller('AttributeController', function ($scope, $http, $timeout, filterService) {
        $scope.filters = filterService.getFilters();
        $scope.addNewForm = function () {
            $scope.filters.push({});
        };
        $scope.attribute = filterService.getAttribute();
        $scope.comparison = function (index, field) {
            console.log("f:" + field);
            $scope.comparisonType = filterService.getComparison($scope.attribute[field]);
        }
        $scope.value = function (index, parent, field) {
            console.log(index + ' ' + parent + ' ' + field);
            $scope.filters[index].vType = filterService.getValueType($scope.attribute[parent], $scope.comparisonType[field]);
        }
        var attributeConditionData = $('#attributeConditionData').data('field');
        if (undefined !== attributeConditionData) {
            var jsonAttributeConditionData = JSON.parse(attributeConditionData);
        }
        if (undefined !== attributeConditionData && "" !== jsonAttributeConditionData) {
            var obj = jQuery.parseJSON(jsonAttributeConditionData);
            for (var key in obj) {
                $scope.filters.push(obj[key]);
                angular.forEach($scope.filters, function (value, key) {
                    $scope.comparison(key, value.attribute)
                });
            }
        }

    })
    app.service('filterService', function () {
        var attribute = [
            {name: 'Bouwjaar', type: 'integer', id: 1},
            {name: 'BVO', type: 'integer', id: 2},
            {name: 'Functie', type: 'select', id: 3},
            {name: 'Afdeling', type: 'multiselect', id: 4},
            {name: 'Aankoopdatum', type: 'date', id: 5},
        ];
        this.getFilters = function () {
            return [];
        }
        this.getAttribute = function () {
            return attribute;
        }
        this.getComparison = function (attribute) {

            var comparisonType = [];
            if (attribute.type == 'select' || attribute.type == 'multiselect') {
                comparisonType = [
                    {name: 'is', id: 1},
                    {name: 'is niet', id: 2},
                    {name: 'is een van', id: 3},
                    {name: 'is niet een van', id: 4},
                ]
            } else if (attribute.type == 'integer' || attribute.type == 'date') {
                comparisonType = [
                    {name: 'is', id: 1},
                    {name: 'is niet', id: 2},
                    {name: 'groter dan', id: 3},
                    {name: 'kleiner dan', id: 4},
                ]
            }
            return comparisonType;
        }
        this.getValueType = function (attribute, comparison) {

            var vType = [];
            var vTypes = [
                {name: 'Afdeling 1', id: 1, fk: 4},
                {name: 'Afdeling 2', id: 2, fk: 4},
                {name: 'Afdeling 3', id: 3, fk: 4},
                {name: 'Afdeling 4', id: 4, fk: 4},
                {name: 'Afdeling 5', id: 5, fk: 4},
                {name: 'Functie 1', id: 6, fk: 3},
                {name: 'Functie 2', id: 7, fk: 3},
                {name: 'Functie 3', id: 8, fk: 3},
                {name: 'Functie 4', id: 10, fk: 3},
            ]
            var tmp = [];
            angular.forEach(vTypes, function (value, key) {
                if (value.fk === attribute.id)
                    tmp.push(value);
            });

            if (attribute.type == 'select') {
                vType = [
                    {name: 'Department 1', id: 1, fk: 4},
                    {name: 'Department 2', id: 2, fk: 4},
                    {name: 'Department 3', id: 3, fk: 4},
                    {name: 'Department 4', id: 4, fk: 4},
                    {name: 'Department 5', id: 5, fk: 4},
                    {name: 'Function 1', id: 6, fk: 3},
                    {name: 'Function 2', id: 7, fk: 3},
                    {name: 'Function 3', id: 8, fk: 3},
                    {name: 'Function 4', id: 9, fk: 3},
                    {name: 'Function 5', id: 10, fk: 3},
                ];
            }
            //return vType;
            return tmp;
        }
    });

})();
