import {Controller} from '@hotwired/stimulus';
import "leaflet/dist/leaflet.css"
import L from "leaflet"


export default class extends Controller {

    static targets = [
        "container",
    ]

    mapCenter;
    map;

    layerGroup = new L.LayerGroup();

    createMarkers(data, id = null) {
        this.markers = [];
        if (data !== undefined) {
            data.forEach(object => {

                if (id == null || id === object.building.id) {
                    var lon = object.location_xy[0];
                    var lat = object.location_xy[1];

                    var feature = {
                        type: 'Feature',
                        // properties: point,
                        geometry: {
                            type: 'Point',
                            coordinates: [lon, lat]
                        }
                    };

                    this.markers.push(feature);
                }

            })


            this.geoJson = {type: 'FeatureCollection', features: this.markers};
            this.buildingMarkers = L.geoJson(this.geoJson);
            this.layerGroup.addLayer(this.buildingMarkers).addTo(this.map);
            this.map.flyToBounds(L.geoJson(this.geoJson).getBounds(), {duration: 2});
        }

    }

    connect() {
        this.mapCenter = [52.1436, 5.383];

        this.map = L.map(this.containerTarget, {
            zoomDelta: 0.5,
            zoomSnap: 0.5,
        })

        this.map.setView(this.mapCenter, 10
        )

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
            attribution: null,
            maxZoom: 19,
            id: 'mapbox/dark-v10',
            tileSize: 512,
            zoomOffset: -1,
            zoomControl: false,
            accessToken: 'pk.eyJ1IjoibGV4aGFydG1hbiIsImEiOiJjazQ1cXdmODIwYmQyM2xwajdjcjFqcHh3In0._2u8heyFUo3HOWaQa8L1kA'
        }).addTo(this.map);

        this.layerGroup.addTo(this.map)

        // this.fetchRequest("/api/bag_vbos.json");


        fetch("/api/bag_vbos.json")
            .then(response => response.json())
            .then(data => {
                this.bagVbos = data;
                this.createMarkers(data);
            })



        // fetch("/api/bag_vbos.json").then((response) => {
        //     if (response.ok) {
        //         return response.json();
        //     }
        //     throw new Error('Something went wrong');
        // })
        //     .then((responseJson) => {
        //         // Do something with the response
        //         const test = responseJson;
        //     })
        //     // .then(response => response.json())
        //     .then(data => {
        //         this.bagVbos = data;
        //         this.createMarkers(data);
        //     });


    }

    updateMap(event) {
        this.layerGroup.removeLayer(this.buildingMarkers);
        this.buildingId =  parseInt(event.target.value);

        if ("Alle gebouwen" !== event.target.value) {
            this.createMarkers(this.bagVbos, this.buildingId)
        } else {
            this.createMarkers(this.bagVbos, null)
        }
    }


    disconnect() {
        this.map.remove()
    }


}


