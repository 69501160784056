import Chart from 'chart.js';

var conclusionsLego = [];

function initDashboardLego(objectId) {
    console.log('DashboardLego initialized: ' + new Date($.now()));
    if (typeof objectId === "undefined" || objectId === null) {
        objectId = null;
    }
    var url = Routing.generate('dashboard_api_lego');
    $.ajax({
        url: url,
        method: 'GET',
    }).then(function (responseLego) {
        conclusionsLego = responseLego.data;
        populateSelectButtonLego();
        createChartFunctionLego();
    }, function (responseLego) {
        console.log(responseLego);
        //alert('Something went wrong, please reload the page');
    });
}

function populateSelectButtonLego() {
    var elmsToAppend = [];
    //var $selectObjectLego = $('#lego-select-object-report');
    var arrayLego = [];
    $.each(conclusionsLego, function (iLego, objLego) {
        var idLego = objLego.id;
        var nameLego = iLego;
        if ((idLego in arrayLego) == false) {
            arrayLego[idLego] = nameLego;
            //$selectObjectLego.append('<option value=' + idLego + '>' + nameLego + '</option>');
            elmsToAppend.push($('<option value=' + idLego + '>' + nameLego + '</option>'));
        }
    });
    $('#lego-select-object-report').html(elmsToAppend);
}

function sortConlusionLego() {
    var sortedData = {
        "Alle gebouwen": {
            "Eigendomsdossier": {
                "verplicht": {
                    "aanwezig": 60,
                    "afwezig": 40
                },
                "aanbevolen": {
                    "aanwezig": 30,
                    "afwezig": 70
                },
            },
            "Gebruikersdossier": {
                "verplicht": {
                    "aanwezig": 50,
                    "afwezig": 50
                },
                "aanbevolen": {
                    "aanwezig": 90,
                    "afwezig": 10
                },
            },
            "Objectdossier": {
                "verplicht": {
                    "aanwezig": 33,
                    "afwezig": 67
                },
                "aanbevolen": {
                    "aanwezig": 63,
                    "afwezig": 37
                },
            },
        }
    };
    return sortedData;
}

function createChartFunctionLego(objectName = null) {
    var a = 0, b = 0;
    var arrayPieCharts = [];
    /*
    $('[id^="pieScoreLegoDashboard"]').each(function () {
        $(this).remove();
    });
    */
    if (null == objectName) {
        var scoreData = conclusionsLego["Alle gebouwen"]['dossier'];
    } else {
        var scoreData = conclusionsLego[objectName]['dossier'];
    }
    // var scoreData = sortConlusionLego();
    // var scoreAllBuildings =scoreData["Alle gebouwen"];
    var colors = {
        "Eigendomsdossier":
            {
                "verplicht": 'rgba(49, 133, 156, 0.7)',
                "aanbevolen": 'rgba(55,156,151,0.7)'
            },
        "Gebruikersdossier":
            {
                "verplicht": 'rgba(156,38,40,0.7)',
                "aanbevolen": 'rgba(156,46,76,0.7)'
            },
        "Objectdossier":
            {
                "verplicht": 'rgba(44,156,54,0.7)',
                "aanbevolen": 'rgba(61,156,106,0.7)'
            },
    };
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    //DashboardScorePie
    $.each(scoreData, function (i, obj) {
            $.each(obj, function (j, objChild) {
                /*
                $('#' + 'container-' + "pieScoreLegoDashboard-" + i + "-" + j).html('<canvas id="' + 'pieScoreLegoDashboard-' + i + "-" + j + '" > </canvas>');
                */
                var ctxPieScore = document.getElementById("pieScoreLegoDashboard-" + i + "-" + j);
                //scoreData = [60, 40];
                if (null !== ctxPieScore) {
                    var dataPie = {
                        labels: ['Documenten aanwezig (#)', 'Documenten niet aanwezig (#)'],
                        datasets: [
                            {
                                data: [objChild.aanwezig, objChild.afwezig],
                                backgroundColor: [
                                    colors[i][j],
                                    'rgba(192, 192, 192, 0.7)'
                                ],
                                hoverBackgroundColor: [
                                    colors[i][j],
                                    'rgba(192, 192, 192, 0.9)'
                                ]
                            }]
                    };
                    arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j] = new Chart(ctxPieScore, {
                        type: 'doughnut',
                        data: dataPie,
                        options: {
                            legend: {
                                display: true,
                                labels: {
                                    fontColor: 'rgb(102, 102, 102)'
                                }
                            }
                        }
                    });
                    /*
                    $('.score-counter').each(function () {
                        $(this).prop('Counter', 0).animate({
                            //Counter: scoreData[0] / 10
                            Counter: scoreData[0]
                        }, {
                            duration: 0,
                            easing: 'swing',
                            step: function (now) {
                                var score = now.toFixed(1).toString();
                                var scoreComma = parseFloat(score.replace(',', '').replace(' ', ''));
                                $(this).text(scoreComma + " %");
                            }
                        });
                    });
                    */
                }
            });
        }
    );
    $('#lego-select-object-report').change(function () {
        var objectName = $(this).find("option:selected").text();
        var scoreData = conclusionsLego[objectName]['dossier'];
        /*
        $.each(scoreData, function (i, obj) {
            $.each(obj, function (j, objChild) {
                //arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j].data.labels.pop();
                arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j].data.datasets.forEach((dataset) => {
                    dataset.data.pop();
                });
                arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j].update();
            })
        })
        */
        $.each(scoreData, function (i, obj) {
            $.each(obj, function (j, objChild) {
                /*
                var dataPie = {
                    labels: ['Documenten aanwezig (%)', 'Documenten niet aanwezig (%)'],
                    datasets: [
                        {
                            data: [objChild.aanwezig, objChild.afwezig],
                            backgroundColor: [
                                colors[i][j],
                                'rgba(192, 192, 192, 0.7)'
                            ],
                            hoverBackgroundColor: [
                                colors[i][j],
                                'rgba(192, 192, 192, 0.9)'
                            ]
                        }]
                };
                */
                // arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j].dataPie.labels.push(label);
                /*
                arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j].data.datasets.forEach((dataset) => {
                    dataset.data.push([objChild.aanwezig, objChild.afwezig]);
                });
                */
                arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j].data.datasets[0].data[0] = [objChild.aanwezig];
                arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j].data.datasets[0].data[1] = [objChild.afwezig];
                arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j].update();
                //arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j].data.datasets[1].data.pop();
                //arrayPieCharts["pieScoreLegoDashboard-" + i + "-" + j].update();
            })
        })
        //createChartFunctionLego(objectName);
    });
}

initDashboardLego();
