/*
 -----------------------------------------------
 -----------------------------------------------
 Contract legosubject objects
 -----------------------------------------------
 -----------------------------------------------
 */

var $legosubjectHolder;


// setup an "add a child" link
var $addLegosubjectLink = $(
    '<a class="btn btn-primary" href="#" class="add_legosubject_link">\n\
<i class="fa fa-plus"></i> LEGO-onderdeel toevoegen</a>'
);

var $newLegosubjectLinkLi = $('<li class="legosubject-add-link list-unstyled"></li>').append($addLegosubjectLink);

jQuery(document).ready(function () {

    // Get the ul that holds the collection of child
    $legosubjectHolder = $('ul.legosubject-parent');

    // add a delete link to all of the existing child form li elements
    $legosubjectHolder.find('li.legosubject-child').each(function () {
        addContractLegoFormDeleteLink($(this));
    });    // add a delete link to all of the existing child form li elements
    $legosubjectHolder.find('ul.legosubject-object-parent').each(function () {
        addLegosubjectObjectLinkLi($(this));
    });
    // add a delete link to all of the existing child-answers form li elements
    $legosubjectHolder.find('li.legosubject-object-child').each(function () {
        addContractLegoFormDeleteLink($(this));
    });


    // add the "add a child" anchor and li to the child ul
    $legosubjectHolder.append($newLegosubjectLinkLi);

    // count the current form inputs we have (e.g. 2), use that as the new
    // index when inserting a new item (e.g. 2)
    $legosubjectHolder.data('index', $legosubjectHolder.find('.legosubject-child').length);

    $addLegosubjectLink.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // add a new child form (see next code block)
        addLegosubjectForm($legosubjectHolder, $newLegosubjectLinkLi);
    });


});

$('body').on('click', 'a.add-child-legosubject-object-link', function (e) {
    // prevent the link from creating a "#" on the URL
    e.preventDefault();

    addLegosubjectObjectForm($(this));
});

function addLegosubjectObjectForm($addChildObjectButton) {
    var indexButton = $addChildObjectButton.data('index-legosubject-object-children');

    // Get all the li from the childanswers (only from the active childelement
    var legosubjectObjectHolder = $('ul#legosubject-object-parent-' + indexButton);
    var addAnswerButton = legosubjectObjectHolder.find('.child-legosubject-object-add-link');
    //var childElementHolder = $('ul#children-container');


    // Get the data-prototype explained earlier
    //var prototypeHolder = $('#prots');
    //var prototypelegosubjectObject = $('#contractor_agreement_contractorlegosubjectobjects___legosubject___contractorobjects___object__').data('prototype-answer');
    //appbundle_contract_contractobjectlegosubjects___name___objects___name__
    var prototypeLegosubjectObject = $('#prototype-lego-object').data('prototype-legosubject');
    //var prototypeLegosubjectObject = $('#prototype-lego-object').data('prototype-legosubject-object');
    //var prototype = $childrenHolder.data('prototype');

    // get the new index
    var indexLegosubject = legosubjectObjectHolder.data('index-legosubject-object-children');
    var indexObject = legosubjectObjectHolder.find('li.legosubject-object-child').length;

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    var newFormLegosubjectObject = prototypeLegosubjectObject.replace(/__legosubject_object__/g, indexObject).replace(/__name__/g, indexLegosubject);

    // increase the index with one for the next item
    legosubjectObjectHolder.data('index', indexObject + 1);

    // Display the form in the page in an li, before the "Add a child" link li
    var $newLegosubjectFormLi = $('<li class="list-unstyled well legosubject-object-child"></li>').append(newFormLegosubjectObject);
    addAnswerButton.before($newLegosubjectFormLi);

    // add a delete link to the new form
    addContractLegoFormDeleteLink($newLegosubjectFormLi);
}

function addLegosubjectForm($legosubjectHolder, $newLegosubjectLinkLi) {
    // Get the data-prototype explained earlier
    var prototypeLegosubject = $('#prototype-lego-object').data('prototype-legosubject');

    var prototypeLegosubjectObject = $('#prototype-lego-object').data('prototype-legosubject-object');


    // get the new index
    var indexLegosubject = $legosubjectHolder.data('index');
    var legosubjectObjectHolder = $('ul#legosubject-parent-' + indexLegosubject);
    var indexObject = legosubjectObjectHolder.find('li.legosubject-object-child').length;

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    //appbundle_contract_contractobjectlegosubjects___name___objects___name__
    var newFormLegosubjectObject = prototypeLegosubject.replace(/__legosubject_object__/g, indexObject).replace(/__name__/g, indexLegosubject);

    var liObject = $('<li class="list-unstyled well legosubject-object-child">' +
        '</li>').append(newFormLegosubjectObject);
    var ulObject = $('<ul data-index-legosubject-object-children="' + indexLegosubject + '" id="legosubject-object-parent-' + indexLegosubject + '" class="collection legosubject-object-parent">' +
        '</ul>').append(liObject);
    var htmlPanelHeading = $('<div class="panel-heading" role="tab" id="heading' + indexLegosubject + '">' +
        '<h4 class="panel-title">' +
        '<a role="button" data-bs-toggle="collapse" data-parent="#accordion' + indexLegosubject + '" href="#collapse' + indexLegosubject + '"' +
        'aria-expanded="true" aria-controls="collapse' + indexLegosubject + '">' +
        '<span class="clickable">' +
        '<i class="pull-left glyphicon glyphicon-minus-sign"></i> Gebouwen' +
        '</span></a></h4></div>');
    var htmlPanelBody = $('<div id="collapse' + indexLegosubject + '" class="panel-collapse" role="tabpanel"' +
        'aria-labelledby="heading' + indexLegosubject + '">' +
        '<div class="panel-body">' +
        '</div></div>');
    htmlPanelBody.find('.panel-body').append(ulObject)


    var newFormLegosubjectObjectUl =
        $('<div class="form-group">' +
            '<div class="panel-group" id="accordion' + indexLegosubject + '" role="tablist" aria-multiselectable="true">' +
            '<div class="panel panel-default panel-parent">' +
            '</div></div></div>');
    newFormLegosubjectObjectUl.find('.panel-parent').append(htmlPanelHeading).append(htmlPanelBody);
    var newFormLegosubject = $('<div class="form-group"></div>').append(prototypeLegosubjectObject.replace(/__name__/g, indexLegosubject));

    // increase the index with one for the next item
    $legosubjectHolder.data('index', indexLegosubject + 1);

    // Display the form in the page in an li, before the "Add a child" link li
    var $newLegosubjectFormLi = $('<div class="well"></div>').append(newFormLegosubject).append(newFormLegosubjectObjectUl);
    var $newLegosubjectFormWell = $('<li data-index-child="' + indexLegosubject + '" class="list-unstyled"></li>').append($newLegosubjectFormLi);

    $newLegosubjectLinkLi.before($newLegosubjectFormWell);

    addLegosubjectObjectLinkLi(ulObject);
    addContractLegoFormDeleteLink(liObject);

    // add a delete link to the new form
    addContractLegoFormDeleteLink($newLegosubjectFormLi);
}

function addLegosubjectObjectLinkLi($newFormLi) {
    var indexButton = $newFormLi.data('index-legosubject-object-children');

    // setup an "add a child" link
    var $addLegosubjectLink = $(
        '<a data-index-legosubject-object-children="' + indexButton + '" class="btn btn-primary add-child-legosubject-object-link" href="#">\n\
    <i class="fa fa-plus"></i> Gebouw toevoegen</a>'
    );

    var $newLegosubjectLinkLi = $('<li data-index-legosubject-object-children="' + indexButton + '" class="child-legosubject-object-add-link list-unstyled"></li>').append($addLegosubjectLink);
    $newFormLi.append($newLegosubjectLinkLi);

    /*
     var $childElementAnswersHolder = $newFormLi.closest('ul.child-answers');

     // add the "add a child" anchor and li to the child ul
     $childElementAnswersHolder.append($newTaskLinkLi);
     */


}

function addContractLegoFormDeleteLink($newFormLi) {
    var $removeFormA = $('<a class="btn btn-danger" href="#"><i class="fa fa-trash-o"></i></a>');
    //$newFormLi.append($removeFormA);
    $newFormLi.append($removeFormA);

    $removeFormA.on('click', function (e) {
        // prevent the link from creating a "#" on the URL
        e.preventDefault();

        // remove the li for the child form
        $newFormLi.remove();
    });
}


